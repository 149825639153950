export const formatPercentage = (value: string) => {
    let numericValue = value.replace(/[^0-9,]/g, "");
  
    if (numericValue.startsWith(",")) {
      numericValue = "0" + numericValue;
    }
  
    const parts = numericValue.split(",");
    if (parts.length > 2) {
      numericValue = parts[0] + "," + parts[1].slice(0, 2);
    } else if (parts.length === 2) {
      numericValue = parts[0] + "," + parts[1].slice(0, 2);
    }
  
    return numericValue
}