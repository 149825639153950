import React, { useEffect, useState, useRef } from 'react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import styled from 'styled-components';

interface FeaturedCardProps {
  title: string;
  value: string;
  icon: React.ReactNode;
  images: string[];
  percentage?: string;
  type?: "up" | "down" | "pending";
  format?: "percent" | "normal" | "minutes";
}

export const SimpleFeaturedCard: React.FC<FeaturedCardProps> = ({ title, value, type, icon, images, format, percentage }) => {
  const IndicatorIcon =
    type === "up"
      ? FiArrowUp
      : type === "down"
        ? FiArrowDown
        : FiArrowUp;

  const backgroundColor =
    type === "up"
      ? "#00FF5599"
      : type === "down"
        ? "#FF000099"
        : "#FFD400";

  const formattedValue = format === 'percent' ? (
    <>
      <Value>{value}</Value><Percent>%</Percent>
    </>
  ) : format === 'minutes' ? (
    <>
      <Value>
        {parseFloat(value) >= 60 ? `${Math.floor(parseFloat(value) / 60)}h ${Math.floor(parseFloat(value) % 60)}min` : `${Math.floor(parseFloat(value))}min`}
      </Value>
    </>
  ) : (
    <Value>{value}</Value>
  );

  const progressBarWidth = percentage ? `${100 + parseFloat(percentage)}%` : '0%';

  const [elapsedTime, setElapsedTime] = useState<string>("0s");
  const startTime = useRef<number>(Date.now());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      const elapsedSeconds = Math.floor((currentTime - startTime.current) / 1000);

      if (elapsedSeconds < 60) {
        setElapsedTime(`${elapsedSeconds}s`);
      } else if (elapsedSeconds < 3600) {
        const minutes = Math.floor(elapsedSeconds / 60);
        setElapsedTime(`${minutes}m`);
      } else {
        const hours = Math.floor(elapsedSeconds / 3600);
        setElapsedTime(`${hours}h`);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Card>
      <Header>
        <Title>{title}</Title>
      </Header>
      {formattedValue}
      <ProgressBar>
        <div className="filled" style={{ width: progressBarWidth }}></div>
        <div className="empty"></div>
      </ProgressBar>

      <FooterContent>
        <Percentage style={{ backgroundColor }}>
          {percentage} <IndicatorIcon size={12} color="#fff" />
        </Percentage>

        <TimeIndicator>{`Atualizado há ${elapsedTime}`}</TimeIndicator>
      </FooterContent>
    </Card>
  );
};

const Card = styled.div`
  background-color: white;
  max-height: 200px;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const Title = styled.text`
  font-weight: 700;
  color: #B2B2B2;
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
`;

const Icon = styled.div`
  background-color: #1270FC;
  padding: 6px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Value = styled.span`
  font-size: 60px;
  font-weight: 700;
  color: #444444;
`;

const Percent = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: #444444;
`;

const ImageList = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const Image = styled.img`
  width: 30%;
  border-radius: 8px;
  object-fit: cover;
`;

const ProgressBar = styled.div`
  display: flex;
  height: 3px;
  margin-top: 5px;

  .filled {
    background-color: #007bff;
    border-radius: 2px;
  }

  .empty {
    background-color: #e0e0e0;
    flex: 1;
    border-radius: 2px;
  }
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

const Percentage = styled.div`
  background-color: #d4f8e8;
  color: #ffffff;
  padding: 4px 6px;
  border-radius: 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const TimeIndicator = styled.div`
  font-size: 10px;
  color: #ffffff;
  background-color: #1270FC99;
  padding: 4px 6px;
  border-radius: 12px;
`;
