import { Ball, TagText, TagsProps, TagsWrapper } from "./style";

export const TagStatus = (props: TagsProps) => {
  return (
    <TagsWrapper type={props.type}>
      <Ball type={props.type} />
      <TagText type={props.type}>
        {props.type == "inactive" ? "Inativo" : "Ativo"}
      </TagText>
    </TagsWrapper>
  );
};
