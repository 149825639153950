import styled from 'styled-components';

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  overflow: auto;
  padding: 35px 40px 5px 40px;
  background-color: #F6F6F6;
`;

export const MainContent = styled.div`
  width: 100%;
`;

export const CardContent = styled.div`
  display: flex;
  gap: 18px;
  margin-bottom: 25px;
  justify-content: space-between;
`;

export const PatientContent = styled.div`
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 33.33%;
  padding: 30px;
  display: flex;
  flex-direction: column;
`;

export const SubTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #B2B2B2;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #444444;
`;

export const ChartWrapper = styled.div`
  background-color: #fff;
  margin-top: 8px;
  padding: 20px;
  flex: 1;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 4px 6px -3px rgba(0, 0, 0, 0.18);

  @media (max-width: 1200px) {
    padding: 6px;
  }
`;

export const AgeChartContainer = styled.div`
  background-color: #fff;
  margin-top: 8px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 2px 4px 6px -3px rgba(0, 0, 0, 0.18);
  width: 100%;
`;

export const TableWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 30px;
  max-height: 600px;
`;

export const customStyles = {
  overlay: {
    zIndex: 9000,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 16,
    transition: "all 0.3s ease",
    overflow: "visible",
  },
};

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-top: 10px;
  border-bottom: 1px solid #E5E5E5;
`;

export const HeaderTitle = styled.span`
  font-size: 20px;
  font-weight: 600;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 100px;
  left: 240px;
  width: calc(100% - 240px);
  height: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
`;