import { useEffect, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import PageStructure from "../../../components/PageStructure";
import Modal from "react-modal";
import { animated, useSpring } from "react-spring";
import api from "../../../service/api";
import { toast } from "react-toastify";
import {
    Wrapper,
    FormWrapper,
    FieldWrapper,
    Label,
    ButtonWrapper,
    customStyles,
    HeaderWrapper,
    HeaderTitle,
    CloseButton,
    InlineFieldWrapper,
    VariablesWrapper,
    VariableButton,
} from "./style";
import CustomInput from "../../../components/CustomInput";
import CustomDropdown from "../../../components/CustomDropDown";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
} from "@mui/material";
import { MdEdit } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";

const TemplateMessage = () => {
    interface Template {
        id: number;
        message: string;
        request_pre_payment: boolean;
        send_period_hours: number;
        message_type: string;
    }

    const [templates, setTemplates] = useState<Template[]>([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [requestPrePayment, setRequestPrePayment] = useState(false);
    const [sendPeriodHours, setSendPeriodHours] = useState("");
    const [messageType, setMessageType] = useState("PRE");
    const [editingTemplate, setEditingTemplate] = useState<Template | null>(null);
    const [selectedOption, setSelectedOption] = useState<string>("");

    const typeOptions = [
        { id: "PRE", name: "Pré-Consulta" },
        { id: "POS", name: "Pós-Consulta" },
    ];

    const fetchTemplates = async () => {
        try {
            const response = await api.get("/templateMensage/");
            setTemplates(response.data);
        } catch (error) {
            toast.error("Erro ao carregar templates");
        }
    };

    useEffect(() => {
        fetchTemplates();
    }, []);

    const handleSave = async () => {
        try {
            if (editingTemplate) {
                await api.put(`/templateMensage/${editingTemplate.id}/`, { message, request_pre_payment: requestPrePayment, send_period_hours: sendPeriodHours, message_type: messageType });
                toast.success("Template atualizado com sucesso!");
            } else {
                await api.post("/templateMensage/", { message, request_pre_payment: requestPrePayment, send_period_hours: sendPeriodHours, message_type: messageType });
                toast.success("Template cadastrado com sucesso!");
            }
            fetchTemplates();
            closeModal();
        } catch (error) {
            toast.error("Erro ao salvar template");
        }
    };

    const openModal = (template?: Template) => {
        if (template) {
            setEditingTemplate(template);
            setMessage(template.message);
            setRequestPrePayment(template.request_pre_payment);
            setSendPeriodHours(template.send_period_hours.toString());
            setMessageType(template.message_type);
        } else {
            setEditingTemplate(null);
            setMessage("");
            setRequestPrePayment(false);
            setSendPeriodHours("");
            setMessageType("PRE");
        }
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const fade = useSpring({
        from: { opacity: 0 },
        to: { opacity: modalIsOpen ? 1 : 0 },
    });

    const filteredTemplates = selectedOption
        ? templates.filter(template =>
            typeOptions.find(option => option.name === selectedOption)?.id === template.message_type
        )
        : templates;

    const insertVariable = (variable: string) => {
        setMessage(prevMessage => `${prevMessage} {{${variable}}}`);
    };

    return (
        <PageStructure options={typeOptions.map(option => option.name)} onSelectChange={(selected) => setSelectedOption(selected)}>
            <Wrapper>
                <ButtonWrapper>
                    <CustomButton onClick={() => openModal()} theme={"addPrimary"}>
                        Cadastrar Novo Template
                    </CustomButton>
                </ButtonWrapper>
                <TableContainer component={Paper} style={{ marginTop: 20 }}>
                    <Table>
                        <TableHead>
                            <TableRow style={{ backgroundColor: "#1270FC" }}>
                                <TableCell style={{ color: "#FFFFFF", fontWeight: "bold", position: 'sticky', top: 0, zIndex: 1 }}>Mensagem</TableCell>
                                <TableCell style={{ color: "#FFFFFF", fontWeight: "bold", position: 'sticky', top: 0, zIndex: 1 }}>Pré-Pagamento</TableCell>
                                <TableCell style={{ color: "#FFFFFF", fontWeight: "bold", position: 'sticky', top: 0, zIndex: 1 }}>Período (horas)</TableCell>
                                <TableCell style={{ color: "#FFFFFF", fontWeight: "bold", position: 'sticky', top: 0, zIndex: 1 }}>Tipo</TableCell>
                                <TableCell style={{ color: "#FFFFFF", fontWeight: "bold", position: 'sticky', top: 0, zIndex: 1 }}>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredTemplates.map((template, index) => (
                                <TableRow key={index}>
                                    <TableCell>{template.message}</TableCell>
                                    <TableCell>{template.request_pre_payment ? "Sim" : "Não"}</TableCell>
                                    <TableCell>{template.send_period_hours}</TableCell>
                                    <TableCell>{typeOptions.find(option => option.id === template.message_type)?.name}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => openModal(template)}>
                                            <MdEdit />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Wrapper>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={false}
                style={{
                    ...customStyles,
                    content: {
                        ...customStyles.content,
                        width: '80%',
                        height: '60%',
                        display: 'flex',
                        flexDirection: 'column',
                    },
                }}
            >
                <animated.div style={{ ...fade, display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <HeaderWrapper>
                        <HeaderTitle>{editingTemplate ? "Editar Template" : "Criar Template"}</HeaderTitle>
                        <CloseButton onClick={closeModal}>
                            <IoCloseOutline size={22} color="#919EAB" />
                        </CloseButton>
                    </HeaderWrapper>
                    <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                        <div style={{ flex: 1, paddingRight: '20px', borderRight: '1px solid #ddd' }}>
                            <FormWrapper>
                                <FieldWrapper>
                                    <Label>Tipo</Label>
                                    <CustomDropdown
                                        options={typeOptions}
                                        selectedOption={typeOptions.find(option => option.id === messageType)?.name || ""}
                                        setSelectedOption={(value) => setMessageType(value.id)}
                                    />
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label>Período (em horas)</Label>
                                    <CustomInput
                                        type="number"
                                        value={sendPeriodHours}
                                        onChange={(e) => setSendPeriodHours(e.target.value)}
                                        placeholder="Digite o período em horas"
                                    />
                                </FieldWrapper>
                                <InlineFieldWrapper>
                                    <input
                                        type="checkbox"
                                        checked={requestPrePayment}
                                        onChange={(e) => setRequestPrePayment(e.target.checked)}
                                    />
                                    <Label>Solicita Pré-Pagamento</Label>
                                </InlineFieldWrapper>
                                <CustomButton onClick={handleSave} theme={"primary"}>
                                    {editingTemplate ? "Atualizar" : "Salvar"}
                                </CustomButton>
                            </FormWrapper>
                        </div>
                        <div style={{ flex: 2, paddingLeft: '20px' }}>
                            <FieldWrapper>
                                <Label>Mensagem</Label>
                                <textarea
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    placeholder="Digite a mensagem"
                                    style={{ width: '100%', height: '250px', padding: '10px', borderRadius: '5px', borderColor: '#ddd', resize: 'none' }}
                                />
                                <VariablesWrapper>
                                    <VariableButton onClick={() => insertVariable('firstName')}>Primeiro Nome</VariableButton>
                                    <VariableButton onClick={() => insertVariable('name')}>Nome</VariableButton>
                                    <VariableButton onClick={() => insertVariable('ms')}>Saudação</VariableButton>
                                    <VariableButton onClick={() => insertVariable('protocol')}>Protocolo</VariableButton>
                                    <VariableButton onClick={() => insertVariable('hora')}>Hora</VariableButton>
                                </VariablesWrapper>
                            </FieldWrapper>
                        </div>
                    </div>
                </animated.div>
            </Modal>
        </PageStructure>
    );
};

export default TemplateMessage;
