import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import PageStructure from "../../components/PageStructure";
import PatientProfile from "../../assets/user-profile.png";
import { AnimatePresence, motion } from "framer-motion";
import * as S from "./styles";
import { useEffect, useState } from "react";
import CustomTextArea from "../../components/CustomTextArea";
import ExpandableSection from "../../components/ExpandableSection";
import ButtonOptionList from "../../components/ButtonOptionList";
import { MultiOptionButtonList } from "../../components/MultiOptionButtonList";
import { PatologyList } from "./mock/PatolgyList";
import CustomDropdown from "../../components/CustomDropDown";
import MetricIcon from "../../assets/fita-metrica.png";
import CheckList from "../../assets/check-list.png";
import Composition from "../../assets/composition.png";
import LifeStyle from "../../assets/lifestyle.png";
import Genoma from "../../assets/genoma.png";
import Avaliacao from "../../assets/viabilidade.png";
import SistemaUrinario from "../../assets/sistema-urinario.png";
import Comida from "../../assets/alimentos.png";
import { jwtDecode } from "jwt-decode";
import { AntropometryDTO } from "./dtos/AntropometryDTO";
import { AnamneseDTO } from "./dtos/AnamneseDTO";
import api from "../../service/api";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { getProfessionalId } from "../../service/token.service";
import PatientSummary, { calculateAgeDetails } from "./components/PatientSummary";
import { FaAngleDown, FaAngleUp, FaSave } from "react-icons/fa";
import Model, { IExerciseData, IMuscleStats, ModelType } from 'react-body-highlighter';
import { SegmentList } from "./mock/SegmentList";
import React from "react";
import { IoBeer } from "react-icons/io5";
import { GiCigarette } from "react-icons/gi";
import { ExpandableOptionList } from "../../components/ExpandableOptionList";

export const MedicalRecord = () => {
  const formatDate = (dateString: string) => {
    const splitedDate = dateString.split("T");
    return splitedDate[0];
  };
  const location = useLocation();
  const navigate = useNavigate();
  const { patient, pageType, recordData } = location?.state as {
    patient?: any;
    pageType: string;
    recordData: any;
  };

  const [dadosPaciente, setDadosPaciente] = useState<DadosPaciente | null>(null);
  const [anamneseExpandable, setAnamneseExpandable] = useState(true);
  const [hasMealsAwayFromHome, setHasMealsAwayFromHome] = useState(false);
  const [appetiteSelectedOption, setAppetiteSelectedOption] =
    useState("normal");
  const [foodRestrictionSelectedOption, setFoodRestrictionSelectedOption] =
    useState("nenhum");
  const [selectedChewing, setSelectedChewing] = useState("normal");
  const [stoolShape, setStoolShape] = useState("Teste");
  const [evacuationFrequency, setEvacuationFrequency] = useState("pordia");
  const [shitColorOption, setShitColorOption] = useState("marrom");
  const [intestionalHabitSelectedOption, setIntestionalHabitSelectedOption] =
    useState("normal");
  const [hydroSelectedOption, setHydroSelectedOption] =
    useState("1bemhidratado");
  const [bodyType, setBodyType] = useState("medidascorporais");
  const [selectedSegmentList, setSelectedSegmentList] = useState<string[]>([]);
  const [selectedPathologyList, setSelectedPathologyList] = useState<string[]>(
    []
  );
  const [habitos, setHabitos] = useState<string[]>(
    []
  );
  const [userRole, setUserRole] = useState("");
  const [modelType, setModelType] = useState("anterior");
  const [recordId, setRecordId] = useState();

  const [antropometryObj, setAntropometryObj] = useState<AntropometryDTO>({
    date: formatDate(new Date().toISOString()),
    weight: 0,
    height: 0,
    measurement_type: "",
    arm_relaxed_right: 0,
    arm_contracted_right: 0,
    chest: 0,
    waist: 0,
    abdomen: 0,
    hip: 0,
    thigh: 0,
    calf: 0,
    rcq: 0,
    rce: 0,
    abdomen_dobras: 0,
    chest_dobras: 0,
    supra_iliaca: 0,
    axilar_media: 0,
    thigh_dobras: 0,
    triceps: 0,
    subscapular: 0,
    fat_free_mass: 0,
    skeletal_muscle_mass: 0,
    fat_mass: 0,
    visceral_fat_level: 0,
    pgc: 0,
    obesity_grade: 0,
    tmb: 0,
  });

  const [anamneseObj, setAnamneseObj] = useState<AnamneseDTO>({
    clinical_case: "",
    protocol: [""],
    ideal_weight: 0,
    target_weight: 0,
    date: formatDate(new Date().toISOString()),
    lifeHabits: {
      foodRestriction: "",
      drinkAlcohol: false,
      smoking: false,
      mealsAwayFromHome: false,
      mealDescription: "",
      numberOfPeopleLives: 0,
      whoDoesHouseholdShopping: "",
      whereYouShop: "",
      howManyTimes: 0,
      takeChildren: false,
      litersOilUsedPerMonth: 0,
      kiloSaltUsedPerMonth: 0,
      drinkAlcoholFrequency: 0,
      smokingFrequency: 0,
    },
    pathology: {
      types: [""],
      otherPathologies: "",
      medicines: "",
      exams: "",
      familyHistory: "",
      observation: "",
    },
    clinicalAssessment: {
      appetite: "",
      chewing: "",
      intestionalHabit: "",
      evacuationFrequency: 0,
      format: "",
      frequencyType: "",
      useLaxative: false,
      stoolColor: "",
    },
    urinaryHabits: {
      describe: "",
      waterIntake: "",
      urinaryHydratation: "",
      observation: "",
    },
    eatingHabits: {
      foodSuplements: "",
      foodAllergy: "",
      foodIntolerance: "",
      foodAversions: "",
      observation: "",
    },
  });

  const [bodyData, setBodyData] = useState<IExerciseData[]>([]);

  const getStatusColor = (value: number, thresholds: { green: number; yellow: number; red: number }) => {
    if (value <= thresholds.green) return "green";
    if (value <= thresholds.yellow) return "yellow";
    return "red";
  };

  useEffect(() => {
    const token = localStorage.getItem("bridges.token");
    if (token) {
      const tokenData = jwtDecode(token) as any;
      setUserRole(tokenData.groups[0] || "");
    }
  }, []);

  const handleSaveRecord = async () => {
    const recordData = {
      patient: patient.id,
      created_by: getProfessionalId() || 2,
      professional: getProfessionalId() || 2,
      antropometry: antropometryObj,
      life_habits: anamneseObj.lifeHabits,
      pathology: anamneseObj.pathology,
      clinical_assessment: anamneseObj.clinicalAssessment,
      urinary_habits: anamneseObj.urinaryHabits,
      eating_habits: anamneseObj.eatingHabits,
      anamnese: {
        clinical_case: anamneseObj.clinical_case,
        date: anamneseObj.date,
      },
    };

    // Atualiza campos selecionados
    recordData.life_habits.foodRestriction = foodRestrictionSelectedOption;
    recordData.pathology.types = selectedPathologyList;
    recordData.clinical_assessment.appetite = appetiteSelectedOption;
    recordData.clinical_assessment.chewing = selectedChewing;
    recordData.clinical_assessment.intestionalHabit = intestionalHabitSelectedOption;
    recordData.clinical_assessment.format = stoolShape;
    recordData.clinical_assessment.frequencyType = evacuationFrequency;
    recordData.clinical_assessment.stoolColor = shitColorOption;
    recordData.urinary_habits.urinaryHydratation = hydroSelectedOption;

    const id = toast.loading("Salvando prontuário...");

    try {
      if (pageType === "edit" && recordId) {
        await api.put(`/historico-prontuario/${recordId}/`, recordData);
        toast.update(id, {
          render: "Prontuário atualizado com sucesso!",
          type: "success",
          autoClose: 5000,
          isLoading: false,
        });
      } else {
        await api.post("/historico-prontuario/", recordData);
        toast.update(id, {
          render: "Prontuário salvo com sucesso!",
          type: "success",
          autoClose: 5000,
          isLoading: false,
        });
      }

      navigate("/medicalRecordHistory", {
        state: {
          patient: { id: patient.id },
        },
      });
    } catch (error) {
      toast.update(id, {
        render: "Houve uma falha ao salvar o prontuário",
        type: "error",
        autoClose: 5000,
        isLoading: false,
      });
    }
  };


  const initializeFields = () => {
    if (pageType == "view" || pageType == "edit") {

      setRecordId(recordData.id)

      setAntropometryObj({
        date: recordData.antropometry.date,
        weight: recordData.antropometry.weight,
        height: recordData.antropometry.height,

        sex: recordData.antropometry.sex,
        measurement_type: recordData.antropometry.measurement_type,
        arm_relaxed_right: recordData.antropometry.arm_relaxed_right,
        arm_contracted_right: recordData.antropometry.arm_contracted_right,
        chest: recordData.antropometry.chest,
        waist: recordData.antropometry.waist,
        abdomen: recordData.antropometry.abdomen,
        hip: recordData.antropometry.hip,
        thigh: recordData.antropometry.thigh,
        calf: recordData.antropometry.calf,
        rcq: recordData.antropometry.rcq,
        rce: recordData.antropometry.rce,

        abdomen_dobras: recordData.antropometry.abdomen_dobras,
        chest_dobras: recordData.antropometry.chest_dobras,
        supra_iliaca: recordData.antropometry.supra_iliaca,
        axilar_media: recordData.antropometry.axilar_media,
        thigh_dobras: recordData.antropometry.thigh_dobras,
        triceps: recordData.antropometry.triceps,
        subscapular: recordData.antropometry.subscapular,

        fat_free_mass: recordData.antropometry.fat_free_mass,
        skeletal_muscle_mass: recordData.antropometry.skeletal_muscle_mass,
        fat_mass: recordData.antropometry.fat_mass,
        visceral_fat_level: recordData.antropometry.visceral_fat_level,
        pgc: recordData.antropometry.pgc,
        obesity_grade: recordData.antropometry.obesity_grade,
        tmb: recordData.antropometry.tmb,
      });

      setAnamneseObj({
        ideal_weight: 0,
        date: recordData.date,
        target_weight: 0,
        lifeHabits: {
          drinkAlcohol: recordData.life_habits.drinkAlcohol,
          smoking: recordData.life_habits.smoking,
          mealsAwayFromHome: recordData.life_habits.mealsAwayFromHome,
          mealDescription: recordData.life_habits.mealDescription,
          numberOfPeopleLives: recordData.life_habits.numberOfPeopleLives,
          whoDoesHouseholdShopping:
            recordData.life_habits.whoDoesHouseholdShopping,
          whereYouShop: recordData.life_habits.whereYouShop,
          kiloSaltUsedPerMonth: recordData.life_habits.kiloSaltUsedPerMonth,
          litersOilUsedPerMonth: recordData.life_habits.litersOilUsedPerMonth,
          howManyTimes: recordData.life_habits.howManyTimes,
          takeChildren: recordData.life_habits.takeChildren,
        },
        pathology: {
          otherPathologies: recordData.pathology.otherPathologies,
          medicines: recordData.pathology.medicines,
          exams: recordData.pathology.exams,
          familyHistory: recordData.pathology.familyHistory,
          observation: recordData.pathology.observation,
        },
        urinaryHabits: {
          describe: recordData.urinary_habits.describe,
          waterIntake: recordData.urinary_habits.waterIntake,
          observation: recordData.urinary_habits.observation,
        },
        clinicalAssessment: {
          evacuationFrequency:
            recordData.clinical_assessment.evacuationFrequency,
          useLaxative: recordData.clinical_assessment.useLaxative,
        },
        eatingHabits: {
          foodAllergy: recordData.eating_habits.foodAllergy,
          foodAversions: recordData.eating_habits.foodAversions,
          foodIntolerance: recordData.eating_habits.foodIntolerance,
          foodSuplements: recordData.eating_habits.foodSuplements,
          observation: recordData.eating_habits.observation,
        },
      });

      // setSelectedSegmentList(recordData.metrics.segment);
      // setBodyType(recordData.composition_body.type);
      setSelectedPathologyList(recordData.pathology.types || []);
      setFoodRestrictionSelectedOption(recordData.life_habits.foodRestriction);
      setAppetiteSelectedOption(recordData.clinical_assessment.appetite);
      setSelectedChewing(recordData.clinical_assessment.chewing);
      setIntestionalHabitSelectedOption(
        recordData.clinical_assessment.intestionalHabit
      );
      setShitColorOption(recordData.clinical_assessment.stoolColor);
      setEvacuationFrequency(recordData.clinical_assessment.frequencyType);
      setStoolShape(recordData.clinical_assessment.format);
      setHydroSelectedOption(recordData.urinary_habits.urinaryHydratation);
    }
  };

  useEffect(() => {
    initializeFields();
  }, []);

  interface DadosPaciente {
    name?: string;
    nickname?: string;
    birthday?: string;
    sex: string;
    profilePicture?: string;
    email?: string;
    phone?: string;
    currentWeight?: number;
    habitualWeight?: number;
    weightDifference?: number;
  }


  useEffect(() => {
    const obtemPaciente = async () => {
      try {
        const response = await api.get(`/clients/${patient.id}`);
        const dados: DadosPaciente = response.data;

        const validSex = ["Masculino", "Feminino", "Outro"].includes(dados.sex) ? dados.sex : "Sem Informação";

        setDadosPaciente({
          name: dados.name ?? "Nome não informado",
          nickname: dados.nickname ?? "",
          birthday: dados.birthday ?? "Data não informada",
          sex: validSex,
          profilePicture: dados.profilePicture ?? PatientProfile,
          email: dados.email ?? "Email não informado",
          phone: dados.phone ?? "Telefone não informado",
          currentWeight: dados.currentWeight ?? 0,
          habitualWeight: dados.habitualWeight ?? 0,
          weightDifference:
            dados.currentWeight && dados.habitualWeight
              ? dados.currentWeight - dados.habitualWeight
              : 0,
        });

      } catch (error) {
        console.error("Erro ao obter os dados do paciente:", error);
      }
    };

    obtemPaciente();
  }, []);

  const handleClick = React.useCallback(({ muscle }: IMuscleStats, click?: boolean) => {
    click ? setModelType("posterior") : setModelType("anterior");

    setBodyData(() => [
      {
        name: '',
        muscles: [muscle],
      },
    ]);
  }, []);

  useEffect(() => {
    if (antropometryObj?.waist !== undefined && antropometryObj?.hip !== undefined && antropometryObj.waist > 0 && antropometryObj.hip > 0) {
      setAntropometryObj(prev => ({
        ...prev,
        rcq: Math.round((prev.waist! / prev.hip!) * 100) / 100,
      }));
    }
  }, [antropometryObj?.waist, antropometryObj?.hip]);

  useEffect(() => {
    if (antropometryObj?.height !== undefined && antropometryObj?.waist !== undefined && antropometryObj.height > 0 && antropometryObj.waist > 0) {
      setAntropometryObj(prev => ({
        ...prev,
        rce: Math.round((prev.waist! / prev.height!) * 100) / 100,
      }));
    }
  }, [antropometryObj?.waist, antropometryObj?.height]);

  useEffect(() => {
    const ageDetails = dadosPaciente?.birthday ? calculateAgeDetails(dadosPaciente.birthday) : null;
    const years = ageDetails ? ageDetails.years : null;

    let defaultValue1 = dadosPaciente?.sex === 'Masculino' ? 1.112 : 1.097;
    let defaultValue2 = dadosPaciente?.sex === 'Masculino' ? 0.00043499 : 0.00046971;
    let defaultValue3 = dadosPaciente?.sex === 'Masculino' ? 0.00000055 : 0.00000056;
    let defaultValue4 = dadosPaciente?.sex === 'Masculino' ? 0.0002882 : 0.00012828;

    if (
      antropometryObj?.abdomen_dobras !== undefined && antropometryObj.abdomen_dobras > 0 &&
      antropometryObj?.supra_iliaca !== undefined && antropometryObj.supra_iliaca > 0 &&
      antropometryObj?.axilar_media !== undefined && antropometryObj.axilar_media > 0 &&
      antropometryObj?.chest_dobras !== undefined && antropometryObj.chest_dobras > 0 &&
      antropometryObj?.thigh_dobras !== undefined && antropometryObj.thigh_dobras > 0 &&
      antropometryObj?.triceps !== undefined && antropometryObj.triceps > 0 &&
      antropometryObj?.subscapular !== undefined && antropometryObj.subscapular > 0
    ) {
      let soma =
        antropometryObj.abdomen_dobras +
        antropometryObj.supra_iliaca +
        antropometryObj.axilar_media +
        antropometryObj.chest_dobras +
        antropometryObj.thigh_dobras +
        antropometryObj.triceps +
        antropometryObj.subscapular;

      let dcValue = defaultValue1 - (defaultValue2 * soma) + (defaultValue3 * (soma ** 2)) - (defaultValue4 * (years ?? 0));
      let percentG = (4.95 / dcValue) - 4.5;

      setAntropometryObj(prev => ({
        ...prev,
        body_density: Math.round(dcValue * 100) / 100,
        pgc: Math.round(percentG * 100) / 100
      }));
    }
  }, [antropometryObj.abdomen_dobras, antropometryObj.supra_iliaca, antropometryObj.axilar_media, antropometryObj.chest_dobras, antropometryObj.thigh_dobras, antropometryObj.triceps, antropometryObj.subscapular])

  return (
    <PageStructure>
      <S.Wrapper>
        <S.SummaryWrapper>
          <PatientSummary {...dadosPaciente} />
        </S.SummaryWrapper>
        <S.ContentWrapper>
          <S.MainTitleWrapper>
            <S.TextWrapper>
              <S.TextIconImage src={MetricIcon} />
              <S.MainTitle>Antropometria</S.MainTitle>
            </S.TextWrapper>
            <CustomButton
              theme="secondary"
              onClick={() => handleSaveRecord()}
              disabled={pageType === "view"}
            >
              <FaSave style={{ marginRight: "4px" }} /> Salvar
            </CustomButton>
          </S.MainTitleWrapper>
          <S.SectionsWrapper>
            <S.Subtitle>Informações Básicas</S.Subtitle>
            <S.SeciontInputsWrapper>
              <CustomInput
                type="date"
                label="Data"
                onChange={(value) => {
                  setAntropometryObj((prevState) => ({
                    ...prevState,
                    date: formatDate(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                value={antropometryObj.date}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="Altura (cm)"
                value={antropometryObj.height?.toString()}
                onChange={(value) => {
                  const height = Number(value.target.value);
                  setAntropometryObj((prevState) => ({
                    ...prevState,
                    height: height,
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
                errorMessage={antropometryObj.height == 0 ? '' : (antropometryObj.height || 0) < 50 || (antropometryObj.height || 0) > 300 ? "Altura inválida" : ""}
              />
              <CustomInput
                type="number"
                label="Peso (kg)"
                value={antropometryObj.weight?.toString()}
                onChange={(value) => {
                  setAntropometryObj((prevState) => ({
                    ...prevState,
                    weight: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
                errorMessage={antropometryObj.weight == 0 ? '' : (antropometryObj.weight || 0) < 2 || (antropometryObj.weight || 0) > 500 ? "Peso inválido" : ""}
              />
              <div style={{ width: "120%", height: 40 }}></div>
            </S.SeciontInputsWrapper>
            <S.ExpandableAntropometryWrapper>
              <ExpandableSection
                title="Composição corporal"
                icon={Composition}
                isOpen={true}
                content={
                  <S.BodyCompositionWrapper>
                    <div style={{ display: "flex", gap: "10px", alignItems: "flex-start" }}>
                      <Model
                        data={bodyData}
                        style={{
                          width: "14rem",
                          padding: "2rem",
                          flexShrink: 0,
                        }}
                        type={modelType === "anterior" ? ModelType.ANTERIOR : ModelType.POSTERIOR}
                        highlightedColors={["#1270FC"]}
                      />
                      <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: "10px" }}>
                        <S.SeciontInputsWrapper>
                          <ButtonOptionList
                            title="Tipo"
                            setSelectedValue={setBodyType}
                            selectedValue={bodyType}
                            options={[
                              {
                                title: "Medidas Corporais",
                                value: "medidascorporais",
                              },
                              {
                                title: "Dobras Cutâneas",
                                value: "dobrascutaneas",
                              },
                              {
                                title: "Bioimpedância",
                                value: "bioimpedancia"
                              }
                            ]}
                          />
                        </S.SeciontInputsWrapper>
                        <div>
                          {bodyType === "medidascorporais" ? (
                            <>
                              <div style={{ marginTop: 20 }}></div>
                              <S.Subtitle>Circunferências</S.Subtitle>
                              <S.SeciontInputsWrapper>
                                <CustomInput
                                  type="number"
                                  label="Braço Direito Relaxado (cm)"
                                  value={antropometryObj.arm_relaxed_right?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      arm_relaxed_right: Number(value.target.value),
                                    }));
                                  }}
                                  onFocus={() => handleClick({
                                    muscle: 'right-biceps',
                                    data: {
                                      exercises: [],
                                      frequency: 0,
                                    },
                                  })}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.arm_relaxed_right == 0 ? '' : (antropometryObj.arm_relaxed_right || 0) < 10 || (antropometryObj.arm_relaxed_right || 0) > 100 ? "Medida inválida" : ""}
                                />
                                <CustomInput
                                  type="number"
                                  label="Braço Direito Contraído (cm)"
                                  value={antropometryObj.arm_contracted_right?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      arm_contracted_right: Number(value.target.value),
                                    }));
                                  }}
                                  onFocus={() => handleClick({
                                    muscle: 'right-biceps',
                                    data: {
                                      exercises: [],
                                      frequency: 0,
                                    },
                                  })}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.arm_contracted_right == 0 ? '' : (antropometryObj.arm_contracted_right || 0) < 10 || (antropometryObj.arm_contracted_right || 0) > 100 ? "Medida inválida" : ""}
                                />
                                <CustomInput
                                  type="number"
                                  label="Tórax (cm)"
                                  value={antropometryObj.chest?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      chest: Number(value.target.value),
                                    }));
                                  }}
                                  onFocus={() => handleClick({
                                    muscle: 'chest',
                                    data: {
                                      exercises: [],
                                      frequency: 0,
                                    },
                                  })}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.chest == 0 ? '' : (antropometryObj.chest || 0) < 30 || (antropometryObj.chest || 0) > 200 ? "Medida inválida" : ""}
                                />
                                <CustomInput
                                  type="number"
                                  label="Cintura (cm)"
                                  value={antropometryObj.waist?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      waist: Number(value.target.value),
                                    }));
                                  }}
                                  onFocus={() => handleClick({
                                    muscle: 'obliques',
                                    data: {
                                      exercises: [],
                                      frequency: 0,
                                    },
                                  })}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.waist == 0 ? '' : (antropometryObj.waist || 0) < 30 || (antropometryObj.waist || 0) > 200 ? "Medida inválida" : ""}
                                />
                                <CustomInput
                                  type="number"
                                  label="Abdômen (cm)"
                                  value={antropometryObj.abdomen?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      abdomen: Number(value.target.value),
                                    }));
                                  }}
                                  onFocus={() => handleClick({
                                    muscle: 'abs',
                                    data: {
                                      exercises: [],
                                      frequency: 0,
                                    },
                                  })}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.abdomen == 0 ? '' : (antropometryObj.abdomen || 0) < 30 || (antropometryObj.abdomen || 0) > 200 ? "Medida inválida" : ""}
                                />
                              </S.SeciontInputsWrapper>

                              <S.SeciontInputsWrapper>
                                <CustomInput
                                  type="number"
                                  label="Quadril (cm)"
                                  value={antropometryObj.hip?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      hip: Number(value.target.value),
                                    }));
                                  }}
                                  onFocus={() => handleClick({
                                    muscle: 'abductors',
                                    data: {
                                      exercises: [],
                                      frequency: 0,
                                    },
                                  })}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.hip == 0 ? '' : (antropometryObj.hip || 0) < 30 || (antropometryObj.hip || 0) > 200 ? "Medida inválida" : ""}
                                />
                                <CustomInput
                                  type="number"
                                  label="Coxa (cm)"
                                  value={antropometryObj.thigh?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      thigh: Number(value.target.value),
                                    }));
                                  }}
                                  onFocus={() => handleClick({
                                    muscle: 'right-quadriceps',
                                    data: {
                                      exercises: [],
                                      frequency: 0,
                                    },
                                  })}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.thigh == 0 ? '' : (antropometryObj.thigh || 0) < 30 || (antropometryObj.thigh || 0) > 200 ? "Medida inválida" : ""}
                                />
                                <CustomInput
                                  type="number"
                                  label="Panturrilha (cm)"
                                  value={antropometryObj.calf?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      calf: Number(value.target.value),
                                    }));
                                  }}
                                  onFocus={() => handleClick({
                                    muscle: 'right-calves',
                                    data: {
                                      exercises: [],
                                      frequency: 0,
                                    },
                                  })}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.calf == 0 ? '' : (antropometryObj.calf || 0) < 20 || (antropometryObj.calf || 0) > 100 ? "Medida inválida" : ""}
                                />
                                <CustomInput
                                  type="number"
                                  label="RCQ"
                                  value={antropometryObj.rcq?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      rcq: Number(value.target.value),
                                    }));
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={true}
                                />
                                <CustomInput
                                  type="number"
                                  label="RCE"
                                  value={antropometryObj.rce?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      rce: Number(value.target.value),
                                    }));
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={true}
                                />
                              </S.SeciontInputsWrapper>
                            </>
                          ) : null}

                          {bodyType === "dobrascutaneas" ? (
                            <>
                              <div style={{ marginTop: 20 }}></div>
                              <S.Subtitle>Dobras</S.Subtitle>
                              <S.SeciontInputsWrapper>
                                <CustomInput
                                  type="number"
                                  label="Abdômen (mm)"
                                  value={antropometryObj.abdomen_dobras?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      abdomen_dobras: Number(value.target.value),
                                    }));
                                  }}
                                  onFocus={() => handleClick({
                                    muscle: 'abs',
                                    data: {
                                      exercises: [],
                                      frequency: 0,
                                    },
                                  })}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.abdomen_dobras == 0 ? '' : (antropometryObj.abdomen_dobras || 0) < 1 || (antropometryObj.abdomen_dobras || 0) > 100 ? "Medida inválida" : ""}
                                />
                                <CustomInput
                                  type="number"
                                  label="SupraIlíaca (mm)"
                                  value={antropometryObj.supra_iliaca?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      supra_iliaca: Number(value.target.value),
                                    }));
                                  }}
                                  onFocus={() => handleClick({
                                    muscle: 'abs',
                                    data: {
                                      exercises: [],
                                      frequency: 0,
                                    },
                                  })}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.supra_iliaca == 0 ? '' : (antropometryObj.supra_iliaca || 0) < 1 || (antropometryObj.supra_iliaca || 0) > 100 ? "Medida inválida" : ""}
                                />
                                <CustomInput
                                  type="number"
                                  label="Axilar Média (mm)"
                                  value={antropometryObj.axilar_media?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      axilar_media: Number(value.target.value),
                                    }));
                                  }}
                                  onFocus={() => handleClick({
                                    muscle: 'chest',
                                    data: {
                                      exercises: [],
                                      frequency: 0,
                                    },
                                  })}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.axilar_media == 0 ? '' : (antropometryObj.axilar_media || 0) < 1 || (antropometryObj.axilar_media || 0) > 100 ? "Medida inválida" : ""}
                                />
                                <CustomInput
                                  type="number"
                                  label="Tórax (mm)"
                                  value={antropometryObj.chest_dobras?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      chest_dobras: Number(value.target.value),
                                    }));
                                  }}
                                  onFocus={() => handleClick({
                                    muscle: 'chest',
                                    data: {
                                      exercises: [],
                                      frequency: 0,
                                    },
                                  })}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.chest_dobras == 0 ? '' : (antropometryObj.chest_dobras || 0) < 1 || (antropometryObj.chest_dobras || 0) > 100 ? "Medida inválida" : ""}
                                />
                              </S.SeciontInputsWrapper>
                              <S.SeciontInputsWrapper>
                                <CustomInput
                                  type="number"
                                  label="Coxa (mm)"
                                  value={antropometryObj.thigh_dobras?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      thigh_dobras: Number(value.target.value),
                                    }));
                                  }}
                                  onFocus={() => handleClick({
                                    muscle: 'right-quadriceps',
                                    data: {
                                      exercises: [],
                                      frequency: 0,
                                    },
                                  })}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.thigh_dobras == 0 ? '' : (antropometryObj.thigh_dobras || 0) < 1 || (antropometryObj.thigh_dobras || 0) > 100 ? "Medida inválida" : ""}
                                />
                                <CustomInput
                                  type="number"
                                  label="Tríceps (mm)"
                                  value={antropometryObj.triceps?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      triceps: Number(value.target.value),
                                    }));
                                  }}
                                  onFocus={() => handleClick({
                                    muscle: 'triceps',
                                    data: {
                                      exercises: [],
                                      frequency: 0,
                                    },
                                  }, true)}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.triceps == 0 ? '' : (antropometryObj.triceps || 0) < 1 || (antropometryObj.triceps || 0) > 100 ? "Medida inválida" : ""}
                                />
                                <CustomInput
                                  type="number"
                                  label="SubEscapular (mm)"
                                  value={antropometryObj.subscapular?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      subscapular: Number(value.target.value),
                                    }));
                                  }}
                                  onFocus={() => {
                                    handleClick({
                                      muscle: 'upper-back',
                                      data: {
                                        exercises: [],
                                        frequency: 0,
                                      },
                                    }, true)
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.triceps == 0 ? '' : (antropometryObj.subscapular || 0) < 1 || (antropometryObj.subscapular || 0) > 100 ? "Medida inválida" : ""}
                                />
                                <CustomInput
                                  type="number"
                                  label="Densidade Corporal (kg/m3)"
                                  value={antropometryObj.body_density?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      body_density: Number(value.target.value),
                                    }));
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled
                                />
                              </S.SeciontInputsWrapper>
                            </>
                          ) : null}

                          {bodyType === "bioimpedancia" ? (
                            <>
                              <div style={{ marginTop: 20 }}></div>
                              <S.Subtitle>Resultado</S.Subtitle>
                              <S.SeciontInputsWrapper>
                                <CustomInput
                                  type="number"
                                  label="Massa de Gordura (kg)"
                                  value={antropometryObj.fat_mass?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      fat_mass: Number(value.target.value),
                                    }));
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.fat_mass == 0 ? '' : (antropometryObj.fat_mass || 0) < 1 || (antropometryObj.fat_mass || 0) > 200 ? "Massa de gordura inválida" : ""}
                                />
                                <CustomInput
                                  type="number"
                                  label="Nível de Gordura Visceral"
                                  value={antropometryObj.visceral_fat_level?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      visceral_fat_level: Number(value.target.value),
                                    }));
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.visceral_fat_level == 0 ? '' : (antropometryObj.visceral_fat_level || 0) < 1 || (antropometryObj.visceral_fat_level || 0) > 50 ? "Nível de gordura visceral inválido" : ""}
                                />
                                <CustomInput
                                  type="number"
                                  label="PGC (%)"
                                  value={antropometryObj.pgc?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      pgc: Number(value.target.value),
                                    }));
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.pgc == 0 ? '' : (antropometryObj.pgc || 0) < 1 || (antropometryObj.pgc || 0) > 100 ? "PGC inválido" : ""}
                                />
                                <CustomInput
                                  type="number"
                                  label="TMB (kcal)"
                                  value={antropometryObj.tmb?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      tmb: Number(value.target.value),
                                    }));
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.tmb == 0 ? '' : (antropometryObj.tmb || 0) < 500 || (antropometryObj.tmb || 0) > 5000 ? "TMB inválido" : ""}
                                />
                              </S.SeciontInputsWrapper>
                              <S.SeciontInputsWrapper>
                                <CustomInput
                                  type="number"
                                  label="Massa livre de Gordura"
                                  value={antropometryObj.fat_free_mass?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      fat_free_mass: Number(value.target.value),
                                    }));
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.fat_free_mass == 0 ? '' : (antropometryObj.fat_free_mass || 0) < 1 || (antropometryObj.fat_free_mass || 0) > 200 ? "Massa livre de gordura inválida" : ""}
                                />
                                <CustomInput
                                  type="number"
                                  label="Massa Muscular Esquelética"
                                  value={antropometryObj.skeletal_muscle_mass?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      skeletal_muscle_mass: Number(value.target.value),
                                    }));
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.skeletal_muscle_mass == 0 ? '' : (antropometryObj.skeletal_muscle_mass || 0) < 1 || (antropometryObj.skeletal_muscle_mass || 0) > 200 ? "Massa muscular esquelética inválida" : ""}
                                />
                                <CustomInput
                                  type="number"
                                  label="Grau de Obesidade"
                                  value={antropometryObj.obesity_grade?.toString()}
                                  onChange={(value) => {
                                    setAntropometryObj((prevState) => ({
                                      ...prevState,
                                      obesity_grade: Number(value.target.value),
                                    }));
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                  errorMessage={antropometryObj.obesity_grade == 0 ? '' : (antropometryObj.obesity_grade || 0) < 1 || (antropometryObj.obesity_grade || 0) > 10 ? "Grau de obesidade inválido" : ""}
                                />
                              </S.SeciontInputsWrapper>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </S.BodyCompositionWrapper>
                }
              />
            </S.ExpandableAntropometryWrapper>
          </S.SectionsWrapper>
        </S.ContentWrapper>


        {!["estagiários", "estagiario", "estagiarios", "estagiário"].includes(
          userRole || ""
        ) && (
            <S.ContentWrapper>
              <S.MainTitleWrapper onClick={() => setAnamneseExpandable(!anamneseExpandable)}>
                <S.TextWrapper>
                  <S.TextIconImage src={CheckList} />
                  <S.MainTitle>Anamnese</S.MainTitle>
                </S.TextWrapper>
                <S.IconWrapper>
                  {anamneseExpandable ? (
                    <FaAngleUp size={24} />
                  ) : (
                    <FaAngleDown size={24} />
                  )}
                </S.IconWrapper>
              </S.MainTitleWrapper>
              <AnimatePresence>
                {anamneseExpandable ? (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.5, ease: "easeOut" }}
                    style={{ overflow: "hidden" }}
                  >
                    <S.SectionsWrapper>
                      <S.SeciontInputsWrapper>
                        <CustomInput
                          label="Data"
                          type="date"
                          value={anamneseObj.date}
                          inputStyle={{ height: 40 }}
                          onChange={(value) => {
                            setAnamneseObj(prevState => ({
                              ...prevState,
                              date: formatDate(value.target.value),
                            }));
                          }}
                          disabled={pageType === "view"}
                        />
                        <S.StatusIndicatorWrapper>
                          <CustomInput
                            type="number"
                            label="Sugestão de Peso Ideal (kg)"
                            value={anamneseObj?.ideal_weight.toString()}
                            onChange={(value) => {
                              anamneseObj.ideal_weight =
                                Number(value.target.value);
                              setAnamneseObj({ ...anamneseObj });
                            }}
                            inputStyle={{ height: 40 }}
                            disabled={pageType === "view"}
                            errorMessage={Number(anamneseObj.ideal_weight || 0) == 0 ? '' : Number(anamneseObj.ideal_weight || 0) < 2 || Number(anamneseObj.ideal_weight || 0) > 500 ? "Peso ideal inválido" : ""}
                          />
                          <S.StatusIndicator
                            color={getStatusColor(Number(anamneseObj.ideal_weight) ?? 0, { green: 0.8, yellow: 0.9, red: 1.0 })}
                          />
                        </S.StatusIndicatorWrapper>
                        <CustomInput
                          type="number"
                          label="Meta (kg)"
                          value={anamneseObj?.target_weight.toString()}
                          onChange={(value) => {
                            anamneseObj.target_weight =
                              Number(value.target.value);
                            setAnamneseObj({ ...anamneseObj });
                          }}
                          inputStyle={{ height: 40 }}
                          disabled={pageType === "view"}
                          errorMessage={Number(anamneseObj.target_weight || 0) == 0 ? '' : Number(anamneseObj.target_weight || 0) < 2 || Number(anamneseObj.target_weight || 0) > 500 ? "Peso Meta inválido" : ""}
                        />
                        <div style={{ width: "120%", height: 40 }}></div>
                      </S.SeciontInputsWrapper>
                      <S.ExpandableWrapper>
                        <ExpandableSection
                          title="Hábitos de vida"
                          icon={LifeStyle}
                          content={
                            <S.HabitsWrapper>
                              <ButtonOptionList
                                selectedValue={foodRestrictionSelectedOption}
                                setSelectedValue={
                                  setFoodRestrictionSelectedOption
                                }
                                title="Restrição alimentar"
                                disabled={pageType === "view"}
                                options={[
                                  {
                                    title: "Nenhum",
                                    value: "nenhum",
                                  },
                                  {
                                    title: "Vegetariano",
                                    value: "vegetariano",
                                  },
                                  {
                                    title: "Vegano",
                                    value: "vegano",
                                  },
                                ]}
                              />
                              <div style={{ display: 'flex', justifyContent: 'left' }}>
                                <MultiOptionButtonList
                                  listOptions={[
                                    {
                                      title: "Ingere bebida alcoólica",
                                      value: "drinkAlcohol",
                                      icons: <IoBeer size={22} />
                                    },
                                    {
                                      title: "Fumante",
                                      value: "smoking",
                                      icons: <GiCigarette size={22} />
                                    },
                                  ]}
                                  setSelectedList={(selectedList: any) => {
                                    setHabitos(selectedList);
                                    setAnamneseObj((prevState) => ({
                                      ...prevState,
                                      lifeHabits: {
                                        ...prevState.lifeHabits,
                                        drinkAlcohol: selectedList.includes("drinkAlcohol"),
                                        smoking: selectedList.includes("smoking"),
                                      },
                                    }));
                                  }}
                                  selectedList={habitos}
                                  disabled={pageType === "view"}
                                />
                              </div>
                              <div style={{ width: '50%', display: habitos.includes("drinkAlcohol") && habitos.includes("smoking") ? 'flex' : 'block', gap: '8px' }}>
                                {habitos.includes("drinkAlcohol") && (
                                  <CustomInput
                                    type="number"
                                    label="Frequência de ingestão de bebida alcoólica"
                                    value={anamneseObj.lifeHabits.drinkAlcoholFrequency?.toString() || ""}
                                    onChange={(value) => {
                                      setAnamneseObj((prevState) => ({
                                        ...prevState,
                                        lifeHabits: {
                                          ...prevState.lifeHabits,
                                          drinkAlcoholFrequency: Number(value.target.value),
                                        },
                                      }));
                                    }}
                                    inputStyle={{ height: 40 }}
                                    disabled={pageType === "view"}
                                  />
                                )}
                                {habitos.includes("smoking") && (
                                  <CustomInput
                                    type="text"
                                    label="Frequência de uso de cigarro"
                                    value={anamneseObj.lifeHabits.smokingFrequency?.toString() || ""}
                                    onChange={(value) => {
                                      setAnamneseObj((prevState) => ({
                                        ...prevState,
                                        lifeHabits: {
                                          ...prevState.lifeHabits,
                                          smokingFrequency: Number(value.target.value),
                                        },
                                      }));
                                    }}
                                    inputStyle={{ height: 40 }}
                                    disabled={pageType === "view"}
                                  />
                                )}
                              </div>
                              <S.AwayHomeWrapper>
                                <S.CheckBoxWrapper>
                                  <S.InputRecord
                                    type="checkbox"
                                    checked={
                                      anamneseObj.lifeHabits.mealsAwayFromHome
                                    }
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setHasMealsAwayFromHome(
                                        !hasMealsAwayFromHome
                                      );
                                      anamneseObj.lifeHabits.mealsAwayFromHome =
                                        event.target.checked;
                                      setAnamneseObj({ ...anamneseObj });
                                    }}
                                    disabled={pageType === "view"}
                                  />
                                  <S.CheckBoxLabel>
                                    Refeições fora de casa
                                  </S.CheckBoxLabel>
                                </S.CheckBoxWrapper>
                                <CustomInput
                                  type="string"
                                  placeholder="Quais"
                                  value={anamneseObj.lifeHabits.mealDescription}
                                  inputStyle={{ height: 40, width: "40%" }}
                                  onChange={(value) => {
                                    anamneseObj.lifeHabits.mealDescription =
                                      value.target.value;
                                    setAnamneseObj({ ...anamneseObj });
                                  }}
                                  disabled={
                                    !hasMealsAwayFromHome || pageType === "view"
                                  }
                                />
                              </S.AwayHomeWrapper>
                              <S.SectionsWrapper>
                                <S.SectionTitle>Habitos de compra</S.SectionTitle>
                                <S.SeciontInputsWrapper>
                                  <CustomInput
                                    type="number"
                                    label="Mora com quantas pessoas?"
                                    value={anamneseObj.lifeHabits.numberOfPeopleLives?.toString()}
                                    onChange={(value) => {
                                      anamneseObj.lifeHabits.numberOfPeopleLives =
                                        Number(value.target.value);
                                      setAnamneseObj({ ...anamneseObj });
                                    }}
                                    inputStyle={{ height: 40 }}
                                    disabled={pageType === "view"}
                                    errorMessage={Number(anamneseObj.lifeHabits.numberOfPeopleLives || 0) == 0 ? '' : Number(anamneseObj.lifeHabits.numberOfPeopleLives || 0) < 1 || Number(anamneseObj.lifeHabits.numberOfPeopleLives || 0) > 50 ? "Número de pessoas inválido" : ""}
                                  />
                                  <CustomInput
                                    type="text"
                                    label="Quem realiza as compras da casa?"
                                    value={
                                      anamneseObj.lifeHabits
                                        .whoDoesHouseholdShopping
                                    }
                                    onChange={(value) => {
                                      anamneseObj.lifeHabits.whoDoesHouseholdShopping =
                                        value.target.value;
                                      setAnamneseObj({ ...anamneseObj });
                                    }}
                                    inputStyle={{ height: 40 }}
                                    disabled={pageType === "view"}
                                  />
                                </S.SeciontInputsWrapper>
                                <S.SeciontInputsWrapper>
                                  <CustomInput
                                    type="text"
                                    label="Onde realiza as compras?"
                                    value={anamneseObj.lifeHabits.whereYouShop}
                                    onChange={(value) => {
                                      anamneseObj.lifeHabits.whereYouShop =
                                        value.target.value;
                                      setAnamneseObj({ ...anamneseObj });
                                    }}
                                    inputStyle={{ height: 40 }}
                                    disabled={pageType === "view"}
                                  />
                                  <S.PurcasheQuantityWrapper>
                                    <CustomInput
                                      type="number"
                                      label="Quantas vezes por mês?"
                                      value={anamneseObj.lifeHabits.howManyTimes?.toString()}
                                      onChange={(value) => {
                                        anamneseObj.lifeHabits.howManyTimes =
                                          Number(value.target.value);
                                        setAnamneseObj({ ...anamneseObj });
                                      }}
                                      inputStyle={{ height: 40 }}
                                      disabled={pageType === "view"}
                                      errorMessage={Number(anamneseObj.lifeHabits.howManyTimes || 0) == 0 ? '' : Number(anamneseObj.lifeHabits.howManyTimes || 0) < 1 || Number(anamneseObj.lifeHabits.howManyTimes || 0) > 31 ? "Número de vezes inválido" : ""}
                                    />
                                    <S.CheckBoxWrapper>
                                      <S.InputRecord
                                        type="checkbox"
                                        checked={
                                          anamneseObj.lifeHabits.takeChildren
                                        }
                                        onChange={(
                                          event: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          anamneseObj.lifeHabits.takeChildren =
                                            event.target.checked;
                                          setAnamneseObj({ ...anamneseObj });
                                        }}
                                        disabled={pageType === "view"}
                                      />
                                      <S.CheckBoxLabel>
                                        Leva os filhos junto?
                                      </S.CheckBoxLabel>
                                    </S.CheckBoxWrapper>
                                  </S.PurcasheQuantityWrapper>
                                </S.SeciontInputsWrapper>
                                <S.SeciontInputsWrapper>
                                  <CustomInput
                                    type="number"
                                    label="Litros de óleo utilizado por mês (L)"
                                    value={anamneseObj.lifeHabits.litersOilUsedPerMonth?.toString()}
                                    onChange={(value) => {
                                      anamneseObj.lifeHabits.litersOilUsedPerMonth =
                                        Number(value.target.value);
                                      setAnamneseObj({ ...anamneseObj });
                                    }}
                                    inputStyle={{ height: 40 }}
                                    disabled={pageType === "view"}
                                    errorMessage={Number(anamneseObj.lifeHabits.litersOilUsedPerMonth || 0) == 0 ? '' : Number(anamneseObj.lifeHabits.litersOilUsedPerMonth || 0) < 0 || Number(anamneseObj.lifeHabits.litersOilUsedPerMonth || 0) > 100 ? "Valor inválido" : ""}
                                  />
                                  <CustomInput
                                    type="number"
                                    label="Kg de sal utilizado por mês (kg)"
                                    value={anamneseObj.lifeHabits.kiloSaltUsedPerMonth?.toString()}
                                    onChange={(value) => {
                                      anamneseObj.lifeHabits.kiloSaltUsedPerMonth =
                                        Number(value.target.value);
                                      setAnamneseObj({ ...anamneseObj });
                                    }}
                                    inputStyle={{ height: 40 }}
                                    disabled={pageType === "view"}
                                    errorMessage={Number(anamneseObj.lifeHabits.kiloSaltUsedPerMonth || 0) == 0 ? '' : Number(anamneseObj.lifeHabits.kiloSaltUsedPerMonth || 0) < 0 || Number(anamneseObj.lifeHabits.kiloSaltUsedPerMonth || 0) > 100 ? "Valor inválido" : ""}
                                  />
                                </S.SeciontInputsWrapper>
                              </S.SectionsWrapper>
                            </S.HabitsWrapper>
                          }
                        />
                        <ExpandableSection
                          title="Patologias"
                          icon={Genoma}
                          content={
                            <S.PatologyMainWrapper>
                              <ExpandableOptionList
                                listOptions={PatologyList}
                                setSelectedList={setSelectedPathologyList}
                                selectedList={selectedPathologyList}
                                disabled={pageType === "view"}
                              />
                              <S.PatologyTextAreaWrapper>
                                <CustomTextArea
                                  label="Outras patologias"
                                  value={anamneseObj.pathology.otherPathologies}
                                  onChange={(value) => {
                                    anamneseObj.pathology.otherPathologies =
                                      value.target.value;
                                    setAnamneseObj({ ...anamneseObj });
                                  }}
                                  disabled={pageType === "view"}
                                />
                                <CustomTextArea
                                  label="Medicamentos"
                                  value={anamneseObj.pathology.medicines}
                                  onChange={(value) => {
                                    anamneseObj.pathology.medicines =
                                      value.target.value;
                                    setAnamneseObj({ ...anamneseObj });
                                  }}
                                  disabled={pageType === "view"}
                                />
                                <CustomTextArea
                                  label="Exames"
                                  value={anamneseObj.pathology.exams}
                                  onChange={(value) => {
                                    anamneseObj.pathology.exams =
                                      value.target.value;
                                    setAnamneseObj({ ...anamneseObj });
                                  }}
                                  disabled={pageType === "view"}
                                />
                                <CustomTextArea
                                  label="Histórico familiar"
                                  value={anamneseObj.pathology.familyHistory}
                                  onChange={(value) => {
                                    anamneseObj.pathology.familyHistory =
                                      value.target.value;
                                    setAnamneseObj({ ...anamneseObj });
                                  }}
                                  disabled={pageType === "view"}
                                />
                                <CustomTextArea
                                  label="Observação"
                                  value={anamneseObj.pathology.observation}
                                  onChange={(value) => {
                                    anamneseObj.pathology.observation =
                                      value.target.value;
                                    setAnamneseObj({ ...anamneseObj });
                                  }}
                                  disabled={pageType === "view"}
                                />
                              </S.PatologyTextAreaWrapper>
                            </S.PatologyMainWrapper>
                          }
                        />
                        <ExpandableSection
                          title="Avaliação clínica"
                          icon={Avaliacao}
                          content={
                            <S.ClinicAvaliationWrapper>
                              <S.FoodOptionsListWrapper>
                                <ButtonOptionList
                                  title="Apetite"
                                  setSelectedValue={setAppetiteSelectedOption}
                                  selectedValue={appetiteSelectedOption}
                                  disabled={pageType === "view"}
                                  options={[
                                    {
                                      title: "Normal",
                                      value: "normal",
                                    },
                                    {
                                      title: "Aumentado",
                                      value: "aumentado",
                                    },
                                    {
                                      title: "Diminuido",
                                      value: "diminuido",
                                    },
                                  ]}
                                />

                                <ButtonOptionList
                                  selectedValue={selectedChewing}
                                  setSelectedValue={setSelectedChewing}
                                  disabled={pageType === "view"}
                                  title="Mastigação"
                                  options={[
                                    {
                                      title: "Normal",
                                      value: "normal",
                                    },
                                    {
                                      title: "Rápida",
                                      value: "rapida",
                                    },
                                    {
                                      title: "Lenta",
                                      value: "lenta",
                                    },
                                  ]}
                                />
                              </S.FoodOptionsListWrapper>
                              <ButtonOptionList
                                selectedValue={intestionalHabitSelectedOption}
                                setSelectedValue={
                                  setIntestionalHabitSelectedOption
                                }
                                title="Hábito intestinal"
                                disabled={pageType === "view"}
                                options={[
                                  {
                                    title: "Normal",
                                    value: "normal",
                                  },
                                  {
                                    title: "Constipante",
                                    value: "constipante",
                                  },
                                  {
                                    title: "Diarréico",
                                    value: "diarreico",
                                  },
                                  {
                                    title: "Variado",
                                    value: "variado",
                                  },
                                ]}
                              />
                              <S.EvacuationDataWrapper>
                                <S.EvacuationFrequencyWrapper>
                                  <S.WrapperFrequencyInput>
                                    <CustomInput
                                      label="Frequência de evacuação"
                                      type="number"
                                      value={anamneseObj.clinicalAssessment.evacuationFrequency?.toString()}
                                      onChange={(value) => {
                                        anamneseObj.clinicalAssessment.evacuationFrequency =
                                          Number(value.target.value);
                                        setAnamneseObj({ ...anamneseObj });
                                      }}
                                      inputStyle={{ height: 40 }}
                                      disabled={pageType === "view"}
                                      errorMessage={anamneseObj.clinicalAssessment.evacuationFrequency == 0 ? '' : Number(anamneseObj.clinicalAssessment.evacuationFrequency || 0) < 1 || Number(anamneseObj.clinicalAssessment.evacuationFrequency || 0) > 20 ? "Frequência inválida" : ""}
                                    />
                                    <CustomDropdown
                                      label="Formato"
                                      options={["Teste"]}
                                      selectedOption={stoolShape}
                                      setSelectedOption={setStoolShape}
                                      style={{ color: "black" }}
                                    />
                                  </S.WrapperFrequencyInput>
                                </S.EvacuationFrequencyWrapper>
                                <ButtonOptionList
                                  selectedValue={evacuationFrequency}
                                  setSelectedValue={setEvacuationFrequency}
                                  title=""
                                  disabled={pageType === "view"}
                                  options={[
                                    {
                                      title: "Por dia",
                                      value: "pordia",
                                    },
                                    {
                                      title: "Por semana",
                                      value: "porsemana",
                                    },
                                  ]}
                                />
                              </S.EvacuationDataWrapper>
                              <S.CheckBoxWrapper>
                                <S.InputRecord
                                  type="checkbox"
                                  checked={
                                    anamneseObj.clinicalAssessment.useLaxative
                                  }
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    anamneseObj.clinicalAssessment.useLaxative =
                                      event.target.checked;
                                    setAnamneseObj({ ...anamneseObj });
                                  }}
                                />
                                <S.CheckBoxLabel>
                                  Faz uso de laxante
                                </S.CheckBoxLabel>
                              </S.CheckBoxWrapper>
                              <ButtonOptionList
                                title="Cor das fezes"
                                setSelectedValue={setShitColorOption}
                                selectedValue={shitColorOption}
                                disabled={pageType === "view"}
                                options={[
                                  {
                                    title: "Marrom",
                                    value: "marrom",
                                  },
                                  {
                                    title: "Amarelo",
                                    value: "amarelo",
                                  },
                                  {
                                    title: "Verde",
                                    value: "verde",
                                  },
                                  {
                                    title: "Avermelhado",
                                    value: "avermelhado",
                                  },
                                  {
                                    title: "Escura",
                                    value: "escura",
                                  },
                                  {
                                    title: "Clara",
                                    value: "clara",
                                  },
                                ]}
                              />
                            </S.ClinicAvaliationWrapper>
                          }
                        />
                        <ExpandableSection
                          title="Hábitos urinários"
                          icon={SistemaUrinario}
                          content={
                            <S.UrineExpandableWrapper>
                              <S.UrineStartInformationsWrapper>
                                <CustomInput
                                  type="text"
                                  label="Descreva"
                                  value={anamneseObj.urinaryHabits.describe}
                                  onChange={(value) => {
                                    anamneseObj.urinaryHabits.describe =
                                      value.target.value;
                                    setAnamneseObj({ ...anamneseObj });
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                />
                                <S.HydroInputWrapper>
                                  <CustomInput
                                    type="number"
                                    label="Ingestão hídrica (L)"
                                    value={anamneseObj.urinaryHabits.waterIntake?.toString()}
                                    onChange={(value) => {
                                      anamneseObj.urinaryHabits.waterIntake =
                                        value.target.value;
                                      setAnamneseObj({ ...anamneseObj });
                                    }}
                                    inputStyle={{ height: 40 }}
                                    disabled={pageType === "view"}
                                    errorMessage={Number(anamneseObj.urinaryHabits.waterIntake || 0) == 0 ? '' : Number(anamneseObj.urinaryHabits.waterIntake || 0) < 0 || Number(anamneseObj.urinaryHabits.waterIntake || 0) > 20 ? "Ingestão hídrica inválida" : ""}
                                  />
                                  <S.DescriptionInputText>
                                    Em litros
                                  </S.DescriptionInputText>
                                </S.HydroInputWrapper>
                              </S.UrineStartInformationsWrapper>
                              <ButtonOptionList
                                title="Hidratação urinária"
                                setSelectedValue={setHydroSelectedOption}
                                selectedValue={hydroSelectedOption}
                                disabled={pageType === "view"}
                                options={[
                                  {
                                    title: "1 Bem hidratado",
                                    value: "1bemhidratado",
                                  },
                                  {
                                    title: "2 Bem hidratado",
                                    value: "2bemhidratado",
                                  },
                                  {
                                    title: "3 Bem hidratado",
                                    value: "3bemhidratado",
                                  },
                                  {
                                    title: "4 Mal hidratado",
                                    value: "4malhidratado",
                                  },
                                  {
                                    title: "5 Mal hidratado",
                                    value: "5malhidratado",
                                  },
                                  {
                                    title: "6 Mal hidratado",
                                    value: "6malhidratado",
                                  },
                                  {
                                    title: "7 Desidratado",
                                    value: "7desidratado",
                                  },
                                  {
                                    title: "8 Desidratado",
                                    value: "8desidratado",
                                  },
                                ]}
                              />
                              <CustomTextArea
                                label="Observação"
                                style={{ height: 55 }}
                                value={anamneseObj.urinaryHabits.observation}
                                onChange={(value) => {
                                  anamneseObj.urinaryHabits.observation =
                                    value.target.value;
                                  setAnamneseObj({ ...anamneseObj });
                                }}
                                placeholder="Motivo do paciente, observações gerais, objetivos.."
                                disabled={pageType === "view"}
                              />
                            </S.UrineExpandableWrapper>
                          }
                        />
                        <ExpandableSection
                          title="Hábitos alimentares"
                          icon={Comida}
                          content={
                            <S.FoodsHabits>
                              <S.FoodHabitsInput>
                                <CustomTextArea
                                  label="Suplementos alimentares"
                                  value={anamneseObj.eatingHabits.foodSuplements}
                                  onChange={(value) => {
                                    anamneseObj.eatingHabits.foodSuplements =
                                      value.target.value;
                                    setAnamneseObj({ ...anamneseObj });
                                  }}
                                  disabled={pageType === "view"}
                                />
                                <CustomTextArea
                                  label="Alergia alimentar"
                                  value={anamneseObj.eatingHabits.foodAllergy}
                                  onChange={(value) => {
                                    anamneseObj.eatingHabits.foodAllergy =
                                      value.target.value;
                                    setAnamneseObj({ ...anamneseObj });
                                  }}
                                  disabled={pageType === "view"}
                                />
                                <CustomTextArea
                                  label="Intolerância alimentares"
                                  value={anamneseObj.eatingHabits.foodIntolerance}
                                  onChange={(value) => {
                                    anamneseObj.eatingHabits.foodIntolerance =
                                      value.target.value;
                                    setAnamneseObj({ ...anamneseObj });
                                  }}
                                  disabled={pageType === "view"}
                                />
                                <CustomTextArea
                                  label="Aversão alimentares"
                                  value={anamneseObj.eatingHabits.foodAversions}
                                  onChange={(value) => {
                                    anamneseObj.eatingHabits.foodAversions =
                                      value.target.value;
                                    setAnamneseObj({ ...anamneseObj });
                                  }}
                                  disabled={pageType === "view"}
                                />
                                <CustomTextArea
                                  label="Observação"
                                  value={anamneseObj.eatingHabits.observation}
                                  onChange={(value) => {
                                    anamneseObj.eatingHabits.observation =
                                      value.target.value;
                                    setAnamneseObj({ ...anamneseObj });
                                  }}
                                  disabled={pageType === "view"}
                                />
                              </S.FoodHabitsInput>
                            </S.FoodsHabits>
                          }
                        />
                      </S.ExpandableWrapper>
                    </S.SectionsWrapper>
                    <S.SectionsWrapper>
                      <S.SeciontInputsWrapper>
                        <MultiOptionButtonList
                          listOptions={SegmentList}
                          title="Protocolo"
                          setSelectedList={setSelectedSegmentList}
                          selectedList={selectedSegmentList}
                          disabled={pageType === "view"}
                          singleSelect
                        />
                      </S.SeciontInputsWrapper>
                    </S.SectionsWrapper>
                    <S.SectionsWrapper>
                      <CustomTextArea
                        label="Caso clínico / Objetivo"
                        style={{ height: 200 }}
                        onChange={(value) => {
                          setAnamneseObj(prevState => ({
                            ...prevState,
                            clinical_case: value.target.value,
                          }));
                        }}
                        placeholder="Motivo do paciente, observações gerais, objetivos.."
                        disabled={pageType === "view"}
                      />
                    </S.SectionsWrapper>
                  </motion.div>
                ) : (
                  ""
                )}
              </AnimatePresence>
            </S.ContentWrapper>
          )}
      </S.Wrapper>
    </PageStructure>
  );
};
