import { ReactNode, useEffect, useRef, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { FcCancel } from "react-icons/fc";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { IoFlagOutline } from "react-icons/io5";
import { PiMoneyLight, PiNewspaperClippingThin } from "react-icons/pi";
import CustomInput from "../CustomInput";
import LabelTags from "../LabelTags";
import { Wrapper } from "../Shared";
import {
  DropdownButton,
  DropdownItem,
  DropdownItemNew,
  DropdownList,
  DropdownListPayments,
  DropdownWrapper,
  ElementIcon,
  ElementTitle,
  ProfileImage,
  SideElementWrapper,
} from "./style";
import { authenticateProfessionalImageUrl } from "../../utils/authenticateImageUrl";

interface CustomDropdownProps extends React.HTMLAttributes<HTMLInputElement> {
  label?: string;
  options?: any[];
  selectedOption: string | null;
  setSelectedOption: (option: any) => void;
  leftIcon?: ReactNode;
  dropDownType?: "default" | "payment";
  allowCreate?: boolean;
  createLabel?: string;
  onCreate?: () => void;
  sideElement?: JSX.Element;
  disableBorder?: boolean;
  searchInput?: boolean;
  placeHolder?: string;
  selectAutomatic?: boolean;
  showImage?: boolean;
  onSearchInputChange?: (value: string) => void;
}

const CustomDropdown = ({
  leftIcon,
  label,
  options,
  dropDownType,
  selectedOption,
  setSelectedOption,
  allowCreate,
  createLabel,
  onCreate,
  sideElement,
  disableBorder,
  searchInput,
  placeHolder,
  onSearchInputChange,
  selectAutomatic = true,
  showImage,
}: CustomDropdownProps) => {
  const defaultImage = require("../../assets/user-profile.png");

  const [isOpen, setIsOpen] = useState(false);
  const [containerClicked, setContainerClicked] = useState(false);
  const [inputClick, setInputClick] = useState(false);
  const [optionClicked, setOptionClicked] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState<string[]>([
    "Lançar pagamento",
  ]);
  const [authenticatedImageUrls, setAuthenticatedImageUrls] = useState<{ [key: string]: string }>({});

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
  };

  const getReactIconByPaymentType = (paymentType: string) => {
    switch (paymentType) {
      case "Enviar cobrança":
        return <PiMoneyLight size={15} />;
      case "Confirmar pagamento":
        return <IoFlagOutline size={15} />;
      case "Ver comprovante":
        return <PiNewspaperClippingThin size={15} />;
      case "Cancelar pagamento":
        return <FcCancel size={15} />;
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (containerClicked && inputClick) {
      setContainerClicked(false);
      setInputClick(false);
      return;
    }

    if (containerClicked && optionClicked) {
      setIsOpen(false);
      setContainerClicked(false);
      setInputClick(false);
      setOptionClicked(false);
    }

    setContainerClicked(false);
  }, [isOpen, containerClicked]);

  useEffect(() => {
    if (options?.length === 1 && selectAutomatic) {
      const singleOption = options[0];
      setSelectedOption(singleOption);
      setIsOpen(false);
    }
  }, [options]);

  const getProfilePhoto = async (professionalId: number) => {
    try {
      const token = localStorage.getItem("bridges.token");
      if (token) {
        const url = await authenticateProfessionalImageUrl(professionalId, token);
        setAuthenticatedImageUrls((prevState) => ({
          ...prevState,
          [professionalId]: url,
        }));
      }
    } catch (error) {
      setAuthenticatedImageUrls((prevState) => ({
        ...prevState,
        [professionalId]: defaultImage,
      }));
    }
  };

  useEffect(() => {
    if (showImage && options) {
      options.forEach((option) => {
        if (option.photo && !authenticatedImageUrls[option.id]) {
          getProfilePhoto(option.id);
        }
      });
    }
  }, [options, showImage]);

  return (
    <Wrapper>
      {label && <span>{label}</span>}
      <DropdownWrapper
        ref={dropdownRef}
        type={dropDownType || "default"}
        disableBorder={disableBorder}
        onClick={() => {
          setContainerClicked(true);
          setIsOpen(true);
        }}
      >
        {leftIcon && leftIcon}
        <DropdownButton type={dropDownType || "default"}>
          {selectedOption || placeHolder || "Selecione"}
        </DropdownButton>
        {(!dropDownType || dropDownType === "default") && (
          <DropdownList open={isOpen}>
            {searchInput && (
              <CustomInput
                onChange={(event) => {
                  onSearchInputChange?.(event.currentTarget.value);
                }}
                onClick={() => setInputClick(true)}
                name=""
                disableBorder
                placeholder="Pesquisar..."
                leftIcon={
                  <HiMagnifyingGlass
                    size={20}
                    color="var(--primary-icon-color)"
                  />
                }
              />
            )}
            {allowCreate && (
              <DropdownItemNew onClick={onCreate}>
                {createLabel || "Criar novo"}
              </DropdownItemNew>
            )}
            {options &&
              options?.map((option, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => {
                    handleOptionSelect(option);
                    setOptionClicked(true);
                  }}
                >
                  <SideElementWrapper>
                    <LabelTags
                      info={option.tag?.name}
                      color={option.tag?.color}
                    />
                  </SideElementWrapper>
                  <ElementTitle>
                    {option.alias || option.name || option.tag || option}
                    {option.price ? ` - R$ ${option.price},00` : ""}
                    {showImage ? (
                      <ProfileImage
                        src={authenticatedImageUrls[option.id] || defaultImage}
                        alt="Perfil"
                        onError={(e) => {
                          (e.currentTarget as HTMLImageElement).src =
                            defaultImage;
                        }}
                      />
                    ) : null}
                  </ElementTitle>
                  {option.icon && <ElementIcon> {option.icon} </ElementIcon>}
                </DropdownItem>
              ))}
          </DropdownList>
        )}
        {dropDownType === "payment" && (
          <DropdownListPayments open={isOpen}>
            {paymentOptions?.map((option: any, index: any) => (
              <DropdownItem
                key={index}
                onClick={() => handleOptionSelect(option)}
              >
                {getReactIconByPaymentType(option)}
                {option}
              </DropdownItem>
            ))}
          </DropdownListPayments>
        )}
        <BsChevronDown className="dropdown-icon" />
      </DropdownWrapper>
    </Wrapper>
  );
};

export default CustomDropdown;
