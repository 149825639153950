import { useCallback, useEffect, useRef, useState } from "react";
import { Column, TableInstance, useTable } from "react-table";
import { Tag } from "../Tags";
import {
  EmptyField,
  StickyHeader,
  Table,
  TableBody,
  TableContainer,
  Title,
} from "./style";

interface CustomTableProps {
  columns: Array<Column<any>>;
  data: Array<object>;
  columnMinWidths: { [key: string]: string };
  title?: string;
  maxHeight?: string;
  minWidth?: string;
  onRowClick?: (row: any) => void;
  emptyText?: string;
}

const CustomTable = ({
  columns,
  data,
  columnMinWidths,
  title,
  maxHeight,
  minWidth,
  onRowClick,
  emptyText,
}: CustomTableProps) => {
  const tableInstance: TableInstance<any> = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const [displayData, setDisplayData] = useState<Array<any>>([]);
  const [visibleCount, setVisibleCount] = useState<number>(20);

  useEffect(() => {
    setDisplayData(data.slice(0, visibleCount));
  }, [data, visibleCount]);

  const observer = useRef<IntersectionObserver | null>(null);

  const lastRowRef = useCallback(
    (node: HTMLTableRowElement | null) => {
      if (node) {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          const lastRow = entries[0];
          if (lastRow.isIntersecting && visibleCount < data.length) {
            setVisibleCount((prevCount) => prevCount + 20);
          }
        });
        observer.current.observe(node);
      }
    },
    [data.length, visibleCount]
  );

  return (
    <>
      {title && <Title>{title}</Title>}
      <TableContainer maxHeight={maxHeight} style={{ overflowX: "hidden" }}>
        <Table {...getTableProps()} minWidth={minWidth}>
          <StickyHeader>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      minWidth: columnMinWidths[column.id] || "auto",
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </StickyHeader>
          {displayData.length != 0 && (
            <TableBody {...getTableBodyProps()}>
              {displayData.map((row, index) => {
                const rowData = tableInstance.rows[index];
                if (rowData) {
                  prepareRow(rowData);
                  return (
                    <tr
                      onClick={() => onRowClick && onRowClick(row)}
                      {...rowData.getRowProps()}
                      ref={index + 1 === visibleCount ? lastRowRef : null}
                    >
                      {rowData.cells.map((cell) => {
                        if (cell.column.id == "start_time") {
                          return (
                            <td>
                              {new Date(cell.value).toLocaleDateString(
                                "pt-BR",
                                {
                                  weekday: "long",
                                  day: "2-digit",
                                  hour: "numeric",
                                  month: "long",
                                  minute: "2-digit",
                                  year: "numeric",
                                }
                              )}
                            </td>
                          );
                        }

                        if (cell.column.id == "end_time") {
                          return (
                            <td>
                              {new Date(cell.value).toLocaleDateString(
                                "pt-BR",
                                {
                                  weekday: "long",
                                  day: "2-digit",
                                  hour: "numeric",
                                  month: "long",
                                  minute: "2-digit",
                                  year: "numeric",
                                }
                              )}
                            </td>
                          );
                        }

                        if (cell.column.id == "status.name") {
                          return (
                            <td style={{ display: "flex" }}>
                              <Tag
                                type={
                                  cell.value == "Pago"
                                    ? "paid"
                                    : cell.value == "Em Aberto"
                                    ? "opened"
                                    : cell.value == "Pagamento Parcial"
                                    ? "pending"
                                    : "default"
                                }
                                text={cell.value}
                              />
                            </td>
                          );
                        }

                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                }
                return null;
              })}
            </TableBody>
          )}
        </Table>
        {displayData.length == 0 && emptyText && (
          <EmptyField>{emptyText}</EmptyField>
        )}
      </TableContainer>
    </>
  );
};

export default CustomTable;