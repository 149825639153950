import { useEffect, useState } from "react";
import { ProgressBar } from "../ProgressBar";
import {
  Content,
  HeaderWrapper,
  Img,
  InfosWrapper,
  OccupationTitle,
  PersonalInfosWrapper,
  ProfessionalName,
  ProgressLabel,
  ProgressWrapper
} from "./style";
import { authenticateProfessionalImageUrl } from "../../utils/authenticateImageUrl";

interface OccupationProps {
  occupation: string;
  professionalName: string;
  occupationValue: number;
  professionalId: number;
  occupationFinalValue: number;
  isSelected: boolean;
  img?: string;
  onClick: () => void;
}

export const Ocuppation = ({
  occupation,
  professionalName,
  occupationValue,
  occupationFinalValue,
  isSelected,
  img,
  professionalId,
  onClick,
}: OccupationProps) => {
  const defaultImage = require("../../assets/user-profile.png");

  const [authenticatedImageUrl, setAuthenticatedImageUrl] = useState<string>(defaultImage);

  useEffect(() => {
    const fetchAuthenticatedImageUrl = async () => {
      try {
        const token = localStorage.getItem("bridges.token");
        if (token && img) {
          const url = await authenticateProfessionalImageUrl(professionalId, token);
          setAuthenticatedImageUrl(url);
        }
      } catch (error) {
        setAuthenticatedImageUrl('');
      }
    };

    if (img) {
      fetchAuthenticatedImageUrl();
    }
  }, [img]);

  return (
    <Content isSelected={isSelected} onClick={onClick}>
      <HeaderWrapper>
        <InfosWrapper>
          {img && <Img src={authenticatedImageUrl} onError={(e) => {
              (e.currentTarget as HTMLImageElement).src =
                defaultImage;
            }}/>
          }
          <PersonalInfosWrapper>
            <OccupationTitle>{occupation}</OccupationTitle>
            <ProfessionalName>{professionalName}</ProfessionalName>
          </PersonalInfosWrapper>
        </InfosWrapper>
      </HeaderWrapper>
      <ProgressWrapper>
        <ProgressLabel>Meta de ocupação</ProgressLabel>
        <ProgressBar
          currentValue={occupationValue}
          finalValue={occupationFinalValue}
        />
      </ProgressWrapper>
    </Content>
  );
};
