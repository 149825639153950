import { useEffect, useMemo, useState } from "react";
import PageStructure from "../../components/PageStructure";
import { CardContent, ChartWrapper, customStyles, HeaderTitle, HeaderWrapper, MainContent, Overlay, PageContent, PatientContent, SubTitle, TableWrapper, Title, AgeChartContainer } from "./style";
import { formatDecimalValues } from "../../utils/formatDecimalValues";
import api from "../../service/api";
import GenderChart from "./Components/GenderChart";
import { FeaturedCard } from "./Components/FeaturedCard";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { IoCloseOutline, IoEyeOutline, IoPersonSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { SimpleFeaturedCard } from "./Components/SimpleFeaturedCard";
import { FaRegClock } from "react-icons/fa";
import { NoShowFeaturedCard } from "./Components/NoShowFeaturedCard";
import NewRevisionCard from "./Components/NewRevisionCard";
import { NewProfit } from "./Components/NewProfit";
import NpsChart from "./Components/NpsChart";
import PatientGoals from "./Components/PatientGoals";
import AgeRangeChart from "./Components/AgeRangeChart";
import { animated, useSpring } from "react-spring";
import Modal from "react-modal";
import PatientProfile from "../Patients/Profile";
import { LifeLine } from "react-loading-indicators";
import CustomButton from "../../components/CustomButton";

export const Operational = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<any[]>([]);
    const [goals, setGoals] = useState<any[]>([]);
    const [genderRate, setGenderRate] = useState<any>(null);
    const [occupancyRate, setOccupancyRate] = useState<any>(null);
    const [averageTime, setAverageTime] = useState<any>(null);
    const [noShowData, setNoShowData] = useState<any>(null);
    const [newPatients, setNewPatients] = useState<any>(null);
    const [ageDistribution, setAgeDistribution] = useState<{ range: string; count: number }[]>([]); // Estado para distribuição por faixa etária
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const today = new Date();

    const last7Days = new Date();
    last7Days.setDate(today.getDate() - 7);

    const last30Days = new Date();
    last30Days.setDate(today.getDate() - 30);

    const [startDate, setStartDate] = useState<Date | null>(last30Days);
    const [endDate, setEndDate] = useState<Date | null>(today);

    const [labels, setLabels] = useState<any[]>([]);
    const [chartData, setChartData] = useState<any[]>([]);

    const [modalPersonDetail, setModalPersonDetail] = useState(false);

    const [professionals, setProfessionals] = useState<any[]>([]);
    const [selectedProfessional, setSelectedProfessional] = useState<any>(null);

    const fadePersonDetail = useSpring({
        from: { opacity: 0 },
        to: { opacity: modalPersonDetail ? 1 : 0 },
    });

    const fetchAllData = async () => {
        try {
            const professionalId = selectedProfessional?.id;
            const [
                genderResponse,
                patientsResponse,
                occupancyResponse,
                noShowResponse,
                newPatientsResponse,
                averageTimeResponse,
                goalsResponse,
                newPatientsChartResponse,
                ageDistributionResponse, // Nova requisição para distribuição por faixa etária
            ] = await Promise.all([
                api.get(professionalId ? `/patients/gender-statistics/?professional_id=${professionalId}` : "/patients/gender-statistics/"),
                api.get(professionalId ? `/patients/not-returned/?professional_id=${professionalId}` : "/patients/not-returned/"),
                api.get(`/reports/occupancy-rate/${startDate?.toISOString().split('T')[0]}/${endDate?.toISOString().split('T')[0]}${professionalId ? `/${professionalId}` : ''}/`),
                api.get(`/reports/canceled-appointments-percentage/?start_date=${startDate?.toISOString().split('T')[0]}&end_date=${endDate?.toISOString().split('T')[0]}${professionalId ? `&professional_id=${professionalId}` : ''}`),
                api.get(`/reports/new-patients-percentage/?start_date=${startDate?.toISOString().split('T')[0]}&end_date=${endDate?.toISOString().split('T')[0]}${professionalId ? `&professional_id=${professionalId}` : ''}`),
                api.get(professionalId ? `/reports/average-consultation-time/?professional_id=${professionalId}` : "/reports/average-consultation-time/"),
                api.get(professionalId ? `/reports/goal-percentages/?professional_id=${professionalId}` : "/reports/goal-percentages/"),
                api.get(`/reports/new-patients-percentage/?start_date=${new Date(new Date().setMonth(new Date().getMonth() - 5)).toISOString().split('T')[0]}&end_date=${new Date().toISOString().split('T')[0]}${professionalId ? `&professional_id=${professionalId}` : ''}`),
                api.get(professionalId ? `/reports/age-distribution/?professional_id=${professionalId}` : "/reports/age-distribution/"), // Requisição ajustada com o caminho correto
            ]);

            setGenderRate(genderResponse.data);
            setData(patientsResponse.data);
            setOccupancyRate(occupancyResponse.data);
            setNoShowData(noShowResponse.data);
            setNewPatients(newPatientsResponse.data.reduce((sum: number, record: any) => sum + record.new_patients, 0));
            setAverageTime(averageTimeResponse.data.average_consultation_time_minutes);
            setGoals(Object.entries(goalsResponse.data.goal_percentages).map(([label, value]) => ({ label, value })));
            setAgeDistribution(ageDistributionResponse.data.age_distribution || []); // Atualiza o estado com os dados de faixa etária

            const newPatientsChartData = newPatientsChartResponse.data;
            const labels = newPatientsChartData.map((item: any) => {
                const [year, month] = item.month.split('-');
                const date = new Date(year, month - 1);
                let monthName = date.toLocaleString('default', { month: 'short' });
                monthName = monthName.replace('.', '');
                monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
                return `${monthName}/${year.slice(-2)}`;
            });
            const chartData = [
                { name: "Novos Pacientes", data: newPatientsChartData.map((item: any) => item.new_patients) }
            ];
            setLabels(labels);
            setChartData(chartData);

        } catch (error) {
            console.error(error);
            setAgeDistribution([]); // Garante que o estado seja vazio em caso de erro
        } finally {
            setIsLoading(false);
        }
    };

    const fetchProfessionals = async () => {
        try {
            const response = await api.get("/professionals/");
            const professionalsData = response.data.map((professional: any) => ({
                id: professional.id,
                name: professional.name,
            }));
            setProfessionals(professionalsData);
        } catch (error) {
            console.error("Erro ao buscar profissionais:", error);
        }
    };

    const handleProfessionalSelect = (name: string) => {
        const professional = professionals.find((p: any) => p.name === name);
        setSelectedProfessional(professional);
    };

    useEffect(() => {
        fetchProfessionals();
        fetchAllData();
    }, [selectedProfessional]);

    useEffect(() => {
        fetchAllData();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const [genderResponse, patientsResponse] = await Promise.all([
                    api.get("/patients/gender-statistics/"),
                    api.get("/patients/not-returned/")
                ]);

                setGenderRate(genderResponse.data);
                setData(patientsResponse.data);
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    const genderOptions = useMemo(() => [
        { name: 'Homens', value: genderRate?.male_count ?? 0, color: '#1270FC' },
        { name: 'Mulheres', value: genderRate?.female_count ?? 0, color: '#FF12A8' },
        { name: 'Outros', value: genderRate?.other_count ?? 0, color: '#7C6C99' },
    ], [genderRate]);

    const fetchOccupancyRate = async (startDate: string, endDate: string, professionalId?: number) => {
        try {
            const url = professionalId
                ? `/reports/occupancy-rate/${startDate}/${endDate}/${professionalId}/`
                : `/reports/occupancy-rate/${startDate}/${endDate}/`;
            const response = await api.get(url);
            setOccupancyRate(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchNoShowRate = async (startDate: string, endDate: string) => {
        try {
            const formattedStartDate = startDate.split('T')[0];
            const formattedEndDate = endDate.split('T')[0];
            const url = `/reports/canceled-appointments-percentage/?start_date=${formattedStartDate}&end_date=${formattedEndDate}`;

            const response = await api.get(url);
            const noShowRateData = response.data;

            setNoShowData(noShowRateData);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchNewPatients = async (startDate: string, endDate: string) => {
        try {
            const formattedStartDate = startDate.split('T')[0];
            const formattedEndDate = endDate.split('T')[0];
            const url = `/reports/new-patients-percentage/?start_date=${formattedStartDate}&end_date=${formattedEndDate}`;

            const response = await api.get(url);
            const newPatientsData = response.data;

            const totalNewPatients = newPatientsData.reduce((sum: number, record: any) => sum + record.new_patients, 0);

            setNewPatients(totalNewPatients);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchNewPatientsChart = async () => {
        try {
            const end = new Date();
            const start = new Date(end);
            start.setMonth(start.getMonth() - 5);

            const formattedStartDate = start.toISOString().split('T')[0];
            const formattedEndDate = end.toISOString().split('T')[0];
            const url = `/reports/new-patients-percentage/?start_date=${formattedStartDate}&end_date=${formattedEndDate}`;

            const response = await api.get(url);
            const newPatientsData = response.data;

            const labels = newPatientsData.map((item: any) => {
                const [year, month] = item.month.split('-');
                const date = new Date(year, month - 1);
                let monthName = date.toLocaleString('default', { month: 'short' });
                monthName = monthName.replace('.', '');
                monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
                return `${monthName}/${year.slice(-2)}`;
            });

            const chartData = [
                { name: "Novos Pacientes", data: newPatientsData.map((item: any) => item.new_patients) }
            ];

            setLabels(labels);
            setChartData(chartData);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchNewPatientsChart();
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            fetchOccupancyRate(
                startDate.toISOString().split('T')[0],
                endDate.toISOString().split('T')[0],
            );

            fetchNoShowRate(
                startDate.toISOString().split('T')[0],
                endDate.toISOString().split('T')[0]
            );

            fetchNewPatients(
                startDate.toISOString().split('T')[0],
                endDate.toISOString().split('T')[0]
            );
        }
    }, [startDate, endDate]);

    const fetchAverageTime = async () => {
        try {
            const url = `/reports/average-consultation-time/`;

            const response = await api.get(url);
            const averageTimeData = response.data;

            setAverageTime(averageTimeData.average_consultation_time_minutes);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchAverageTime();
    }, []);

    const fetchGoalPercentages = async () => {
        try {
            const url = `/reports/goal-percentages/`;

            const response = await api.get(url);
            const goalPercentagesData = response.data;

            const goals = Object.entries(goalPercentagesData.goal_percentages).map(([label, value]) => ({
                label,
                value
            }));

            setGoals(goals);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchGoalPercentages();
    }, []);

    const monthOptions = useMemo(() => [
        { name: 'Janeiro', value: 'january' },
        { name: 'Fevereiro', value: 'february' },
        { name: 'Março', value: 'march' },
        { name: 'Abril', value: 'april' },
        { name: 'Maio', value: 'may' },
        { name: 'Junho', value: 'june' },
        { name: 'Julho', value: 'july' },
        { name: 'Agosto', value: 'august' },
        { name: 'Setembro', value: 'september' },
        { name: 'Outubro', value: 'october' },
        { name: 'Novembro', value: 'november' },
        { name: 'Dezembro', value: 'december' }
    ], []);

    const closeModal = () => {
        setModalPersonDetail(false);
    };

    const handleExport = async (format: string) => {
        try {
            const professionalId = selectedProfessional?.id;
            const url = professionalId
                ? `/patients/not-returned/?professional_id=${professionalId}&export=${format}`
                : `/patients/not-returned/?export=${format}`;
            const response = await api({
                url,
                method: 'GET',
                responseType: 'blob',
            });

            const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = urlBlob;
            link.setAttribute('download', `patients_not_returned_${new Date().toISOString().split('T')[0]}.${format}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(urlBlob);
        } catch (error) {
            console.error(`Erro ao exportar para ${format}:`, error);
            alert(`Falha ao exportar para ${format}. Verifique o console para detalhes.`);
        }
    };

    return (
        <>
            <PageStructure
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                options={professionals.map((professional: any) => professional.name)}
                onSelectChange={handleProfessionalSelect}
            >
                {isLoading && (
                    <Overlay>
                        <LifeLine color="#418DFD" size="large" text="" textColor="" />
                    </Overlay>
                )}
                <PageContent>
                    <MainContent>
                        <CardContent>
                            <FeaturedCard
                                title={"% de ocupação"}
                                value={occupancyRate ? occupancyRate.general_occupancy_rate : 0}
                                format={"percent"}
                                images={[""]}
                                type={occupancyRate ? occupancyRate.percentage_difference > 0 ? "up" : "down" : "up"}
                                percentage={occupancyRate ? `${formatDecimalValues(occupancyRate.percentage_difference)}%` : "0"}
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                            />
                            <SimpleFeaturedCard
                                title={"Média de duração de consultas"}
                                value={averageTime ? averageTime : 0}
                                format={"minutes"}
                                icon={<FaRegClock size={20} color="#fff" />}
                                images={[""]}
                                type={averageTime ? averageTime > 0 ? "up" : "down" : "up"}
                                percentage={averageTime ? `0%` : "N/A"}
                            />
                            <NoShowFeaturedCard
                                title={"% No Show"}
                                value={noShowData ? noShowData.canceled_percentage : 0}
                                format={"percent"}
                                images={[""]}
                                type={noShowData ? noShowData.percentage_difference > 0 ? "up" : "down" : "up"}
                                percentage={noShowData ? `${formatDecimalValues(noShowData.percentage_difference) === "NaN" ? "0" : formatDecimalValues(noShowData.percentage_difference)}%` : "0"}
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                            />
                        </CardContent>

                        <CardContent>
                            <GenderChart title="Gênero dos Pacientes" options={genderOptions} />
                            <PatientGoals title={"Objetivo dos Pacientes"} goals={goals ?? 0} />
                            <NpsChart title="NPS" options={monthOptions} />
                        </CardContent>
                    </MainContent>

                    <CardContent>
                        <PatientContent>
                            <SubTitle>Quantidade de Pacientes Novos</SubTitle>

                            <NewRevisionCard
                                label="Pacientes"
                                value={newPatients ?? 0}
                                icon={<IoPersonSharp size={20} color="#fff" />}
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                            />

                            <ChartWrapper>
                                <SubTitle>Evolução</SubTitle>
                                <NewProfit data={chartData} labelsExtern={labels} />
                            </ChartWrapper>

                            <AgeChartContainer>
                                <SubTitle>Distribuição por Faixa Etária</SubTitle>
                                {ageDistribution.length > 0 ? (
                                    <AgeRangeChart data={ageDistribution} />
                                ) : (
                                    <div>Nenhum dado disponível para exibir.</div>
                                )}
                            </AgeChartContainer>
                        </PatientContent>

                        <TableWrapper>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px', marginBottom: '10px' }}>
                                <Title>Pacientes sem retorno</Title>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <CustomButton
                                        theme="primary"
                                        style={{
                                            backgroundColor: '#28a745',
                                            color: '#fff',
                                            padding: '8px 16px',
                                            borderRadius: '5px',
                                        }}
                                        onClick={() => handleExport('csv')}
                                    >
                                        Exportar CSV
                                    </CustomButton>
                                    <CustomButton
                                        theme="secondary"
                                        style={{
                                            backgroundColor: '#007bff',
                                            color: '#fff',
                                            padding: '8px 16px',
                                            borderRadius: '5px',
                                        }}
                                        onClick={() => handleExport('excel')}
                                    >
                                        Exportar Excel
                                    </CustomButton>
                                </div>
                            </div>
                            <TableContainer component={Paper} style={{ marginTop: 0 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: "#1270FC", position: "sticky", top: 0, overflow: "auto", zIndex: 1 }}>
                                            <TableCell sx={{ color: "#FFFFFF" }} align="left">
                                                Nome
                                            </TableCell>
                                            <TableCell sx={{ color: "#FFFFFF" }} align="left">
                                                CPF
                                            </TableCell>
                                            <TableCell sx={{ color: "#FFFFFF" }} align="left">
                                                Email
                                            </TableCell>
                                            <TableCell sx={{ color: "#FFFFFF" }} align="center">
                                                Ações
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((row: any) => (
                                            <TableRow key={row.id}>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.cpf}</TableCell>
                                                <TableCell>{row.email}</TableCell>
                                                <TableCell align="center">
                                                    <IconButton>
                                                        <IoEyeOutline
                                                            size={20}
                                                            color="var(--primary-icon-color)"
                                                            onClick={() => {
                                                                navigate("", {
                                                                    state: {
                                                                        patient: row,
                                                                        hidesidebar: true
                                                                    },
                                                                });
                                                                setModalPersonDetail(true);
                                                            }}
                                                        />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TableWrapper>
                    </CardContent>
                </PageContent>
            </PageStructure>
            <Modal
                isOpen={modalPersonDetail}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={false}
                style={{
                    ...customStyles,
                    content: {
                        ...customStyles.content,
                        width: '80%'
                    }
                }}
            >
                <animated.div style={fadePersonDetail}>
                    <HeaderWrapper>
                        <HeaderTitle>Paciente</HeaderTitle>
                        <IoCloseOutline
                            size={22}
                            onClick={closeModal}
                            color="#919EAB"
                            cursor={"pointer"}
                        />
                    </HeaderWrapper>
                    <PatientProfile />
                </animated.div>
            </Modal>
        </>
    );
};