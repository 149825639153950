import React, { useEffect, useMemo, useRef, useState } from "react";
import { AiOutlineInbox } from "react-icons/ai"; // ícone para "sem registros"
import { BiSearchAlt2 } from "react-icons/bi";
import { FaRegHandPointer } from "react-icons/fa";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { useFilters, useSortBy, useTable } from "react-table";
import * as styled from "./style";

interface Column {
  Header: string;
  accessor: string;
  Filter?: React.FC<{ column: any }>;
  filter?: string;
}

interface TableProps {
  columns: Column[];
  data: any[];
}

const DefaultColumnFilter: React.FC<{ column: any }> = ({ column }) => {
  const { filterValue, setFilter } = column;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  return (
    <input
      ref={inputRef}
      id="inputFilter"
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value)}
      placeholder={`Filtre por ${column.Header}`}
    />
  );
};

const CustomTableFilter: React.FC<TableProps> = ({ columns, data }) => {
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  ) as any;

  const [showFilter, setShowFilter] = useState<number | null>(null);
  const [internState, setInternState] = useState({} as any);

  const toggleFilter = (index: number | null) => {
    setShowFilter(showFilter === index ? null : index);
  };

  const handleDocumentClick = (e: MouseEvent) => {
    if (e.target instanceof Element && e.target.nodeName !== "INPUT") {
      toggleFilter(showFilter);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    dispatch,
    prepareRow,
    state,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      stateReducer: (newState, action, previousState) => {
        switch (action.type) {
          case "toggleSortBy":
            return newState;

          case "setFilter":
            return newState;

          case "eraseFilter":
            return { ...previousState, filters: [] };

          default:
            return newState;
        }
      },
    },
    useFilters,
    useSortBy
  ) as any;

  useEffect(() => {
    setInternState(state);
  }, [state]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          fontSize: "10px",
        }}
      >
        <div
          id="filterActive"
          style={{
            fontSize: "12px",
            width: "600px",
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
            overflow: "hidden",
          }}
          onClick={() => {
            dispatch({ type: "eraseFilter" });
          }}
        >
          {internState?.filters?.length ? "Remover filtros" : ""}
        </div>
  
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "12px",
            marginTop: "-10px",
          }}
        >
          <div style={{ marginRight: "10px", display: "inline-block" }}>
            <FaRegHandPointer /> - Clique nas colunas para ordenar
          </div>
  
          <div style={{ marginRight: "10px", fontSize: "12px" }}>
            <BiSearchAlt2 /> - Clique nas lupas para filtrar pela coluna
          </div>
        </div>
      </div>
  
      {/* Wrapper da tabela para controlar o scroll */}
      <div
        style={{
          overflowY: "auto",
          maxHeight: "550px", // Defina a altura máxima conforme necessário
          border: "1px solid #ddd",
          borderRadius: "5px",
        }}
      >
        <table
          {...getTableProps()}
          style={{
            borderCollapse: "collapse",
            backgroundColor: "#fff",
            width: "100%",
            tableLayout: "fixed", // Evita layout quebrado
          }}
        >
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()} style={{ borderBottom: "2px solid #ccc" }}>
                {headerGroup.headers.map((column: any, index: any) => (
                  <th
                    key={index}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      padding: "10px",
                      textAlign: "center",
                      backgroundColor: "#1270FC",
                      color: "white",
                      borderBottom: "1px solid #ccc",
                      borderTopLeftRadius: index === 0 ? "0px" : 0,
                      borderTopRightRadius: index === headerGroup.headers.length - 1 ? "0px" : 0,
                      position: "sticky",
                      top: "0",
                      zIndex: "10", // Mantém o header visível
                    }}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <RiArrowDownSFill style={{ verticalAlign: "middle" }} />
                        ) : (
                          <RiArrowUpSFill style={{ verticalAlign: "middle" }} />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                    {index !== 0 && (
                      <div
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "55%",
                          transform: "translateY(-45%)",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleFilter(index);
                        }}
                      >
                        <BiSearchAlt2 />
                      </div>
                    )}
                    {showFilter === index && column.canFilter && (
                      <div
                        style={{
                          position: "absolute",
                          top: "-40px",
                          left: "0",
                          width: "100%",
                          padding: "10px",
                          backgroundColor: "rgba(255, 255, 255, 0.9)",
                          borderRadius: "5px",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                          animation: "fade-in 0.2s ease-in-out",
                        }}
                      >
                        {column.render("Filter")}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ width: "100%" }}>
            {rows.length > 0 ? (
              rows.map((row: any, index: any) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    style={{
                      fontSize: "13px",
                      textDecoration: row?.original?.check?.isChecked ? "line-through" : "",
                      borderBottom: "1px solid #ccc",
                      backgroundColor: row?.original?.check?.isChecked
                        ? "#bdc3c7"
                        : index % 2 === 0
                          ? "#fAfAfA"
                          : "#fff",
                      textAlign: "center",
                    }}
                  >
                    {row.cells.map((cell: any) => {
                    const { Header } = cell.column;
                    let cellValue = cell.render("Cell");

                    if (Header === "Status") {
                      const status = cellValue.props.cell.value;
                      const statusType =
                        status === "Confirmado"
                          ? "success"
                          : status === "Pendente"
                          ? "pending"
                          : status === "Aguardando cliente"
                          ? "waiting"
                          : "danger";

                      return (
                        <td {...cell.getCellProps()} style={{ padding: "10px" }}>
                          <styled.StatusShape type={statusType}>
                            {cellValue}
                          </styled.StatusShape>
                        </td>
                      );
                    }

                    return (
                      <td {...cell.getCellProps()} style={{ padding: "10px" }}>
                        {cellValue}
                      </td>
                    );
                  })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns.length} style={{ textAlign: "center", padding: "20px" }}>
                  <AiOutlineInbox style={{ fontSize: "24px", color: "#ccc" }} />
                  <div style={{ fontSize: "16px", color: "#ccc" }}>Sem registros</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
  
};

export default CustomTableFilter;
