import styled from "styled-components";

interface Option {
  label: string;
  value: string;
}

interface CustomSelectorProps {
  options: Option[];
  selectedOption: string;
  setSelectedOption: (value: string) => void;
}

const SelectorContainer = styled.div`
  display: flex;
  background: #f1f5f9;
  padding: 4px;
  border-radius: 20px;
  width: fit-content;
`;

const SelectorButton = styled.button<{ isSelected: boolean }>`
  padding: 6px 12px;
  font-size: 12px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background: ${({ isSelected }) => (isSelected ? "#2563EB" : "transparent")};
  color: ${({ isSelected }) => (isSelected ? "#FFFFFF" : "#374151")};
  font-weight: ${({ isSelected }) => (isSelected ? "bold" : "normal")};
  transition: background 0.2s, color 0.2s;

  &:hover {
    background: ${({ isSelected }) => (isSelected ? "#2563EB" : "#E5E7EB")};
  }
`;

export default function CustomSelector({
  options,
  selectedOption,
  setSelectedOption,
}: CustomSelectorProps) {
  return (
    <SelectorContainer>
      {options.map((option) => (
        <SelectorButton
          key={option.value}
          isSelected={selectedOption === option.value}
          onClick={() => setSelectedOption(option.value)}
        >
          {option.label}
        </SelectorButton>
      ))}
    </SelectorContainer>
  );
}
