import styled from "styled-components"

interface ModalWrapperProps {
    size?: 'default' | 'large' | 'small';
}

export const ModalWrapper = styled.div<ModalWrapperProps>`
    transition: all 0.3s ease-in-out;
    max-width: 90vw;
`

export const ContentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-height: 675px;
    max-width: 1100px;
    overflow-y: auto;
    gap: 16px;
    width: 100%;
    padding: 24px 13px;
`

export const ContentDaysWrapper = styled.div`
    display: flex;
    max-height: 675px;
    overflow: auto;
    gap: 16px;
    width: 100%;
    padding: 24px 13px;
    flex-direction: column;
    @media only screen and (min-width: 768px) and (max-width: 1440px) {
        max-width: 1100px;
    }
`

interface PaymentContainer{
    haveAvailableHours: boolean;
    isInMonth: boolean;
}

export const PaymentContainer = styled.div<PaymentContainer>`
    box-shadow: ${props => props.isInMonth ? '1px 1px 1px 0.5px rgba(0, 0, 0, 0.1)' : 'none'};
    border: 0.2px solid #d3d3d3;
    border-radius: 10px;
    padding-bottom: 10px;
    width: 220px;

    max-height: 190px;
    min-height: 190px;
    min-width: 220px;

    background: ${props => props.isInMonth ? props.haveAvailableHours ? '#fff' : '#FFEEEE' : '#F3F3F3'};
`

interface MonthWrapper {
    disabled?: boolean;
}

export const MonthWrapper = styled.div<MonthWrapper>`
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border: 0.2px solid #d3d3d3;
    border-radius: 10px;
    width: 300px;
    height: 240px;
    background-color: ${props => props.disabled ? '#D3D3D3' : '#fff'};
    cursor: pointer;
    transition: background-color 0.3s ease; 

    &:hover {
        background-color: #E8E8E8;
    }

`

export const PaymentContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
    margin: '0 3px'
`

export const PaymentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 30px 0 15px;
    margin-top: 3px;
`

export const MonthTitleContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

export const PaymentLabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    width: 100%;
`

const TagsPaymentType = {
    default: {
        color: '#919EAB',
        backgroundColor: '#F4F6F8',
    },
    paid: {
        color: '#118D57',
        backgroundColor: '#D3FCD2',
    },
    pending: {
        color: '#B76E00',
        backgroundColor: '#FFF5CC',
    },
    NonAvailable: {
        color: '#FFBD9B',
        backgroundColor: '#FFBD9B'
    }
}

export interface DolarIconProps {
    type: keyof typeof TagsPaymentType;
}

export const DollarIcon = styled.span<DolarIconProps>`
    width: 33px;
    height: 33px;
    color: ${({ type }) => TagsPaymentType[type].color};
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    right: -12px;
    top: -13px;
    border: 1px solid ${({ type }) => TagsPaymentType[type].color};
    background-color: ${({ type }) => TagsPaymentType[type].backgroundColor};
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const SeparatorBottom = styled.div`
    border-bottom: 1px solid #E5E5E5;
`

interface LabelProps {
    color?: string;
    paid?: boolean;
}

export const PaymentLabel = styled.div<LabelProps>`
    font-size: 14px;
    text-decoration: ${props => props.paid ? 'line-through' : 'none'};
    font-style: normal;
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 700;
    color: ${props => props.color || '#000'};
`

export const PaymentInfo = styled.span`
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    color: ${props => props.color || '#000'} 
`

export const MonthContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`

export const Container = styled.div`
    display: flex;
    width: 100%;
`

export const HoursContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 8px;
    padding: 0 10px 0 15px;
    align-items: center;
`

export const FreeHoursContainer = styled.div`
    display: flex;
    width: 90%;
    margin-top: 8px;
    flex-wrap: wrap;
    gap: 5px;
    padding: 0 10px 0 15px;
    align-items: center;
    max-height: 92px;
    overflow-y: auto;
`

export const PaymentHeaderTitle = styled.span`
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    position: relative;
    padding: 3px;
`

export const ItalicLabel = styled.span`
    font-size: 11px;
    font-weight: 300;
    font-style: italic;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const AvailableHourShape = styled.span`
    padding: 5px;
    font-size: 15px;
    background: #D3FCD2;
    border-radius: 4px;
    color: #118D57;
    cursor: pointer;

    transition: background-color 0.3s ease; 

    &:hover {
        background-color: #118D5744;
    }
`

export const ListEmptyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`

export const ListEmptyLabel = styled.div`
    margin-top: 5px;
    color: #ccc;
`