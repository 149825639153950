import { useEffect, useState } from "react";
import { TbEmpathize, TbStethoscope, TbUserCheck } from "react-icons/tb";
import PageStructure from "../../components/PageStructure";
import { FeaturedCard } from "./Components/FeaturedCard";
import { BodyContent, CardContent, ChartRetentionWrapper, ChartWrapper, Label, ListEmptyLabel, ListEmptyWrapper, LogoWrapper, Overlay, PageContent, PatientContent, ProfitContent, QuantitiesContent, SelectorWrapper, SubTitle } from "./style";
import FinancialSummary from "./Components/FinancialSummary";
import { RevisionCard } from "./Components/RevisionCard";
import api from "../../service/api";
import { formatDecimalValues } from "../../utils/formatDecimalValues";
import CustomSelector from "./Components/CustomSelector";
import { RetentionProfit } from "./Components/RetentionProfit";
import { NewProfit } from "../../components/Charts/NewProfit";
import { LifeLine } from "react-loading-indicators";
import Logo from "../../assets/bridges.png";

export const Overview = () => {
    const [labels, setLabels] = useState<any[]>([]);
    const [chartData, setChartData] = useState<any[]>([]);

    const [consultationQuantityRealizedLabes, setConsultationQuantityRealizedLabes] = useState<any[]>([]);
    const [consultationQuantityRealizedChartData, setConsultationQuantityRealizedChartData] = useState<any[]>([]);
    const [timeRangeConsultationQuantityRealized, setTimeRangeConsultationQuantityRealized] = useState<string>("weekly");

    const [consultationQuantityCancelledLabes, setConsultationQuantityCancelledLabes] = useState<any[]>([]);
    const [consultationQuantityCancelledChartData, setConsultationQuantityCancelledChartData] = useState<any[]>([]);
    const [timeRangeConsultationQuantityCancelled, setTimeRangeConsultationQuantityCancelled] = useState<string>("weekly");

    const [labelsRetention, setLabelsRetention] = useState<any[]>([]);
    const [chartDataRetention, setChartDataRetention] = useState<any[]>([]);
    const [professionals, setProfessionals] = useState<any[]>([]);
    const [selectedProfessional, setSelectedProfessional] = useState<any>(null);
    const [elapsedTime, setElapsedTime] = useState<string>("0s");
    const [startTime, setStartTime] = useState<number>(Date.now());
    const [timeRange, setTimeRange] = useState<string>("weekly");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const today = new Date();
    const last30Days = new Date();
    last30Days.setDate(today.getDate() - 30);

    const [startDate, setStartDate] = useState<Date | null>(last30Days);
    const [endDate, setEndDate] = useState<Date | null>(today);
    const [consultNumbers, setConsultNumbers] = useState<any>(null);
    const [occupancyRate, setOccupancyRate] = useState<any>(null);
    const [adherenceRate, setAdherenceRate] = useState<any>(null);
    const [overview, setOverview] = useState<any[]>([]);
    const [returnRate, setReturnRate] = useState<any>({});

    const fetchAllData = async () => {
        if (!startDate || !endDate) return;

        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];
        const professionalId = selectedProfessional?.id;

        const endDateForRetention = new Date(formattedEndDate);
        const startDateForRetention = new Date(endDateForRetention);
        startDateForRetention.setMonth(startDateForRetention.getMonth() - 5);
        const formattedStartDateRetention = startDateForRetention.toISOString().split('T')[0];

        try {
            const requests = [
                api.get(`/reports/number-of-queries/${formattedStartDate}/${formattedEndDate}/${professionalId ?? ''}`),
                api.get(`/reports/occupancy-rate/${formattedStartDate}/${formattedEndDate}/${professionalId ?? ''}`),
                api.get(`/reports/adherence-rate/${formattedStartDate}/${formattedEndDate}/${professionalId ?? ''}`),
                api.get(`/reports/${timeRange}-revenue/${formattedStartDate}/${formattedEndDate}/${professionalId ?? ''}`),
                api.get(`/report/${formattedStartDate}/${formattedEndDate}/${professionalId ?? 0}/`),
                api.get(`/reports/patient-retention/${formattedStartDateRetention}/${formattedEndDate}/`),
                api.get(`/reports/return-rate/?start_date=${formattedStartDate}&end_date=${formattedEndDate}&professional_id=${professionalId ?? ''}`),
                api.get(`/appointments-summary/?start_date=${formattedStartDate}&end_date=${formattedEndDate}&group_by=${timeRangeConsultationQuantityRealized}`),
                api.get(`/appointments-summary/?start_date=${formattedStartDate}&end_date=${formattedEndDate}&group_by=${timeRangeConsultationQuantityCancelled}`),
            ];

            const responses = await Promise.allSettled(requests);

            const [
                consultResponse,
                occupancyResponse,
                adherenceResponse,
                revenueResponse,
                overviewResponse,
                retentionResponse,
                returnRateResponse,
                returnQuantityConsultationRealized,
                returnQuantityConsultationCancelled,
            ] = responses.map(response => response.status === 'fulfilled' ? response.value : null);

            setConsultNumbers(consultResponse?.data);
            setOccupancyRate(occupancyResponse?.data);
            setAdherenceRate(adherenceResponse?.data);

            const revenueLabels = revenueResponse?.data.data.map((item: any) => {
                if (timeRange === "monthly") {
                    const [year, month] = item.month.split('-');
                    return `${month}/${year}`;
                } else if (timeRange === "weekly") {
                    const [weekStart, weekEnd] = [item.week_start, item.week_end];
                    const formattedWeekStart = new Date(weekStart).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' });
                    const formattedWeekEnd = new Date(weekEnd).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' });
                    return `${formattedWeekStart} - ${formattedWeekEnd}`;
                } else {
                    const [year, month, day] = item.date.split('-');
                    return `${day}/${month}`;
                }
            });

            setLabels(revenueLabels);

            const revenueData = revenueResponse?.data.data.map((item: any) => item.revenue) || [];
            const trendlineData = revenueResponse?.data.trendline.map((item: any) => item.value) || [];

            if (revenueData.length !== trendlineData.length) {
                console.warn("Mismatch between revenue and trendline data lengths:", revenueData.length, trendlineData.length);
            }

            setChartData([
                { name: "Receita", data: revenueData, type: "bar" },
                { name: "Tendência", data: trendlineData, type: "line" }
            ]);

            setOverview([
                { label: "Receita total", value: formatDecimalValues(overviewResponse?.data.total_paid) },
                { label: "Comissão da clínica", value: formatDecimalValues(overviewResponse?.data.total_comission_real_clinic) },
                { label: "Comissão do profissional", value: formatDecimalValues(overviewResponse?.data.total_comission_real_professional) },
            ]);

            const retentionLabels = retentionResponse?.data.map((item: any) => {
                const [year, month] = item.month.split('-');
                const date = new Date(year, month - 1);
                let monthName = date.toLocaleString('default', { month: 'short' });
                monthName = monthName.replace('.', '');
                monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
                return `${monthName}/${year.slice(-2)}`;
            });

            setLabelsRetention(retentionLabels);
            setChartDataRetention([{ name: "Pacientes Retidos", data: retentionResponse?.data.map((item: any) => item.retained_patients) }]);

            setReturnRate(returnRateResponse?.data);

            const consultationQuantityRealized = returnQuantityConsultationRealized?.data.data.map((item: any) => {
                if (timeRangeConsultationQuantityRealized === "monthly") {
                    const [year, month] = item.date.split('-');
                    return `${month}/${year}`;
                } else if (timeRangeConsultationQuantityRealized === "weekly") {
                    const [weekStart, weekEnd] = [item.week_start, item.week_end];
                    const formattedWeekStart = new Date(weekStart).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' });
                    const formattedWeekEnd = new Date(weekEnd).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' });
                    return `${formattedWeekStart} - ${formattedWeekEnd}`;
                } else {
                    const [year, month, day] = item.date.split('-');
                    return `${day}/${month}`;
                }
            });

            setConsultationQuantityRealizedLabes(consultationQuantityRealized);
            setConsultationQuantityRealizedChartData([{ name: "Consultas Realizadas", data: returnQuantityConsultationRealized?.data.data.map((item: any) => item.realized) }]);

            const consultationQuantityCancelled = returnQuantityConsultationCancelled?.data.data.map((item: any) => {
                if (timeRangeConsultationQuantityCancelled === "monthly") {
                    const [year, month] = item.date.split('-');
                    return `${month}/${year}`;
                } else if (timeRangeConsultationQuantityCancelled === "weekly") {
                    const [weekStart, weekEnd] = [item.week_start, item.week_end];
                    const formattedWeekStart = new Date(weekStart).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' });
                    const formattedWeekEnd = new Date(weekEnd).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' });
                    return `${formattedWeekStart} - ${formattedWeekEnd}`;
                } else {
                    const [year, month, day] = item.date.split('-');
                    return `${day}/${month}`;
                }
            });

            setConsultationQuantityCancelledLabes(consultationQuantityCancelled);
            setConsultationQuantityCancelledChartData([{ name: "Consultas Canceladas", data: returnQuantityConsultationCancelled?.data.data.map((item: any) => item.canceled) }]);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        fetchAllData();
    }, [startDate, endDate, selectedProfessional, timeRange, timeRangeConsultationQuantityRealized, timeRangeConsultationQuantityCancelled]);

    const handleProfessionalSelect = (name: string) => {
        const professional = professionals.find((p: any) => p.name === name);
        setSelectedProfessional(professional);
    };

    useEffect(() => {
        const getProfessionals = async () => {
            try {
                const response = await api.get('/professionals');
                const professionalsData = response.data.map((professional: any) => ({
                    id: professional.id,
                    name: professional.name
                }));
                setProfessionals(professionalsData);
            } catch (error) {
                console.error("Error fetching professionals:", error);
            }
        };

        getProfessionals();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentTime = Date.now();
            const elapsedSeconds = Math.floor((currentTime - startTime) / 1000);
            if (elapsedSeconds < 60) {
                setElapsedTime(`${elapsedSeconds}s`);
            } else if (elapsedSeconds < 3600) {
                setElapsedTime(`${Math.floor(elapsedSeconds / 60)}m`);
            } else {
                setElapsedTime(`${Math.floor(elapsedSeconds / 3600)}h`);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [startTime]);

    useEffect(() => {
        setElapsedTime("0s");
        setStartTime(Date.now());
    }, [startDate, endDate, selectedProfessional]);

    const rangeOptions = [
        { label: 'Diário', value: 'daily' },
        { label: 'Semanal', value: 'weekly' },
        { label: 'Mensal', value: 'monthly' }
    ];

    return (
        <PageStructure
            options={professionals.map((professional: any) => professional.name)}
            onSelectChange={handleProfessionalSelect}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
        >
            <PageContent>
                {isLoading && (
                    <Overlay>
                        <LifeLine color="#418DFD" size="large" text="" textColor="" />
                    </Overlay>
                )}
                <CardContent style={{ opacity: isLoading ? 0.5 : 1 }}>
                    <FeaturedCard
                        title={"Total de consultas"}
                        value={consultNumbers ? consultNumbers.total_current_period : 0}
                        format={"normal"}
                        icon={<TbEmpathize size={20} color="#fff" />}
                        images={[""]}
                        type={consultNumbers ? consultNumbers.percentage_difference > 0 ? "up" : "down" : "up"}
                        percentage={consultNumbers ? `${formatDecimalValues(consultNumbers.percentage_difference)}%` : "0"}
                        updatedAt={elapsedTime}
                    />
                    <FeaturedCard
                        title={"% de ocupação"}
                        value={occupancyRate ? occupancyRate.general_occupancy_rate : 0}
                        format={"percent"}
                        icon={<TbStethoscope size={20} color="#fff" />}
                        images={[""]}
                        type={occupancyRate ? occupancyRate.percentage_difference > 0 ? "up" : "down" : "up"}
                        percentage={occupancyRate ? `${formatDecimalValues(occupancyRate.percentage_difference)}%` : "0"}
                        updatedAt={elapsedTime}
                    />
                    <FeaturedCard
                        title={"Índice de adesão as consultas"}
                        value={adherenceRate ? adherenceRate.adherence_rate : 0}
                        format={"percent"}
                        icon={<TbUserCheck size={20} color="#fff" />}
                        images={[""]}
                        type={adherenceRate ? adherenceRate.percentage_difference > 0 ? "up" : "down" : "up"}
                        percentage={adherenceRate ? `${formatDecimalValues(adherenceRate.percentage_difference)}%` : "0"}
                        updatedAt={elapsedTime}
                    />
                </CardContent>

                <BodyContent style={{ opacity: isLoading ? 0.5 : 1 }}>
                    <ProfitContent>
                        <FinancialSummary items={overview} />

                        <ChartWrapper>
                            <SelectorWrapper>
                                <SubTitle>Receita</SubTitle>
                                <CustomSelector
                                    options={rangeOptions}
                                    selectedOption={timeRange}
                                    setSelectedOption={setTimeRange}
                                />
                            </SelectorWrapper>
                            <NewProfit data={chartData} labelsExtern={labels} />
                        </ChartWrapper>
                    </ProfitContent>

                    <PatientContent>
                        <Label>Visão dos Pacientes</Label>
                        <RevisionCard label="Retorno dos pacientes" value={returnRate.return_rate_percentage ?? 0} />
                        <ChartRetentionWrapper>
                            <SubTitle>Retenção dos Pacientes</SubTitle>
                            <RetentionProfit data={chartDataRetention} labelsExtern={labelsRetention} />
                        </ChartRetentionWrapper>
                    </PatientContent>
                </BodyContent>

                <BodyContent style={{ opacity: isLoading ? 0.5 : 1 }}>
                    <QuantitiesContent>
                        <ChartWrapper>
                            <SelectorWrapper>
                                <SubTitle>Quantidade de consultas realizadas</SubTitle>
                                <CustomSelector
                                    options={rangeOptions}
                                    selectedOption={timeRangeConsultationQuantityRealized}
                                    setSelectedOption={setTimeRangeConsultationQuantityRealized}
                                />
                            </SelectorWrapper>
                            {consultationQuantityRealizedChartData[0]?.data?.length ? (
                                <NewProfit
                                    data={consultationQuantityRealizedChartData}
                                    labelsExtern={consultationQuantityRealizedLabes}
                                    noCurrencyFormat
                                />
                            ) : (
                                <ListEmptyWrapper>
                                    <LogoWrapper src={Logo} alt="Logo" />
                                    <ListEmptyLabel>Sem dados no período</ListEmptyLabel>
                                </ListEmptyWrapper>
                            )}
                        </ChartWrapper>
                    </QuantitiesContent>

                    <QuantitiesContent>
                        <ChartWrapper>
                            <SelectorWrapper>
                                <SubTitle>Quantidade de consultas desmarcadas</SubTitle>
                                <CustomSelector
                                    options={rangeOptions}
                                    selectedOption={timeRangeConsultationQuantityCancelled}
                                    setSelectedOption={setTimeRangeConsultationQuantityCancelled}
                                />
                            </SelectorWrapper>
                            {consultationQuantityCancelledChartData[0]?.data?.length ? (
                                <NewProfit
                                    data={consultationQuantityCancelledChartData}
                                    labelsExtern={consultationQuantityCancelledLabes}
                                    noCurrencyFormat
                                />
                            ) : (
                                <ListEmptyWrapper>
                                    <LogoWrapper src={Logo} alt="Logo" />
                                    <ListEmptyLabel>Sem dados no período</ListEmptyLabel>
                                </ListEmptyWrapper>
                            )}
                        </ChartWrapper>
                    </QuantitiesContent>
                </BodyContent>
            </PageContent>
        </PageStructure>
    );
};