import React from 'react';
import Chart from 'react-apexcharts';
import styled from 'styled-components';

const ChartContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

interface AgeRangeChartProps {
  data: { range: string; count: number }[];
}

const AgeRangeChart: React.FC<AgeRangeChartProps> = ({ data }) => {
  const options = {
    chart: {
      type: 'bar' as const,
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '50%', // Ajuste para melhor visualização
        distributed: true,
      },
    },
    colors: ['#1270FC'],
    xaxis: {
      categories: data.map(item => item.range),
      title: {
        text: 'Número de Pacientes',
      },
      labels: {
        style: {
          fontSize: '12px', // Ajuste para legibilidade
        },
      },
    },
    yaxis: {
      title: {
        text: 'Faixa Etária',
      },
      labels: {
        style: {
          fontSize: '12px', // Ajuste para legibilidade
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val: number) => (val > 0 ? val.toString() : ''), // Evita exibir 0
      style: {
        colors: ['#000'],
        fontSize: '12px',
      },
    },
    tooltip: {
      y: {
        formatter: (val: number) => `${val} pacientes`,
      },
    },
  };

  const series = [
    {
      name: 'Pacientes',
      data: data.map(item => item.count),
    },
  ];

  return (
    <ChartContainer>
      {data.length > 0 ? (
        <Chart options={options} series={series} type="bar" height={350} />
      ) : (
        <div style={{ textAlign: 'center', color: '#B2B2B2' }}>Nenhum dado disponível.</div>
      )}
    </ChartContainer>
  );
};

export default AgeRangeChart;