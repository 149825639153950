import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface RevisionCardProps {
  value: number;
  label: string;
}

export const RevisionCard: React.FC<RevisionCardProps> = ({ value, label }) => {
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setProgress(value);
    
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Card>
      {isLoading ? (
        <LoadingIndicator>Carregando...</LoadingIndicator>
      ) : (
        <>
          <CardContent>
            <div>
              <Value>{value}</Value><Percent>%</Percent>
            </div>
            <ProgressBar progress={progress}>
              <div className="filled"></div>
              <div className="empty"></div>
            </ProgressBar>
          </CardContent>
          <Label>{label}</Label>
        </>
      )}
    </Card>
  );
};

const Card = styled.div`
  background-color: white;
  max-height: 130px;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  border: 1px solid #D9D9D9;
`;

const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Value = styled.span`
  font-size: 60px;
  font-weight: 700;
  color: #444444;
`;

const Percent = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: #444444;
`;

const ProgressBar = styled.div<{ progress: number }>`
  position: relative;
  width: 80px; /* Increased width */
  height: 80px;
  border-radius: 50%;
  background: conic-gradient(
    #007bff ${({ progress }) => progress}%, 
    #e0e0e0 ${({ progress }) => progress}% 100%
  );
  transition: background 1s ease-in-out;

  .filled {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    z-index: 2;
  }

  .filled:hover {
    border-radius: 50%;
    background: conic-gradient(
      #0056b3 ${({ progress }) => progress}%, 
      #e0e0e0 ${({ progress }) => progress}% 100%
    );
  }

  .empty {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: transparent;
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    width: 80%;
    height: 80%;
    background: white;
    border-radius: 50%;
    top: 10%;
    left: 10%;
    z-index: 3;
  }

  .filled:hover::after {
    content: '${({ progress }) => progress}%';
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background: #0056b3;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 4;
  }
`;

const Label = styled.div`
  color: #444444;
  font-weight: 700;
`;

const LoadingIndicator = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #b2b2b2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default RevisionCard;