import { IoRestaurant } from "react-icons/io5";
import { GiRunningShoe } from "react-icons/gi";
import { GiStrongMan } from "react-icons/gi";

export const SegmentList = [
  {
    title: "Dieta clínica",
    value: "dietaclinica",
    icons: <IoRestaurant size={22}/>
  },
  {
    title: "Emagrecimento",
    value: "emagrecimento",
    icons: <GiRunningShoe size={22}/>
  },
  {
    title: "Hipertrofia",
    value: "hipertrofia",
    icons: <GiStrongMan size={22}/>
  },
];
