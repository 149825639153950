export const PatologyList = [
  {
    title: "Doenças Neurológicas",
    value: "doencas_neurologicas",
    subItems: [
      { title: "Ansiedade", value: "ansiedade" },
      { title: "Depressão", value: "depressao" },
      { title: "Irritabilidade", value: "irritabilidade" },
      { title: "Dor de cabeça", value: "dor_de_cabeca" },
    ],
  },
  {
    title: "Doenças Endócrinas",
    value: "doencas_endocrinas",
    subItems: [
      { title: "Diabetes", value: "diabetes" },
      { title: "Hipertireoidismo", value: "hipertireoidismo" },
      { title: "Hipotireoidismo", value: "hipotireoidismo" },
      { title: "Hipoglicemia", value: "hipoglicemia" },
    ],
  },
  {
    title: "Doenças Circulatórias",
    value: "doencas_circulatorias",
    subItems: [
      { title: "Circulatório", value: "circulatorio" },
      { title: "Hipertensão", value: "hipertensao" },
      { title: "Dislipidemia", value: "dislipidemia" },
    ],
  },
  {
    title: "Doenças Gastrointestinais",
    value: "doencas_gastrointestinais",
    subItems: [
      { title: "Colite", value: "colite" },
      { title: "Gastrite", value: "gastrite" },
      { title: "RGE", value: "rge" },
    ],
  },
  {
    title: "Doenças Hepáticas",
    value: "doencas_hepaticas",
    subItems: [
      { title: "Hepatite", value: "hepatite" },
    ],
  },
  {
    title: "Doenças Renais",
    value: "doencas_renais",
    subItems: [
      { title: "Renal", value: "renal" },
    ],
  },
  {
    title: "Doenças Respiratórias",
    value: "doencas_respiratorias",
    subItems: [
      { title: "Rinite/Sinute", value: "rinitesinusite" },
    ],
  },
  {
    title: "Doenças Ósseas",
    value: "doencas_osseas",
    subItems: [
      { title: "Osteoporose", value: "osteoporose" },
    ],
  },
  {
    title: "Outras Doenças",
    value: "outras_doencas",
    subItems: [
      { title: "Câncer", value: "cancer" },
      { title: "Endócrino", value: "endocrino" },
      { title: "Herpes", value: "herpes" },
    ],
  },
];
