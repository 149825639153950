import { useEffect, useState } from "react";
import { customStyles } from "../../../components/Shared";
import { Tag } from "../../../components/Tags";
import formatCurrency from "../../../utils/formatCurrencyBr";
import {
  AverageInfoWrapper,
  DisplayBlock,
  EndWrapper,
  ExamsHeader,
  ExamsListItem,
  ExamsListWrapper,
  IconContainer,
  InfoWrapper,
  InitialAndEndTimeWrapper,
  InitialWrapper,
  ExamIcon,
  DisplayBlockTimeBox,
} from "./style";
import Modal from "react-modal";
import { animated, useSpring } from "react-spring";
import { HeaderTitle, HeaderWrapper } from "../../Patients/Profile/style";
import { IoCloseOutline } from "react-icons/io5";
import { InfoPaciente } from "./InfoPaciente";
import { InfoProfissional } from "./InfoProfissional";
import { PlayStopIcon } from "./PlayStopIcon";
import { TimeBox } from "./TimeBox";
import { authenticateProfessionalImageUrl } from "../../../utils/authenticateImageUrl";
import api from "../../../service/api";
import { toast } from "react-toastify";
import { getLocalISOString } from "../../../utils/getLocalIsoString";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

interface ProfessionalInfos {
  ctime: Date;
  appointment?: any;
  paymentStatusClicked?: () => void;
  clientClicked?: () => void;
  appointmentClicked?: () => void;
  prontoClicked?: () => void;
  prontoLoading?: boolean;
}

export const ProfessionalInfos = ({
  ctime,
  appointment,
  paymentStatusClicked,
  clientClicked,
  appointmentClicked,
  prontoClicked,
  prontoLoading,
}: ProfessionalInfos) => {
  const navigate = useNavigate();

  const [modaDetaillIsOpen, setModaDetaillIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [appointmentClickedState, setAppointmentClickedState] = useState(false);
  const [examsClickedState, setExamsClickedState] = useState(false);
  const [authenticatedImageUrl, setAuthenticatedImageUrl] = useState<string>("");
  const [userRole, setUserRole] = useState("");

  const [playingState, setPlayingState] = useState(false);
  const [startTimer, setStartTimer] = useState('');

  useEffect(() => {
    if (examsClickedState && appointmentClickedState) openExamsList();
    else if (appointmentClickedState) appointmentClicked?.();

    setAppointmentClickedState(false);
    setExamsClickedState(false);
  }, [appointmentClickedState, examsClickedState]);

  useEffect(() => {
    const storageKey = `playing-${appointment.id.toString()}`;
    const storedValue = localStorage.getItem(storageKey);

    if (storedValue) {
      setPlayingState(true);
      setStartTimer(JSON.parse(storedValue).dateStart);
    }
  }, [])

  useEffect(() => {
    const token = localStorage.getItem("bridges.token");
    
    if (token) {
      const tokenData = jwtDecode(token) as any;
      setUserRole(tokenData.groups[0]);
    }

    const fetchAuthenticatedImageUrl = async () => {
      try {
        const token = localStorage.getItem("bridges.token");
        if (token) {
          const url = await authenticateProfessionalImageUrl(appointment.professional.id, token);
          setAuthenticatedImageUrl(url);
        }
      } catch (error) {
        setAuthenticatedImageUrl('');
      }
    };

    if (appointment?.professional?.photo) {
      fetchAuthenticatedImageUrl();
    }
  }, [appointment]);

  const fadeDetail = useSpring({
    from: { opacity: 0 },
    to: { opacity: modaDetaillIsOpen ? 1 : 0 },
  });

  const openExamsList = () => {
    setModaDetaillIsOpen(true);
  };

  const handleIconClick = () => {
    setLoading(true);

    const storageKey = `playing-${appointment.id.toString()}`;
    const storedValue = localStorage.getItem(storageKey);

    const instant = getLocalISOString(new Date())

    setStartTimer(instant);

    if (storedValue) {
      api.patch(`/appointments/${appointment.id}/end/`, {
        end_time: instant
      }).then(() => {
        setPlayingState(false);
        localStorage.removeItem(storageKey);
        toast.success("Atendimento finalizado com sucesso!");
      }).catch(() => {
        toast.error("Erro ao pausar o atendimento!");
      }).finally(() => {
        setLoading(false)
      });
    } else {
      const storageValue = { appointmentId: appointment.id, dateStart: instant };
      localStorage.setItem(storageKey, JSON.stringify(storageValue));

      api.patch(`/appointments/${appointment.id}/start/`, {
        start_time: instant
      }).then(() => {
        setPlayingState(true);
        toast.success("Atendimento iniciado com sucesso!");

      }).catch(() => {
        localStorage.removeItem(storageKey);
        toast.error("Erro ao iniciar o atendimento!");
      }).finally(() => {
        setLoading(false)

        navigate("/medicalRecordHistory", {
          state: {
            patient: appointment.patient,
          },
        });
      });
    }

    prontoClicked?.();
  }

  return (
    <>
      <Modal
        isOpen={modaDetaillIsOpen}
        onRequestClose={() => setModaDetaillIsOpen(false)}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
      >
        <animated.div style={fadeDetail}>
          <ExamsHeader>
            <HeaderWrapper>
              <HeaderTitle>Exames</HeaderTitle>
              <IoCloseOutline
                size={22}
                onClick={() => setModaDetaillIsOpen(false)}
                color="#919EAB"
                cursor={"pointer"}
              />
            </HeaderWrapper>

            <ExamsListWrapper>
              {appointment?.exams.map((exam: any, index: number) => {
                return (
                  <ExamsListItem key={index} isEven={index % 2 == 0}>
                    {exam.name}
                  </ExamsListItem>
                );
              })}
            </ExamsListWrapper>
          </ExamsHeader>
        </animated.div>
      </Modal>

      <InfoWrapper>
        <AverageInfoWrapper
          hasExams={appointment?.exams.length > 0}
          style={{ paddingBottom: "4px" }}
        >
          <DisplayBlock>
            <InfoPaciente
              onClick={() => clientClicked?.()}
              patient={appointment.patient}
              label="Paciente"
              value={appointment?.patient?.alias || appointment?.paciente}
              alert={appointment?.patient?.has_pendency}
              tagName={appointment?.patient.tag?.name || "Normal"}
              tagColor={appointment?.patient.tag?.color}
            />
          </DisplayBlock>
          <DisplayBlockTimeBox onClick={() => clientClicked?.()}>
            <TimeBox
              color={
                appointment?.status.name === "Pago"
                  ? "green"
                  : appointment?.status.name === "Pagamento Parcial"
                  ? "blue"
                  : "yellow"
              }
              time={
                appointment?.start.split(/[-T:]/)[3] +
                ":" +
                appointment?.start.split(/[-T:]/)[4]
              }
              showIcon={appointment?.patient?.has_pendency}
            />
          </DisplayBlockTimeBox>
        </AverageInfoWrapper>
        {appointment?.exams.length > 0 && (
          <DisplayBlock>
            <InfoPaciente label="Exames" value="" />
            {appointment.exams.map((exam: any) => (
              <ExamIcon data-name={exam.name}>
                {exam.name[0].toUpperCase()}
              </ExamIcon>
            ))}
          </DisplayBlock>
        )}

        <InitialAndEndTimeWrapper>
          <EndWrapper hasExams={appointment?.exams.length > 0}></EndWrapper>
            <AverageInfoWrapper
            hasExams={appointment?.exams.length > 0}
            onClick={() => appointmentClicked?.()}
            >
            <InfoProfissional
              professionalAlias={appointment.professional.alias}
              professionalName={appointment.professional.name}
              profileImage={authenticatedImageUrl}
            />

          { 
            userRole == "profissional" &&
              <InitialWrapper hasExams={true} style={{ position: "relative" }}>
                <IconContainer
                  hasExams={appointment?.exams.length > 0}
                  onClick={() => {
                    if(!loading)
                      handleIconClick()
                  }}
                >
                  <PlayStopIcon
                    playing={playingState}
                    color={
                      appointment?.status.name === "Pago"
                        ? "green"
                        : appointment?.status.name === "Pagamento Parcial"
                        ? "blue"
                        : "yellow"
                    }
                    startDate={startTimer}
                  />
                </IconContainer>
              </InitialWrapper>
          }
            </AverageInfoWrapper>
        </InitialAndEndTimeWrapper>

        <AverageInfoWrapper
          hasExams={appointment?.exams.length > 0}
          style={{ alignItems: "center" }}
          onClick={() => paymentStatusClicked?.()}
        >
          <DisplayBlock>
            <InfoPaciente
              label="Valor"
              value={formatCurrency(
                (appointment.service.price * 100 || "000").toString()
              )}
            />
          </DisplayBlock>
          <DisplayBlock>
            <InfoPaciente
              label="Consulta"
              value={
                appointment?.service.name.includes("Consulta")
                  ? appointment?.service.name.replace("Consulta", "").trim()
                  : appointment?.service.name
              }
            />
          </DisplayBlock>
          <DisplayBlock>
            <Tag
              type={appointment?.status.name == "Pago" ? "paid" : "pending"}
              text={
                appointment?.status.name == "Pago"
                  ? "Pago"
                  : appointment?.status.name == "Pagamento Parcial"
                  ? "Parcial"
                  : "Em aberto"
              }
            />
          </DisplayBlock>
        </AverageInfoWrapper>
      </InfoWrapper>
    </>
  );
};
