import React from "react";
import { StyledButton } from "./styles";
import { FaMoneyBill } from "react-icons/fa6";

interface HeaderButtonProps {
  buttonText: string;
  onClick: () => void;
}

const HeaderButton: React.FC<HeaderButtonProps> = ({ buttonText, onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      <FaMoneyBill style={{ marginRight: "8px" }} />
      {buttonText}
    </StyledButton>
  );
};

export default HeaderButton;
