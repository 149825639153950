import styled from "styled-components";

export const TableContainer = styled.div<{ maxHeight?: string }>`
  max-height: ${(props) => props.maxHeight || "auto"};
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 5px;
`;

export const Table = styled.table<{ minWidth?: string }>`
  width: 100%;
  min-width: ${(props) => props.minWidth || "auto"};
  border-collapse: collapse;
`;

export const StickyHeader = styled.thead`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #1270fc;
  color: #ffffff;
  th {
    padding: 10px;
    text-align: left;
    font-weight: bold;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    &:last-child {
      border-right: none;
    }
  }
`;

export const TableBody = styled.tbody`
  tr {
    background-color: #ffffff;
    &:nth-child(even) {
      background-color: #e8ecef;
    }
    &:hover {
      background-color: #b3e5fc;
      cursor: pointer;
    }
  }
  td {
    padding: 10px;
    border-right: 1px solid #e0e0e0; /* Borda vertical entre colunas */
    &:last-child {
      border-right: none; /* Remove a borda da última coluna */
    }
  }
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const EmptyField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: #919eab;
`;