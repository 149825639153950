import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
  width: 100%;
  position: relative;

  span {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    color: #637381;
  }
`;

export const IconWrapper = styled.div`
  cursor: pointer;
`;

export const customStyles = {
  overlay: {
    zIndex: 9000,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 16,
    transition: "all 0.3s ease",
    overflow: "visible",
  },
};



export const customStylesPaciente = {
  overlay: {
    zIndex: 9000,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 16,
    transition: "all 0.3s ease",
    // overflow: "auto",
    width: "90%",
    height: "70%",
  },
};
