import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Appointments } from "../../components/Appointments";
import CustomCalendar from "../../components/CustomCalendar";
import PageStructure from "../../components/PageStructure";
import api from "../../service/api";
import { getProfessionalId } from "../../service/token.service";
import {
  formatIsoToDate,
  getTimeFromIsoDate,
} from "../../service/utils.service";
import {
  AppointmentsWrapper,
  CenterContentWrapper,
  HomeContent,
  ProfessionalTagsWrapper,
  RightContentWrapper,
  TitleRightSection,
  ToggleButton,
} from "./style";
import CustomDropdown from "../../components/CustomDropDown";
import { MdOutlineFilterList } from "react-icons/md";

export const Home = () => {
  const [professionals, setProfessionals] = useState([] as any[]);
  const [clickedProfessionalsTags, setClickedProfessionalsTags] = useState(
    [] as any[]
  );
  const [appointments, setAppointments] = useState([] as any[]);
  const clickedProfessionalsTagsRef = useRef<Array<number>>([]);

  const [selectedProfessional, setSelectedProfessional] = useState<any>("");
  const [filteredAppointments, setFilteredAppointments] = useState<any>([]);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [intervalDate, setIntervalDate] = useState<any>({
    start_date: "",
    end_date: "",
  });

  const [searchTerm, setSearchTerm] = useState("");

  const refreshEvents = async () => {
    setAppointments([]);
    setAppointments(
      await generateEvents().catch(() =>
        toast.error("Erro ao carregar os dados dos profissionais !")
      )
    );
  };

  useEffect(() => {
    setEventsEffect();

    const interval = setInterval(async () => {
      const events = await generateEvents().catch(() =>
        toast.error("Erro ao carregar os dados dos profissionais !")
      );

      const professionalId = getProfessionalId();

      if (professionalId) {
        const agendaResponse = await api.get(`/agenda/?id=${professionalId}`);

        agendaResponse.data.forEach((item) => {
          const weekDaysMap = {
            "Domingo": 0,
            "Segunda": 1,
            "Terça": 2,
            "Quarta": 3,
            "Quinta": 4,
            "Sexta": 5,
            "Sábado": 6,
          };

          const startDate = new Date(`${intervalDate.start_date}T00:00:00`);
          const endDate = new Date(`${intervalDate.end_date}T23:59:59`);

          for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
            if (d.getDay() === weekDaysMap[item.week_day]) {
              const lunchStart = new Date(d);
              const lunchEnd = new Date(d);

              const [hours, minutes] = item.lunch_start_time.split(":").map(Number);
              lunchStart.setHours(hours-3, minutes);
              const [hoursEnd, minutesEnd] = item.lunch_end_time.split(":").map(Number);
              lunchEnd.setHours(hoursEnd-3, minutesEnd);

              const lunchStartFormatted = lunchStart.toISOString().split(".")[0] + "-03:00";
              const lunchEndFormatted = lunchEnd.toISOString().split(".")[0] + "-03:00";

              events?.push({
                id: `horario-${lunchStartFormatted}`,
                start: lunchStartFormatted,
                end: lunchEndFormatted,
                allDay: false,
                justification: 'Horário de almoço',
                patient: {
                  name: "Almoço",
                },
                isOccurence: true,
                status: {
                  name: "Almoço",
                },
              });
            }
          }
        });
      }

      const response = await api.get(
        `/absence/?start_date=${intervalDate.start_date}&end_date=${intervalDate.end_date}`
      );

      response.data.forEach((item) => {
        if((selectedProfessional?.id && selectedProfessional?.id == item.professional) || !(selectedProfessional?.id)) 
          events?.push({
            id: item.id,
            start: item.start_date,
            end: item.end_date,
            allDay: false,
            justification: item?.justification,
            patient: {
              name: "Ocorrência",
            },
            professional: item.professional,
            isOccurence: true,
            status: {
              name: "Ocorrência",
            },
          });
      });

      setAppointments(events);
    }, 5000);
    return () => clearInterval(interval);
  }, [intervalDate, selectedProfessional]);

  const getProfessionals = async () =>
    await api
      .get("/professionals/")
      .then((res) => setProfessionals(res.data))
      .catch(() =>
        toast.error("Erro ao carregar os dados dos profissionais !")
      );

  const setEventsEffect = async () => {
    const events = await generateEvents() as any[]
    
    const response = await api.get(`/absence/?start_date=${intervalDate.start_date}&end_date=${intervalDate.end_date}`);

    const professionalId = getProfessionalId();

    if (professionalId) {
      const agendaResponse = await api.get(`/agenda/?id=${professionalId}`);

      agendaResponse.data.forEach((item) => {
        const weekDaysMap = {
          "Domingo": 0,
          "Segunda": 1,
          "Terça": 2,
          "Quarta": 3,
          "Quinta": 4,
          "Sexta": 5,
          "Sábado": 6,
        };

        const startDate = new Date(`${intervalDate.start_date}T00:00:00`);
        const endDate = new Date(`${intervalDate.end_date}T23:59:59`);

        for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
          if (d.getDay() === weekDaysMap[item.week_day]) {
            const lunchStart = new Date(d);
            const lunchEnd = new Date(d);

            const [hours, minutes] = item.lunch_start_time.split(":").map(Number);
            lunchStart.setHours(hours-3, minutes);
            const [hoursEnd, minutesEnd] = item.lunch_end_time.split(":").map(Number);
            lunchEnd.setHours(hoursEnd-3, minutesEnd);

            const lunchStartFormatted = lunchStart.toISOString().split(".")[0] + "-03:00";
            const lunchEndFormatted = lunchEnd.toISOString().split(".")[0] + "-03:00";
            
            events?.push({
              id: `horario-${lunchStartFormatted}`,
              start: lunchStartFormatted,
              end: lunchEndFormatted,
              allDay: false,
              justification: 'Horário de almoço',
              patient: {
                name: "Almoço",
              },
              isOccurence: true,
              status: {
                name: "Almoço",
              },
            });
          }
        }
      });
    }

    response.data.map(item => {
      if((selectedProfessional?.id && selectedProfessional?.id == item.professional) || !(selectedProfessional?.id)) 
        events?.push({
          id: item.id,
          start: item.start_date,
          end: item.end_date,
          allDay: false,
          justification: item?.justification,
          patient: {
            name: 'Ocorrência'
          },
          professional: item.professional,
          isOccurence: true,
          status: {
            name: 'Ocorrência'
          }
        })
    })

    setAppointments(events)
  };

  const getLastSunday = (date: any) => {
    const dayOfWeek = date.getDay();
    const diff = dayOfWeek;
    const lastSunday = new Date(date);
    lastSunday.setDate(lastSunday.getDate() - diff);
    return lastSunday;
  };

  const getNextSaturday = (date: any) => {
    const dayOfWeek = date.getDay();
    const diff = (6 - dayOfWeek + 7) % 7;
    const nextSaturday = new Date(date);
    nextSaturday.setDate(nextSaturday.getDate() + diff);
    return nextSaturday;
  };

  useEffect(() => {
    const now = new Date();
    const lastSunday = getLastSunday(now);
    const nextSaturday = getNextSaturday(now);

    setIntervalDate({
      start_date: lastSunday.toISOString().split("T")[0],
      end_date: nextSaturday.toISOString().split("T")[0],
    });

    getProfessionals();
  }, []);

  const handleProfessionalTagClick = (id: number) => {
    if (clickedProfessionalsTags.includes(id)) {
      const indexToRemove = clickedProfessionalsTags.findIndex(
        (professionalId) => professionalId == id
      );
      clickedProfessionalsTags.splice(indexToRemove, 1);
      setClickedProfessionalsTags([...clickedProfessionalsTags]);
      clickedProfessionalsTagsRef.current = [...clickedProfessionalsTags];
      generateEvents();
      return;
    }

    setClickedProfessionalsTags([...clickedProfessionalsTags, id]);
    clickedProfessionalsTagsRef.current = [...clickedProfessionalsTags, id];
    generateEvents();
  };

  useEffect(() => {
    if (selectedProfessional && selectedProfessional.id) {
      generateEvents();
    }
  }, [selectedProfessional]);

  const generateEvents = async () => {
    if (!intervalDate.start_date || !intervalDate.end_date) return;
    if (selectedProfessional?.id) {
      const professionalId = selectedProfessional?.id;
      let link = `/appointments/?professional_id=${professionalId}`;

      const appointments = await api
        .get(
          `${link}&start_date=${intervalDate.start_date}&end_date=${intervalDate.end_date}`
        )
        .then((response) => {
          const appointments = response.data;
          const events = appointments?.map((appointment: any) => {
            const title = appointment.service;
            return {
              id: appointment.id,
              title,
              start: appointment.start_time,
              end: appointment.end || appointment.end_time,
              end_time: appointment.end_time || appointment.end,
              allDay: false,
              paciente: appointment.patient.name,
              professional: appointment.professional,
              service: appointment.service,
              establishment: appointment.establishment,
              observation: appointment.observation,
              patient: appointment.patient,
              preform: appointment.preform,
              status: appointment.status,
              exams: appointment.exams
            };
          });

          return events;
        })
        .catch(() => {
          toast.error("Erro ao carregar os dados dos agendamentos!");
        });

      return appointments;
    } else {
      console.log(intervalDate)

      let link = `/appointments/?start_date=${intervalDate.start_date}&end_date=${intervalDate.end_date}`;
      if (getProfessionalId()) {
        link = `/appointments/?professional_id=${getProfessionalId()}&start_date=${
          intervalDate.start_date
        }&end_date=${intervalDate.end_date}`;
      }
      const appointments = await api
        .get(`${link}`)
        .then((response) => {
          const appointments = response.data;
          const events = appointments?.map((appointment: any) => {
            const title = appointment.service;
            return {
              id: appointment.id,
              title,
              start: appointment.start_time,
              end: appointment.end_time || appointment.end,
              end_time: appointment.end_time || appointment.end,
              allDay: false,
              paciente: appointment.patient.name,
              professional: appointment.professional,
              service: appointment.service,
              establishment: appointment.establishment,
              observation: appointment.observation,
              patient: appointment.patient,
              preform: appointment.preform,
              status: appointment.status,
              appointment_status: appointment.appointment_status,
              exams: appointment.exams
            };
          });

          return events;
        })
        .catch(() => {
          toast.error("Erro ao carregar os dados dos agendamentos!");
        });

      return appointments;
    }
  };

  const sortAppointments = (appointments: any) => {
    const dataAtual = new Date();

    const appointmentsFiltered = appointments
      ?.map((appointment: any) => {
        return { ...appointment, start: new Date(appointment.start) };
      })
      .filter((appointment: any) => {
        if (clickedProfessionalsTagsRef?.current?.length > 0)
          return (
            appointment.start >= dataAtual &&
            appointment.status.name != "Cancelado" &&
            clickedProfessionalsTagsRef.current.includes(
              appointment?.professional?.id
            )
          );
        else
          return (
            appointment.start >= dataAtual &&
            appointment.status.name != "Cancelado"
          );
      });

    const appointmentsSorted = appointmentsFiltered?.sort(
      (a: any, b: any) => a.start - b.start
    );

    return appointmentsSorted;
  };
  const togglePanel = () => setIsCollapsed((prev) => !prev);

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  useEffect(() => {
    if (!appointments || appointments.length === 0) {
      setFilteredAppointments([]);
      return;
    }
  
    const filtered = appointments.filter((appointment) => {
      return (
        (       
          appointment.paciente &&
          appointment.paciente.toLowerCase().includes(searchTerm.toLowerCase())
        ) || appointment?.isOccurence
      );
    });
    setFilteredAppointments(filtered);
  }, [searchTerm, appointments]);
  

  return (
    <PageStructure onSearchChange={handleSearchChange}>
      <HomeContent>
        <CenterContentWrapper>
          <CustomCalendar
            appointments={filteredAppointments}
            setAppointments={setFilteredAppointments}
            setIntervalDate={setIntervalDate}
            refreshFunction={refreshEvents}
          />
        </CenterContentWrapper>
        <ToggleButton onClick={togglePanel}>
          {isCollapsed ? "+" : "-"}
        </ToggleButton>
        <RightContentWrapper isCollapsed={isCollapsed}>
          {!getProfessionalId() && (
            <>
              <TitleRightSection>Profissionais</TitleRightSection>
              <ProfessionalTagsWrapper>
                <CustomDropdown
                  options={professionals}
                  showImage
                  selectedOption={selectedProfessional?.name}
                  placeHolder="Selecione o Profissional"
                  setSelectedOption={(value) => {
                    setSelectedProfessional(value);
                  }}
                  disableBorder
                  leftIcon={
                    <MdOutlineFilterList
                      size={16}
                      color="var(--primary-icon-color)"
                    />
                  }
                />
                {/* {professionals?.map((professional, index) => {
                return (
                  <CustomTag
                    key={index}
                    clicked={clickedProfessionalsTags.includes(professional.id)}
                    onClick={() => {
                      handleProfessionalTagClick(professional.id);
                    }}
                    name={professional.name}
                    color={professional.color}
                  />
                );
              })} */}
              </ProfessionalTagsWrapper>
            </>
          )}
          <TitleRightSection>Próximos compromissos</TitleRightSection>
          <AppointmentsWrapper>
            {sortAppointments(filteredAppointments)?.map((appointment: any) => {
              if(appointment?.isOccurence)
                return

              return (
                <Appointments
                  title={appointment?.service?.name}
                  description={appointment?.observation || ""}
                  patients={appointment?.patient?.name}
                  dateString={formatIsoToDate(appointment?.start)}
                  hourString={getTimeFromIsoDate(appointment?.start)}
                  professionalColor={appointment?.professional?.color}
                  professionalName={appointment?.professional?.name}
                  appointment={{
                    allDay: appointment?.allDay,
                    title: appointment?.title,
                    start: appointment?.start,
                    end: appointment?.end,
                    id: appointment?.id,
                    extendedProps: {
                      establishment: appointment?.establishment,
                      observation: appointment?.observation,
                      paciente: appointment?.paciente,
                      patient: appointment?.patient,
                      professional: appointment?.professional,
                      preform: appointment?.preform,
                      service: appointment?.service,
                      status: appointment?.status,
                    },
                  }}
                />
              );
            })}
          </AppointmentsWrapper>
        </RightContentWrapper>
      </HomeContent>
    </PageStructure>
  );
};

export default Home;
