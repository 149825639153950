import { useEffect, useState } from "react";
import { CiMail } from "react-icons/ci";
import { GoLocation } from "react-icons/go";
import { IoMdTime } from "react-icons/io";
import { MdOutlineBadge } from "react-icons/md";
import { PiIdentificationCardLight, PiPhone, PiUserCircleLight } from "react-icons/pi";
import { RiHotelLine } from "react-icons/ri";
import { toast } from "react-toastify";
import AnonymousProfilePhoto from "../../assets/userAnonymous.jpeg";
import CustomDropdown from "../../components/CustomDropDown";
import CustomInput from "../../components/CustomInput";
import ModalStepByStep from "../../components/ModalStepByStep";
import { ProfileImageWrapper } from "../../pages/Patients/Profile/style";
import api from "../../service/api";
import { formatDocument } from "../../utils/formatDocument";
import { formatPhone } from "../../utils/formatPhone";
import { ContentWrapper, InfoWrapper } from "../Event/Create/style";
import {
  ConfigHoursContainer,
  ConfigHoursWrapper,
  CustomInputWrapper,
  FlexContainer,
  HeaderTitle,
  IconContainer,
  LockIcon,
  LockOutlineIcon,
  WeekContainer,
  WeekendContainer,
} from "./style";
import { validateCPF } from "../../utils/validateCPF";
import { useNavigate } from "react-router-dom";
import { authenticateProfessionalImageUrl } from "../../utils/authenticateImageUrl";

interface CreateProfessionalProps {
  closeModal: () => void;
  updateClients?: () => void;
  professional?: any;
}

export const CreateProfessional = ({
  closeModal,
  updateClients,
  professional,
}: CreateProfessionalProps) => {
  const [form, setForm] = useState<any>({
    name: "",
    document: "",
    email: "",
    phone: "",
    address: "",
    n_conselho: "",
    alias: "",
  });

  const [formWeek, setFormWeek] = useState<any>({
    Domingo: {},
    Sábado: {},
    Segunda: {},
    Terça: {},
    Quarta: {},
    Quinta: {},
    Sexta: {},
  });

  const steps = [
    {
      stepTitle: "Informações Básicas",
    },
    {
      stepTitle: "Configuração de horário",
    },
    {
      stepTitle: "Foto de Perfil",
    },
  ];
  const weekDay = ["Segunda", "Terça", "Quarta", "Quinta", "Sexta"];
  const weekendDay = ["Domingo", "Sábado"];

  const [selectedEstablishment, setSelectedEstablisiment] = useState<any>(null);
  const [establishments, setEstablishments] = useState<any>([]);
  const [stepActive, setStepActive] = useState(steps[0]);
  const [loading, setLoading] = useState(false);
  const [imagem, setImagem] = useState<any>(null);
  const [selectedImage, setSelectedImage] = useState<any>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (professional) {
      setForm({
        name: professional.name || "",
        document: professional.document || "",
        email: professional.email || "",
        phone: professional.phone || "",
        address: professional.address || "",
        n_conselho: professional.n_conselho || "",
        alias: professional.alias || "",
      });

      setImagem(professional.photo || null);
      setSelectedEstablisiment(professional.establishment || null);

      api
        .get(`/agenda/?id=${professional.id}`)
        .then((response) => {
          const schedule = response.data.reduce((acc: any, item: any) => {
            const {
              id,
              week_day,
              start_time,
              end_time,
              lunch_start_time,
              lunch_end_time,
              is_available
            } = item;

            if (week_day && start_time && end_time && lunch_start_time && lunch_end_time) {
              acc[week_day] = {
                id: id,
                week_day: week_day,
                start_time: start_time.slice(0, 5),
                end_time: end_time.slice(0, 5),
                lunch_start_time: lunch_start_time.slice(0, 5),
                lunch_end_time: lunch_end_time.slice(0, 5),
                is_available: is_available,
              };
            } else {
              console.warn(`Dados incompletos na agenda para o dia ${week_day || "desconhecido"}`);
            }

            return acc;
          }, {});

          setFormWeek(schedule);
        })
        .catch((error) => {
          console.error("Erro ao carregar a agenda do profissional:", error);
        });
    }
  }, [professional]);

  useEffect(() => {
    const fetchAuthenticatedImageUrl = async () => {
      try {
        const token = localStorage.getItem("bridges.token");
        if (token) {
          const url = await authenticateProfessionalImageUrl(professional.id, token);
          setImagem(url);
        }
      } catch (error) {
        setImagem(AnonymousProfilePhoto);
      }
    };

    if (professional?.photo) {
      fetchAuthenticatedImageUrl();
    }
  }, [professional]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;
    if (name == "document") value = formatDocument(value);
    else if (name == "phone") value = formatPhone(value);
    else if (name == 'n_conselho') value = value.replace(/\D/g, '');
    setForm({ ...form, [name]: value });
  };

  const handleHoursConfigChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let { name, value } = event.target;

    const [day, field] = name.split(",");
    setFormWeek({
      ...formWeek,
      [day]: {
        week_day: day,
        ...formWeek[day],
        [field]: value + ":00",
      },
    });
  };

  const saveClient = () => {
    setLoading(true);
    const formData = new FormData();

    formData.append("name", form["name"]);
    formData.append("document", form["document"]);
    formData.append("email", form["email"]);
    formData.append("phone", form["phone"]);
    formData.append("address", form["address"]);
    formData.append("n_conselho", form["n_conselho"]);
    formData.append("estabilishments", JSON.stringify(selectedEstablishment?.id || ""));
    formData.append("alias", form["alias"]);

    if (imagem) formData.append("photo", selectedImage);

    api
      .post("/professionals/", formData)
      .then((response) => {
        toast.success("Profissional cadastrado com sucesso!");
        closeModal?.();
        updateClients?.();

        // Salvar a agenda do profissional
        for (const chave in formWeek) {
          if (!(Object.keys(formWeek[chave]).length)) continue;

          api.post(`/agenda/?id=${response.data.id}/`, {
            ...formWeek[chave],
            professional: response.data.id,
          }).catch((error) => {
            console.error("Erro ao salvar agenda:", error);
          });
        }
      })
      .catch(() => {
        toast.error("Erro ao cadastrar o profissional!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateClient = () => {
    setLoading(true);
    const formData = new FormData();

    formData.append("name", form["name"]);
    formData.append("document", form["document"]);
    formData.append("email", form["email"]);
    formData.append("phone", form["phone"]);
    formData.append("address", form["address"]);
    formData.append("n_conselho", form["n_conselho"]);
    formData.append("estabilishments", JSON.stringify(selectedEstablishment?.id || ""));
    formData.append("alias", form["alias"]);

    if (selectedImage instanceof File) {
      formData.append("photo", selectedImage);
    }

    api
      .put(`/professionals/${professional.id}/`, formData)
      .then(() => {
        toast.success("Profissional atualizado com sucesso!");
        closeModal?.();
        updateClients?.();

        // Atualizar a agenda do profissional
        for (const chave in formWeek) {
          if (!(Object.keys(formWeek[chave]).length)) continue;

          const { id, ...agendaData } = formWeek[chave];
          const requestData = {
            ...agendaData,
            professional: professional.id,
          };
          
          if (id === undefined) {
            api.post('/agenda/', requestData)
              .catch((error) => {
                console.error("Erro ao criar agenda:", error);
              });
          } else {
            api.put(`/agenda/${id}/`, requestData)
              .catch((error) => {
                console.error("Erro ao atualizar agenda:", error);
              });
          }
        }
        navigate("/professionals");
      })
      .catch(() => {
        toast.error("Erro ao atualizar o profissional!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    api
      .get("/establishments/")
      .then((response) => {
        setEstablishments(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleEstablishmentSelect = (option: any) => {
    setSelectedEstablisiment(option);
    setForm({ ...form, estabilishments: [option.id] });
  };

  const validateNextStep = () => {
    if (stepActive.stepTitle === "Informações Básicas") {
      if (form["name"].length < 3) {
        toast.error("Digite um nome válido");
        return false;
      } else if (!validateCPF(form["document"])) {
        toast.error("Digite um CPF válido");
        return false;
      } else if (selectedEstablishment == null) {
        toast.error("Escolha um estabelecimento válido");
        return false;
      } else if (form["phone"].replace(/\D/g, "").length !== 11) {
        toast.error("Digite um número de telefone válido");
        return false;
      }
    }
    return true;
  };

  const handleFileUpload = (event: any) => {
    const arquivo = event.target.files[0];
    const reader = new FileReader();
    setSelectedImage(arquivo)

    reader.onload = () => {
      const result = reader.result as any
      setImagem(result);
    };

    reader.readAsDataURL(arquivo);
  };

  return (
    <ModalStepByStep
      steps={steps}
      onChangeStep={(step) => {
        setStepActive({ stepTitle: step.stepTitle });
      }}
      closeModal={closeModal}
      onConclude={() => {
        saveClient();
      }}
      onUpdate={() => {
        updateClient();
      }}
      validateNextStep={() => validateNextStep()}
      isUpdateModal={!!professional}
    >
      <ContentWrapper>
        <InfoWrapper>
          {stepActive.stepTitle == steps[0].stepTitle && (
            <>
              <FlexContainer>
                <CustomInput
                  disabled={loading}
                  value={form["name"]}
                  onChange={handleInputChange}
                  label="Nome"
                  name="name"
                  placeholder="Digite o nome do profissional"
                  leftIcon={
                    <PiUserCircleLight
                      size={20}
                      color="var(--primary-icon-color)"
                    />
                  }
                />
                <CustomInput
                  disabled={loading}
                  value={form["document"]}
                  onChange={handleInputChange}
                  label="CPF"
                  name="document"
                  placeholder="Digite o CPF do profissional"
                  leftIcon={
                    <PiIdentificationCardLight
                      size={20}
                      color="var(--primary-icon-color)"
                    />
                  }
                />
              </FlexContainer>
              <FlexContainer>
                <CustomInput
                  disabled={loading}
                  value={form["email"]}
                  onChange={handleInputChange}
                  label="Email"
                  name="email"
                  placeholder="Digite o Email do profissional"
                  leftIcon={
                    <CiMail size={20} color="var(--primary-icon-color)" />
                  }
                />
                <CustomDropdown
                  leftIcon={
                    <RiHotelLine size={20} color="var(--primary-icon-color)" />
                  }
                  selectedOption={selectedEstablishment?.name}
                  setSelectedOption={handleEstablishmentSelect}
                  options={establishments}
                  label="Estabelecimento"
                />
              </FlexContainer>

              <FlexContainer>
                <CustomInput
                  disabled={loading}
                  value={form["phone"]}
                  onChange={handleInputChange}
                  label="Telefone"
                  name="phone"
                  placeholder="Digite o Telefone do profissional"
                  leftIcon={
                    <PiPhone size={20} color="var(--primary-icon-color)" />
                  }
                />
                <CustomInput
                  disabled={loading}
                  value={form["n_conselho"]}
                  onChange={handleInputChange}
                  label="Número do conselho"
                  name="n_conselho"
                  placeholder="Digite o número do conselho do profissional"
                  leftIcon={
                    <MdOutlineBadge size={20} color="var(--primary-icon-color)" />
                  }
                />
              </FlexContainer>
              <FlexContainer>
                <CustomInput
                  disabled={loading}
                  value={form["address"]}
                  onChange={handleInputChange}
                  label="Endereço"
                  name="address"
                  placeholder="Digite o endereço do profissional"
                  leftIcon={
                    <GoLocation size={20} color="var(--primary-icon-color)" />
                  }
                />
                <CustomInput
                  disabled={loading}
                  value={form["alias"]}
                  onChange={handleInputChange}
                  label="Apelido"
                  name="alias"
                  placeholder="Digite o apelido do profissional"
                  leftIcon={
                    <MdOutlineBadge size={20} color="var(--primary-icon-color)" />
                  }
                />
              </FlexContainer>
            </>
          )}

          {stepActive.stepTitle == steps[1].stepTitle && (
            <ConfigHoursContainer>
              <WeekendContainer>
                {weekendDay.map((day) => {
                  return (
                    <ConfigHoursWrapper key={day}>
                      <HeaderTitle>
                        {day}{" "}
                        {formWeek[day]?.is_available ? (
                          <IconContainer
                            title="Bloquear o horário"
                            onClick={() => {
                              const newFormWeek = { ...formWeek };
                              newFormWeek[day] = { ...newFormWeek[day], is_available: false };
                              setFormWeek(newFormWeek); // Atualiza o estado
                            }}
                          >
                            <LockIcon size={16} />
                          </IconContainer>
                        ) : (
                          <IconContainer
                            title="Desbloquear o horário"
                            onClick={() => {
                              const newFormWeek = { ...formWeek };
                              newFormWeek[day] = { ...newFormWeek[day], is_available: true };
                              setFormWeek(newFormWeek); // Atualiza o estado
                            }}
                          >
                            <LockOutlineIcon size={16} />
                          </IconContainer>
                        )}
                      </HeaderTitle>

                      <CustomInputWrapper>
                        <CustomInput
                          disabled={loading}
                          label="Horário de Entrada"
                          name={day + ",start_time"}
                          onChange={handleHoursConfigChange}
                          placeholder="06:00"
                          type="time"
                          value={formWeek[day]?.start_time || ""}
                          leftIcon={<IoMdTime size={16} color="var(--primary-icon-color)" />}
                        />

                        <CustomInput
                          disabled={loading}
                          label="Horário de Saída"
                          name={day + ",end_time"}
                          onChange={handleHoursConfigChange}
                          placeholder="06:00"
                          type="time"
                          value={formWeek[day]?.end_time || ""}
                          leftIcon={<IoMdTime size={16} color="var(--primary-icon-color)" />}
                        />
                      </CustomInputWrapper>

                      <CustomInputWrapper>
                        <CustomInput
                          disabled={loading}
                          label="Almoço Entrada"
                          name={day + ",lunch_start_time"}
                          onChange={handleHoursConfigChange}
                          placeholder="06:00"
                          type="time"
                          value={formWeek[day]?.lunch_start_time || ""}
                          leftIcon={<IoMdTime size={16} color="var(--primary-icon-color)" />}
                        />

                        <CustomInput
                          disabled={loading}
                          label="Almoço Saída"
                          name={day + ",lunch_end_time"}
                          onChange={handleHoursConfigChange}
                          placeholder="06:00"
                          type="time"
                          value={formWeek[day]?.lunch_end_time || ""}
                          leftIcon={<IoMdTime size={16} color="var(--primary-icon-color)" />}
                        />
                      </CustomInputWrapper>
                    </ConfigHoursWrapper>
                  );
                })}
              </WeekendContainer>

              <WeekContainer>
                {weekDay.map((day) => {
                  return (
                    <ConfigHoursWrapper key={day}>
                      <HeaderTitle>
                        {day}{" "}
                        {formWeek[day]?.is_available ? (
                          <IconContainer
                            title="Bloquear o horário"
                            onClick={() => {
                              const newFormWeek = { ...formWeek };
                              newFormWeek[day] = { ...newFormWeek[day], is_available: false };
                              setFormWeek(newFormWeek); // Atualiza o estado
                            }}
                          >
                            <LockIcon size={16} />
                          </IconContainer>
                        ) : (
                          <IconContainer
                            title="Desbloquear o horário"
                            onClick={() => {
                              const newFormWeek = { ...formWeek };
                              newFormWeek[day] = { ...newFormWeek[day], is_available: true };
                              setFormWeek(newFormWeek); // Atualiza o estado
                            }}
                          >
                            <LockOutlineIcon size={16} />
                          </IconContainer>
                        )}
                      </HeaderTitle>

                      <CustomInputWrapper>
                        <CustomInput
                          disabled={loading}
                          label="Horário de Entrada"
                          name={day + ",start_time"}
                          onChange={handleHoursConfigChange}
                          placeholder="06:00"
                          type="time"
                          value={formWeek[day]?.start_time || ""}
                          leftIcon={<IoMdTime size={16} color="var(--primary-icon-color)" />}
                        />

                        <CustomInput
                          disabled={loading}
                          label="Horário de Saída"
                          name={day + ",end_time"}
                          onChange={handleHoursConfigChange}
                          placeholder="06:00"
                          type="time"
                          value={formWeek[day]?.end_time || ""}
                          leftIcon={<IoMdTime size={16} color="var(--primary-icon-color)" />}
                        />
                      </CustomInputWrapper>

                      <CustomInputWrapper>
                        <CustomInput
                          disabled={loading}
                          label="Almoço Entrada"
                          name={day + ",lunch_start_time"}
                          onChange={handleHoursConfigChange}
                          placeholder="06:00"
                          type="time"
                          value={formWeek[day]?.lunch_start_time || ""}
                          leftIcon={<IoMdTime size={16} color="var(--primary-icon-color)" />}
                        />

                        <CustomInput
                          disabled={loading}
                          label="Almoço Saída"
                          name={day + ",lunch_end_time"}
                          onChange={handleHoursConfigChange}
                          placeholder="06:00"
                          type="time"
                          value={formWeek[day]?.lunch_end_time || ""}
                          leftIcon={<IoMdTime size={16} color="var(--primary-icon-color)" />}
                        />
                      </CustomInputWrapper>
                    </ConfigHoursWrapper>
                  );
                })}
              </WeekContainer>

            </ConfigHoursContainer>
          )}

          {stepActive.stepTitle == steps[2].stepTitle && (
            <>
              <FlexContainer>
                <ProfileImageWrapper
                  src={imagem || AnonymousProfilePhoto}
                  alt="Foto de perfil"
                />
                <div style={{ marginLeft: '10px' }}>
                  <CustomInput
                    label="Escolha uma foto"
                    type="file"
                    disableBorder
                    onChange={handleFileUpload}
                  />
                </div>
              </FlexContainer>
            </>
          )}
        </InfoWrapper>
      </ContentWrapper>
    </ModalStepByStep>
  );
};

export default CreateProfessional;
