import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MdPlayArrow } from "react-icons/md";
import { MdStop } from "react-icons/md";

interface CheckIconProps {
    color?: 'green' | 'yellow' | 'blue'; 
    playing?: boolean;
    startDate: string;
}

const colors = {
    green: '#00D346',
    yellow: '#FFD400',
    blue: '#1270FC',

    red: '#ff7777',
};

const IconWrapper = styled.div<{ color: string }>`
    position: relative;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background-color: ${({ color }) => color};
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    transition: transform 0.2s, background-color 0.2s;

    &:hover {
        transform: scale(1.1);
        background-color: ${({ color }) => darkenColor(color)};
    }

    &:active {
        transform: scale(0.95);
    }

    &:hover::before {
        position: absolute;
        top: -30px; 
        font-size: 12px;
        font-weight: bold;
        color: #333;
        background-color: white;
        padding: 4px 8px;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        white-space: nowrap;
    }
`;

const darkenColor = (color: string): string => {
    const factor = 0.85; 
    const hexToRgb = (hex: string) => hex.match(/\w\w/g)?.map((x) => parseInt(x, 16)) || [0, 0, 0];
    const rgbToHex = (r: number, g: number, b: number) =>
        `#${[r, g, b].map((x) => x.toString(16).padStart(2, '0')).join('')}`;
    const [r, g, b] = hexToRgb(color);
    return rgbToHex(Math.floor(r * factor), Math.floor(g * factor), Math.floor(b * factor));
};

export const PlayStopIcon: React.FC<CheckIconProps> = ({ color = 'green', playing = true, startDate }) => {
    const [timeDifference, setTimeDifference] = useState<string>("00:00");
    
    useEffect(() => {
        const calculateTimeDifference = () => {
            const start = new Date(startDate);
            const now = new Date();
            const diff = now.getTime() - start.getTime();

            const hours = Math.floor(diff / (1000 * 60 * 60));
            const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((diff % (1000 * 60)) / 1000);

            setTimeDifference(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`);
        };

        calculateTimeDifference();
        const interval = setInterval(calculateTimeDifference, 1000);

        return () => clearInterval(interval);
    }, [startDate]);

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '2px'}}>
            {            
                playing ? 
                    <IconWrapper color={colors['red']}>
                        <MdStop size={24} />
                    </IconWrapper> 
                    :
                    <IconWrapper color={colors[color]}>
                        <MdPlayArrow size={24} />
                    </IconWrapper>
            }

            {playing && <div>{timeDifference}</div> }
        </div>
    );
};
