import styled from "styled-components";

interface ITableProps {
  isTitleLine?: boolean;
  isPair?: boolean;
  statusColor?: string;
  isCenterLine?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 100px);
  flex-direction: column;
  gap: 10px;
  align-items: center;
  overflow: auto;
  padding-bottom: 15px;
`;

export const SummaryWrapper = styled.div`
border-radius: 8px;
  width: 98%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  color: white;
  margin-top: 10px;
`;

export const ContentWrapper = styled.div`
  border-radius: 8px;
  width: 98%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  color: white;
  margin-top: 10px;
  -webkit-box-shadow: 0px 1px 11px 3px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 1px 11px 3px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 11px 3px rgba(0, 0, 0, 0.12);
`;

export const MainTitle = styled.text`
  font-size: 20px;
  font-weight: 600;
`;

export const SectionsWrapper = styled.div`
  padding: 10px;
`;

export const SectionTitle = styled.text`
  font-size: 23px;
  font-weight: 400;
  color: black;
`;

export const MainTitleWrapper = styled.div`
  background-color: rgb(18, 112, 252);
  padding: 8px;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Subtitle = styled.text`
  font-size: 18px;
  font-weight: 600;
  color: black;
`;

export const SeciontInputsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

export const SectionSeparator = styled.hr``;

export const DescriptionInputText = styled.text`
  color: gray;
  font-size: 13px;
`;

export const ExpandableWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const HabitsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InputRecord = styled.input``;

export const CheckboxListWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckBoxLabel = styled.text`
  color: gray;
  font-size: 14px;
`;

export const AwayHomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const PurcasheQuantityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 30%;
  gap: 5px;
`;

export const PatologyMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const PatologyTextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ClinicAvaliationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const FoodOptionsListWrapper = styled.div`
  display: flex;
  gap: 50px;
`;

export const EvacuationDataWrapper = styled.div``;

export const EvacuationFrequencyWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: blue;
`;

export const SelectOptions = styled.select`
  height: 40px;
  background-color: red;
`;

export const WrapperFrequencyInput = styled.div`
  width: 50%;
  display: flex;
  gap: 20px;
`;

export const UrineExpandableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const UrineStartInformationsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const HydroInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FoodsHabits = styled.div``;

export const FoodHabitsInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ExpandableAntropometryWrapper = styled.div`
  margin-top: 20px;
`;

export const BodyCompositionWrapper = styled.div``;

export const ProtocolWrapper = styled.div`
  width: 50%;
`;

export const Td = styled.td<ITableProps>`
  padding: 0;
  text-align: ${(element) => (element.isTitleLine ? "none" : "center")};
  padding: 10px;
`;

export const Tr = styled.tr<ITableProps>`
  background-color: ${(element) =>
    element.isPair ? "rgb(18, 112, 252)" : "none"};
  color: ${(element) => (element.isPair ? "white" : "black")};
`;

export const Th = styled.th`
  color: rgb(18, 112, 252);
  padding: 0;
  padding-top: 5px;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  padding: 10px;
`;

export const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const Status = styled.div<ITableProps>`
  background-color: ${(element) => element.statusColor};
  color: white;
  padding: 5px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  min-width: 90px;
`;

export const WrapperStatus = styled.div`
  display: flex;
  justify-content: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TextIconImage = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 5px;
`;

export const IconWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1); /* Aumenta um pouco o ícone ao passar o mouse */
  }
`;

export const StatusIndicatorWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StatusIndicator = styled.div<{ color: string }>`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;
