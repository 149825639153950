import { InputHTMLAttributes, ReactNode } from "react";
import { Wrapper } from "../Shared";
import { Input, InputWrapper, Label, CheckBoxLabel, ShowFileNameSpan, AlertIcons, WrapperInputTitle, ErrorMessage } from "./style";

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
    mask?: 'currency';
    disableBorder?: boolean;
    inputStyle?: any;
    inputId?: string;
    fileSelected?: string;
    showEmptyField?: boolean;
    errorMessage?: string;
}

export const CustomInput = (props: CustomInputProps) => {
    return (
        <Wrapper>
            {
                props.label && props.type != 'checkbox' && 
                <WrapperInputTitle>
                    <span>
                        {props.label}
                        {props?.showEmptyField && <AlertIcons>!</AlertIcons>}
                    </span>
                </WrapperInputTitle>
            }
            <InputWrapper 
                style={{...props.inputStyle}} 
                disabled={props.disabled} 
                disableBorder={props.disableBorder} 
                isTypeFile={props.type == 'file' || props.type == 'checkbox'}
                hasError={!!props.errorMessage}
            >
                {props.leftIcon && props.type != 'checkbox' && <>{props.leftIcon}</>}
                {props.type == 'file' && <>
                    <Label htmlFor={props.inputId ? props.inputId : 'fileInput'}>Escolher arquivo</Label>
                </>}
                {props.type != 'checkbox' && <Input {...props} id={props.inputId ? props.inputId : 'fileInput'} typeStyle={props.type}/>}
                {props.label && props.type == 'checkbox' && <CheckBoxLabel htmlFor="checkBoxButton"> <Input {...props} id="checkBoxButton" typeStyle={props.type}/> {props.label}</CheckBoxLabel>}
                {props.fileSelected ? <ShowFileNameSpan>{props.fileSelected}</ShowFileNameSpan> : <span></span>}
            </InputWrapper>
            {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
        </Wrapper>
    );
}

export default CustomInput;
