import styled from "styled-components";

const Colors = {
    default: {
        color: "#919EAB",
        backgroundColor: "#F4F6F8",
    },
    success: {
        color: "#118D57",
        backgroundColor: "#D3FCD2",
    },
    pending: {
        color: "#E6A500", // Amarelo escuro puxado para dourado
        backgroundColor: "#FFECB3", // Amarelo claro suave
    },
    danger: {
        color: "#FF0000",
        backgroundColor: "#FFD6D6",
    },
    waiting: {
        color: "#0000FF", // Azul forte
        backgroundColor: "#D6E6FF", // Azul claro
    },
};

export interface StatusShapeProps {
    type: keyof typeof Colors;
}

export const StatusShape = styled.span<StatusShapeProps>`
    color: ${({ type }) => Colors[type].color};
    border: 1px solid ${({ type }) => Colors[type].color};
    background-color: ${({ type }) => Colors[type].backgroundColor};
    border-radius: 4px;
    padding: 5px;
    text-align: center;
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0 0 2px 2px ${({ type }) => Colors[type].color + "55"};
    }
`;
