import styled from "styled-components";

export const HourAndValueWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-right: 1px solid #D3D3D3;
    height: 100%;
    padding-right: 10px;
`

export const ListItem = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #1270FC;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #1270FC;
    margin-right: 8px;
`

export interface HourProps {
    color?: string;
}

export const Hour = styled.div<HourProps>`
    background-color: ${({ color }) => color || '#1270FC'};
    width: 60px;
    height: 60px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-left: 10px;
    margin-right: 15px;
`

export const Value = styled.div`
`

export const InfoWrapper = styled.div`
    padding: 0 10px 10px 10px;
    flex: 1;
`

export const InitialAndEndTimeWrapper = styled.div`
    
`
interface WrapperProps {
    hasExams: boolean;
}

export const InitialWrapper = styled.div<WrapperProps>`
    display: flex;
    align-items: center;
    margin-top: ${({ hasExams }) => (hasExams ? '0px' : '10px')};
`;

export const EndWrapper = styled.div<WrapperProps>`
    display: flex;
    align-items: center;
    margin-bottom: ${({ hasExams }) => (hasExams ? '5px' : '10px')};
`;

export const AverageInfoWrapper = styled.div<WrapperProps>`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: ${({ hasExams }) => (hasExams ? '-2px' : '5px')};
`;

export const DisplayBlockTimeBox = styled.div`
    margin-left: 10px;
`

export const DisplayBlock = styled.div`
`

export const ExamsHeader = styled.div`
    width: 400px;
    height: 400px;
`

export const ExamsListWrapper = styled.div`
    margin-top: 15px;
    overflow: auto; 
    height: 350px;
` 

interface ExamsListItemProps {
    isEven: boolean
}

export const ExamsListItem = styled.div<ExamsListItemProps>`
    padding: 6px;
    background: ${({isEven}) => isEven ? '#c7ddff' : '#F4F4F4'}; 
    color: ${({isEven}) => isEven ? '#1270FC' : '#000'};
`

export const ExamsProfessionalCardInfo = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
` 

export const IconContainer = styled.div<WrapperProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 10px;
`;

export const ExamIcon = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #007bff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 10px;
    position: relative;
    transition: background-color 0.3s;
    
    &:hover {
        background-color: #0056b3;
    }

    &:hover::after {
        content: attr(data-name);
        position: absolute;
        bottom: -30px;
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        padding: 5px;
        border-radius: 3px;
        font-size: 12px;
    }
`;