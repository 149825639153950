import { useNavigate } from "react-router-dom";
import {
  Title,
  Subtitle,
  Info,
  AlertIcons,
  InfoLabelWrapper,
  SubtitleWrapper,
  MedicalReportButton,
} from "./style";
import React from "react";

interface InfoLabelProps {
  label: string;
  value: string;
  alert?: boolean;
  tagName?: string;
  tagColor?: string;
  onClick?: () => void;
  patient?: any;
}

export const InfoPaciente: React.FC<InfoLabelProps> = ({
  label,
  value,
  alert,
  tagName,
  tagColor,
  onClick,
  patient,
}) => {
  const navigate = useNavigate();
  return (
    <InfoLabelWrapper>
      <Title>{label}</Title>
      <SubtitleWrapper onClick={onClick}>
        <Subtitle>{value}</Subtitle>
        {tagName && <Info color={tagColor || "#767676"}>{tagName}</Info>}
      </SubtitleWrapper>
      {tagName && (
        <MedicalReportButton
          onClick={() => {
            navigate("/medicalRecordHistory", {
              state: {
                patient: patient,
              },
            });
          }}
        >
          Prontuário
        </MedicalReportButton>
      )}
    </InfoLabelWrapper>
  );
};
