import styled from "styled-components";

const TagsPaymentType = {
    active: {
        color: '#0BA900',
        backgroundColor: '#00D34645',
    },
    inactive: {
        color: '#ff4d4d',
        backgroundColor: '#ff9999',
    },
}

export interface TagsProps {
    type: keyof typeof TagsPaymentType;
}


export const TagsWrapper = styled.div<TagsProps>`
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 50px;
    border: 2px solid ${({ type }) => TagsPaymentType[type].color};
    background-color: ${({ type }) => TagsPaymentType[type].backgroundColor};
`

export const Ball = styled.div<TagsProps>`
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: ${({ type }) => TagsPaymentType[type].color};
    margin-right: 8px;
`

export const TagText = styled.span<TagsProps>`
    font-size: 14px;
    line-height: 16px;
    color: ${({ type }) => TagsPaymentType[type].color};
` 