import { useEffect, useState } from "react";
import { BiWorld } from "react-icons/bi";
import { CiMail } from "react-icons/ci";
import { FaHashtag } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { LuAtSign } from "react-icons/lu";
import { MdOutlineWorkOutline } from "react-icons/md";
import {
  PiIdentificationCardLight,
  PiPhone,
  PiUserCircleLight,
} from "react-icons/pi";
import { toast } from "react-toastify";
import CustomDropdown from "../../../components/CustomDropDown";
import CustomInput from "../../../components/CustomInput";
import ModalStepByStep from "../../../components/ModalStepByStep";
import api from "../../../service/api";
import formatCEP from "../../../utils/formatCEP";
import { formatDocument } from "../../../utils/formatDocument";
import { formatPhone } from "../../../utils/formatPhone";
import { InfoWrapper } from "../../Event/Create/style";
import { ContentWrapper, WrapperInput } from "./style";
import { BsPersonHeart } from "react-icons/bs";

interface CreateProfessionalProps {
  closeModal: () => void;
  updatePatients?: () => void;
  patient: any;
}

export const CreateProfessional = ({
  closeModal,
  updatePatients,
  patient,
}: CreateProfessionalProps) => {
  const [tags, setClientTags] = useState<any[]>([]);

  const fetchClientTags = () => {
    api
      .get("/tags/")
      .then((resp) => {
        setClientTags(resp.data);
      })
      .catch(() => { });
  };

  useEffect(() => {
    fetchClientTags();
  }, []);

  const [form, setForm] = useState<any>({
    name: "",
    document: "",
    email: "",
    phone: "",
    address: "",
    origin: "",
    ocupation: "",
    birthday: "",
    alias: "",
    cep: "",
    tag: null,
    professional: null,
    sex: "",
  });

  const steps = [
    {
      stepTitle: "Informações Básicas",
    },
    {
      stepTitle: "Dados complementares",
    },
  ];

  const [stepActive, setStepActive] = useState(steps[0]);
  const [loading, setLoading] = useState(false);
  const [selectedTagName, setSelectedTagName] = useState("");
  const [selectedProfessional, setSelectedProfessional] = useState<any>({});
  const [professionals, setProfessionals] = useState<any[]>([]);

  const originOptions = [
    { id: "instagram", name: "Instagram" },
    { id: "indication", name: "Indicação" },
    { id: "indication_renan", name: "Indicação Renan" },
    { id: "facebook", name: "Facebook" },
    { id: "whatsapp", name: "Whatsapp" },
  ];

  const genderOptions = [
    { id: "H", name: "Masculino" },
    { id: "M", name: "Feminino" },
    { id: "O", name: "Outros" },
  ];

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;
    if (name == "document") value = formatDocument(value);
    else if (name == "phone") value = formatPhone(value);
    else if (name == "cep") value = formatCEP(value);
    setForm({ ...form, [name]: value });
  };

  const savePatient = () => {
    setLoading(true);
    api
      .post("/clients/", form)
      .then(() => {
        setLoading(false);
        closeModal();
        toast.success("Paciente cadastrado com sucesso!");
        updatePatients?.();
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao cadastrar o paciente!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updatePatient = () => {
    setLoading(true);
    api
      .patch(`/clients/${patient.id}/`, form)
      .then(() => {
        setLoading(false);
        closeModal();
        toast.success("Paciente atualizado com sucesso!");
        updatePatients?.();
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao atualizar o paciente!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateNextStep = () => {
    if (stepActive.stepTitle == "Informações Básicas") {
      if (form["name"].length < 3) {
        toast.error("Digite um nome válido");
        return false;
      } else if (form["phone"].replace(/\D/g, "").length != 11) {
        toast.error("Digite um número de telefone válido");
        return false;
      } else if (form["birthday"] == "") {
        toast.error("Selecione uma data de nascimento.");
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    if (patient.id) {
      api.get(`/clients/${patient.id}`).then((response) => {
        const patientData = response.data;
        
        form["name"] = patientData.name;
        form["document"] = patientData.document;
        form["email"] = patientData.email;
        form["phone"] = patientData.phone;
        form["address"] = patientData.address;
        form["origin"] = patientData.origin;
        form["birthday"] = patientData.birthday;
        form["cep"] = patientData.cep;
        form["ocupation"] = patientData.ocupation;
        form["tag"] = patientData.tag.id;
        form["alias"] = patientData.alias;
        form["professional"] = patientData.professional;
        form["sex"] = patientData.sex;
        
        setSelectedTagName(patientData.tag.name);
  
        api.get(`/professionals/`).then((response) => {
          setProfessionals(response.data);
          
          const mappedProfessional = response.data.find(
            (item: any) => item.id == patientData.professional
          );
          setSelectedProfessional(mappedProfessional);
        });
      });
    }
  }, []);
  

  const handleDropdownSelected = (key: any, value: any) => {
    setForm({ ...form, [key]: value });
  };

  return (
    <ModalStepByStep
      steps={steps}
      modalTitle="Editar paciente"
      onUpdate={updatePatient}
      isUpdateModal={true}
      onChangeStep={(step) => {
        setStepActive({ stepTitle: step.stepTitle });
      }}
      closeModal={closeModal}
      onConclude={() => {
        savePatient();
      }}
      validateNextStep={() => validateNextStep()}
      isLoadingRequest={loading}
    >
      <ContentWrapper>
        <InfoWrapper>
          {stepActive.stepTitle == steps[0].stepTitle && (
            <>
              <WrapperInput>
                <CustomInput
                  disabled={loading}
                  value={form["name"]}
                  onChange={handleInputChange}
                  label="Nome"
                  name="name"
                  placeholder="Digite o nome do paciente"
                  leftIcon={
                    <PiUserCircleLight
                      size={20}
                      color="var(--primary-icon-color)"
                    />
                  }
                  showEmptyField={!form?.["name"]}
                />
                <CustomInput
                  disabled={loading}
                  value={form["document"]}
                  onChange={handleInputChange}
                  label="CPF"
                  name="document"
                  placeholder="Digite o CPF do paciente"
                  leftIcon={
                    <PiIdentificationCardLight
                      size={20}
                      color="var(--primary-icon-color)"
                    />
                  }
                  showEmptyField={!form?.["document"]}
                />
              </WrapperInput>
              <WrapperInput>
                <CustomInput
                  disabled={loading}
                  value={form["email"]}
                  onChange={handleInputChange}
                  label="E-mail"
                  name="email"
                  placeholder="Digite o email do paciente"
                  leftIcon={
                    <CiMail size={20} color="var(--primary-icon-color)" />
                  }
                  showEmptyField={!form?.["email"]}
                />
                <CustomInput
                  disabled={loading}
                  value={form["phone"]}
                  onChange={handleInputChange}
                  label="Telefone"
                  name="phone"
                  placeholder="Digite o telefone do paciente"
                  leftIcon={
                    <PiPhone size={20} color="var(--primary-icon-color)" />
                  }
                  showEmptyField={!form?.["phone"]}

                />
              </WrapperInput>
              <WrapperInput>
                <CustomInput
                  disabled={loading}
                  value={form["alias"]}
                  onChange={handleInputChange}
                  label="Apelido"
                  name="alias"
                  placeholder="Como gostaria de ser chamado"
                  leftIcon={
                    <BsPersonHeart
                      size={20}
                      color="var(--primary-icon-color)"
                    />
                  }
                  showEmptyField={!form?.["alias"]}
                />
                <CustomDropdown
                  label="Sexo"
                  options={genderOptions}
                  selectedOption={
                    genderOptions.find((option) => option.id === form.sex)?.name || ""
                  }
                  setSelectedOption={(value: any) =>
                    handleDropdownSelected("sex", value.id)
                  }
                  leftIcon={
                    <HiOutlineUserCircle size={20} color="var(--primary-icon-color)" />
                  }
                />
              </WrapperInput>
            </>
          )}
          {stepActive.stepTitle == steps[1].stepTitle && (
            <>
              <WrapperInput>
                <CustomInput
                  disabled={loading}
                  onChange={handleInputChange}
                  value={form["address"]}
                  label="Endereço"
                  name="address"
                  placeholder="Digite o endereço do paciente"
                  leftIcon={
                    <GoLocation size={20} color="var(--primary-icon-color)" />
                  }
                  showEmptyField={!form?.["address"]}
                />
                <CustomInput
                  disabled={loading}
                  onChange={handleInputChange}
                  value={form["cep"]}
                  label="CEP"
                  name="cep"
                  placeholder="Digite o CEP do paciente"
                  leftIcon={
                    <BiWorld size={20} color="var(--primary-icon-color)" />
                  }
                  showEmptyField={!form?.["cep"]}
                />
              </WrapperInput>
              <WrapperInput>
                <CustomInput
                  disabled={loading}
                  value={form["birthday"]}
                  type="date"
                  onChange={handleInputChange}
                  label="Data de nascimento"
                  name="birthday"
                  placeholder="Digite o telefone do paciente"
                  leftIcon={
                    <LiaBirthdayCakeSolid
                      size={20}
                      color="var(--primary-icon-color)"
                    />
                  }
                  showEmptyField={!form?.["birthday"]}
                />
                <CustomDropdown
                  label="Origem"
                  options={originOptions}
                  selectedOption={
                    originOptions.find((option) => option.id === form.origin)?.name || ""
                  }
                  setSelectedOption={(value: any) =>
                    handleDropdownSelected("origin", value.id)
                  }
                  leftIcon={
                    <LuAtSign size={20} color="var(--primary-icon-color)" />
                  }
                />
              </WrapperInput>
              <WrapperInput>
                <CustomInput
                  disabled={loading}
                  value={form["ocupation"]}
                  onChange={handleInputChange}
                  label="Ocupação"
                  name="ocupation"
                  placeholder="Digite a ocupação do paciente"
                  leftIcon={
                    <MdOutlineWorkOutline
                      size={20}
                      color="var(--primary-icon-color)"
                    />
                  }
                  showEmptyField={!form?.["ocupation"]}
                />
                <CustomDropdown
                  label="Tag"
                  options={tags}
                  selectedOption={selectedTagName}
                  setSelectedOption={(value) => {
                    handleDropdownSelected("tag", value.id);
                    setSelectedTagName(value.name);
                  }}
                  leftIcon={
                    <FaHashtag size={16} color="var(--primary-icon-color)" />
                  }
                />
              </WrapperInput>
              <WrapperInput>
                <CustomDropdown
                  label="Profissional"
                  setSelectedOption={(value: any) => {
                    handleDropdownSelected("professional", value.id);
                    setSelectedProfessional(value);
                  }}
                  selectedOption={
                    selectedProfessional?.name != null
                      ? selectedProfessional.name
                      : ""
                  }
                  options={professionals}
                  allowCreate={false}
                  onCreate={() => { }}
                  createLabel="Cadastrar profissional"
                  leftIcon={
                    <HiOutlineUserCircle
                      size={16}
                      color="var(--primary-icon-color)"
                    />
                  }
                />
              </WrapperInput>
            </>
          )}
        </InfoWrapper>
      </ContentWrapper>
    </ModalStepByStep>
  );
};

export default CreateProfessional;
