import styled from 'styled-components';

export const InfoLabelWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 15px;
    margin-bottom: 45px;
    position: relative;
`;

export const ProfileImage = styled.img`
    width: 32px; 
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
`;

export const ProfessionalName = styled.div`
    font-family: 'Nunito Sans';
    color: #4B4B4B;
    font-weight: 600;
    font-size: 16px;
    position: relative;
    min-width: 300px;
    
    &:hover .tooltip {
        visibility: visible;
        opacity: 1;
    }
`;

export const ProfessionalAlias = styled.div`
    font-family: 'Nunito Sans';
    color: #4B4B4B;
    font-weight: 600;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
`;

export const Tooltip = styled.div`
    visibility: hidden;
    background-color:rgba(118, 118, 118, 0.25) !important;
    color: #767676;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    padding: 2px;
    padding-left: 8px;
    padding-right: 8px;
    bottom: 125%;
    left: 50px;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.5s;
    &:hover {
        visibility: visible;
        opacity: 1;
    }
`;