import api from '../service/api';

export const authenticateProfessionalImageUrl = async (professionalId: number, token: string): Promise<string> => {
    const url = `/professionals/${professionalId}/photo-download/`;
    
    try {
        const response = await api.get(url);

        if (response.status === 200) {
            return response.data.download_url;
        } else {
            return '';
        }
    } catch (error) {
        return '';
    }
};
