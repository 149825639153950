import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface RevisionCardProps {
    value: number;
    label: string;
    startDate: Date | null;
    setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
    endDate: Date | null;
    setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
    icon: React.ReactNode;
}

export const NewRevisionCard: React.FC<RevisionCardProps> = ({ value, label, startDate, setStartDate, endDate, setEndDate, icon }) => {
    const [timeRange, setTimeRange] = useState<string>("monthly");

    const rangeOptions = [
        { label: 'Semana', value: 'weekly' },
        { label: 'Mês', value: 'monthly' },
        { label: 'Ano', value: 'yearly' }
    ];

    useEffect(() => {
        const today = new Date();
        setEndDate(today);

        if (timeRange === 'weekly') {
            const last7Days = new Date();
            last7Days.setDate(today.getDate() - 7);
            setStartDate(last7Days);
        } else if (timeRange === 'monthly') {
            const last30Days = new Date();
            last30Days.setDate(today.getDate() - 30);
            setStartDate(last30Days);
        } else if (timeRange === 'yearly') {
            const lastYear = new Date();
            lastYear.setFullYear(today.getFullYear() - 1);
            setStartDate(lastYear);
        }
    }, [timeRange, setStartDate, setEndDate]);

    return (
        <Card>
            <CardContent>
                <Header>
                    <IconWrapper>{icon}</IconWrapper>
                </Header>
                <ValueLabelContainer>
                    <Value>{value}</Value>
                    <Label>{label}</Label>
                </ValueLabelContainer>
            </CardContent>
        </Card>
    );
};

const Card = styled.div`
  margin-top: 10px;
  background-color: white;
  max-height: 140px;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  border: 1px solid #D9D9D9;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconWrapper = styled.div`
  background-color: #1270FC;
  padding: 6px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectorWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const DateRange = styled.div`
  font-size: 12px;
  color: #888888;
  text-align: right;
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 5px;
  margin-right: 5px;
`;

const ValueLabelContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
`;

const Value = styled.span`
  font-size: 70px;
  font-weight: 700;
  color: #444444;
`;

const Label = styled.div`
  color: #444444;
  font-weight: 700;
  margin-left: 5px;
`;

export default NewRevisionCard;