import styled from 'styled-components';

interface ButtonTransactionProps {
    isChecked: boolean;
}

export const WrapperTransactionButtons = styled.div `
    display: flex;
    flex-direction: row;
    border: 1px solid #919EAB52;
    border-radius: 4px;
    height: 50px;
    margin-top: 5px;
    
`;

export const ButtonTransactionType = styled.div<ButtonTransactionProps>`
    flex: 1;
    background-color: ${(prop) => prop.isChecked ? "#1270FC" : ""};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 15px;
    font-weight: 500;
    color: ${(prop) => prop.isChecked ? "white" : ""};
    cursor: pointer;    
`;

export const TransactionButtonsTitle = styled.div`
    color: #637381;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
`;

export const Img = styled.img`
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding: 24px 0;
`;

export const InputWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 10px;
`;

export const CommissionCheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 14px;
    height: 50%;
    border: 1px solid #919EAB52;
    border-radius: 8px;
    background-color: #ffffff;
    margin-top: 2%;
`;

export const FileInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-size: 12px;
    font-weight: 500;
    color: #637381;
    margin-bottom: 5px;
  }

  input[type="file"] {
    display: none;
  }

  .custom-file-upload {
    display: inline-block;
    padding: 14px 20px;
    cursor: pointer;
    border: 1px solid #919EAB52;
    border-radius: 8px;
    background-color: #f5f5f5;
    color: #637381;
    font-size: 14px;
    font-weight: 500;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #e0e0e0;
    }
  }
`;