import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../../assets/bridges.png";
import AnonymousProfilePhoto from "../../../assets/userAnonymous.jpeg";
import CustomButton from "../../../components/CustomButton";
import CustomLabel from "../../../components/CustomLabel";
import PageStructure from "../../../components/PageStructure";
import Modal from "react-modal";
import {
  formatarCPF,
  formatarNumeroTelefone,
} from "../../../service/utils.service";
import {
  ProfileActionsWrapper,
  ProfileHeader,
  ProfileHeaderTop,
  ProfileImageWrapper,
  ProfileInfoWrapper,
} from "../../Patients/Profile/style";
import { ListEmptyLabel, ListEmptyWrapper } from "../../Today/style";
import { HeaderTitle, HeaderWrapper } from "./style";
import { hasAdmin } from "../../../service/token.service";
import { animated, useSpring } from "react-spring";
import { customStyles } from "../../../components/Shared";
import CreateProfessional from "../../../modals/CreateProfessional";
import { ProfessionalServices } from "../../../modals/ProfessionalServices";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import api from "../../../service/api";
import { LogoWrapper } from "../../../components/SideMenu/style";
import { HeaderPage } from "../../../components/Shared/HeaderPage";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { LifeLine } from "react-loading-indicators";
import { authenticateProfessionalImageUrl } from "../../../utils/authenticateImageUrl";

const formatDate = (date: string) => {
  const parsedDate = new Date(date);

  // Definir as opções para formatação da data e hora
  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: "long", // Dia da semana
    day: "2-digit", // Dia com dois dígitos
    month: "long", // Mês por extenso
    year: "numeric", // Ano
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit", // Hora com dois dígitos
    minute: "2-digit", // Minutos com dois dígitos
  };

  // Formatação da data e da hora separadamente
  const formattedDate = new Intl.DateTimeFormat("pt-BR", dateOptions).format(
    parsedDate
  );
  const formattedTime = new Intl.DateTimeFormat("pt-BR", timeOptions).format(
    parsedDate
  );

  return `${formattedDate} às ${formattedTime}`;
};

export const ProfessionalProfile = () => {
  const defaultImage = require("../../../assets/user-profile.png");

  const location = useLocation();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalServiceIsOpen, setModalServiceIsOpen] = useState(false);
  const { professional, hidesidebar } = location.state as {
    professional: any;
    hidesidebar: boolean;
  };
  const [data, setData] = useState<any>([]);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [isLoading, setIsLoading] = useState(false);
  const [authenticatedImageUrl, setAuthenticatedImageUrl] = useState<string>("");

  useEffect(() => {
    fetchAppointmentHistoric();
  }, []);

  useEffect(() => {
    const fetchAuthenticatedImageUrl = async () => {
      try {
        const token = localStorage.getItem("bridges.token");
        if (token && professional?.photo) {
          const url = await authenticateProfessionalImageUrl(professional.id, token);
          setAuthenticatedImageUrl(url);
        }
      } catch (error) {
        setAuthenticatedImageUrl(AnonymousProfilePhoto);
      }
    };

    if (professional?.photo) {
      fetchAuthenticatedImageUrl();
    }
  }, [professional]);

  const fetchAppointmentHistoric = () => {
    setIsLoading(true);
    let toastLoading = toast.loading("Carregando consultas...", {
      className: "toast-loading",
    });

    api
      .get(`/professional/appointment/${professional.id}/`)
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Erro ao carregar as consultas!");
      })
      .finally(() => {
        toast.dismiss(toastLoading);
      });
  };

  const columns = useMemo(
    () => [
      { Header: "Local", accessor: "establishment.alias" },
      { Header: "Paciente", accessor: "patient.name" },
      { Header: "Consulta", accessor: "service.name" },
      { Header: "Status do pagamento", accessor: "status.name" },
      { Header: "Inicio da consulta", accessor: "start_time" },
      { Header: "Fim da consulta", accessor: "end_time" },
    ],
    []
  );

  const getValueByAccessor = (row: any, accessor: string) => {
    return accessor.split(".").reduce((acc, part) => acc && acc[part], row);
  };

  const sortData = (key: string) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const sortedData = [...data].sort((a, b) => {
      const aValue = getValueByAccessor(a, key);
      const bValue = getValueByAccessor(b, key);

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setData(sortedData);
    setSortConfig({ key, direction });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalIsOpen ? 1 : 0 },
  });

  const closeModalService = () => {
    setModalServiceIsOpen(false);
  };

  const fadeService = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalServiceIsOpen ? 1 : 0 },
  });

  return (
    <PageStructure hidesidebar={hidesidebar} showHeader={false}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fade}>
          <CreateProfessional
            closeModal={closeModal}
            professional={professional}
          />
        </animated.div>
      </Modal>

      <Modal
        isOpen={modalServiceIsOpen}
        onRequestClose={closeModalService}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fadeService}>
          <ProfessionalServices
            closeModal={closeModalService}
            professional={professional}
          />
        </animated.div>
      </Modal>

      <ProfileHeader>
        <ProfileHeaderTop>
          <ProfileImageWrapper
            src={authenticatedImageUrl || AnonymousProfilePhoto}
            alt="Foto de perfil"
            onError={(e) => {
              (e.currentTarget as HTMLImageElement).src =
                defaultImage;
            }}
          />
          <CustomLabel label="Nome" info={professional?.name} />
          <CustomLabel label="CPF" info={formatarCPF(professional?.document)} />
          <CustomLabel label="Profissonal" info="Empresário" />
          <CustomLabel label="Email" info={professional?.email} />
          <CustomLabel
            label="Telefone"
            info={formatarNumeroTelefone(professional?.phone)}
          />
          <ProfileActionsWrapper>
            <CustomButton
              disabled={!hasAdmin()}
              onClick={() => setModalServiceIsOpen(true)}
              theme="reschedule"
            >
              Serviços
            </CustomButton>
            <CustomButton
              disabled={!hasAdmin()}
              onClick={() => setIsOpen(true)}
              theme="update"
            >
              Editar
            </CustomButton>
          </ProfileActionsWrapper>
        </ProfileHeaderTop>
      </ProfileHeader>

      <HeaderWrapper>
        <HeaderTitle>Consultas realizadas</HeaderTitle>
      </HeaderWrapper>
      {isLoading && (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LifeLine color="#418DFD" size="medium" text="" textColor="" />
        </div>
      )}
      {data?.length > 0 && !isLoading ? (
        <TableContainer
          component={Paper}
          style={{ maxHeight: "532px", overflow: "auto" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((col) => (
                  <TableCell
                    key={col.Header}
                    style={{
                      backgroundColor: "#1270FC",
                      color: "white",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      cursor: "pointer",
                    }}
                    onClick={() => sortData(col.accessor)}
                  >
                    <div
                      style={{ display: "inline-flex", alignItems: "center" }}
                    >
                      {col.Header}
                      {sortConfig.key === col.accessor && (
                        <span
                          style={{
                            marginLeft: "5px",
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          {sortConfig.direction === "asc" ? (
                            <FiChevronUp size={16} />
                          ) : (
                            <FiChevronDown size={16} />
                          )}
                        </span>
                      )}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row: any, index: any) => (
                <TableRow key={index}>
                  {columns.map((col) => (
                    <TableCell key={col.accessor}>
                      {col.accessor === "start_time" ||
                      col.accessor === "end_time"
                        ? formatDate(getValueByAccessor(row, col.accessor))
                        : getValueByAccessor(row, col.accessor) ||
                          "Não disponível"}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <ListEmptyWrapper style={{ marginTop: "10px" }}>
          <LogoWrapper src={Logo} alt="Logo" />
          <ListEmptyLabel> Sem consultas anteriores! </ListEmptyLabel>
        </ListEmptyWrapper>
      )}
    </PageStructure>
  );
};

export default ProfessionalProfile;
