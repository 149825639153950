import { useEffect, useMemo, useState } from "react";
import api from "../../service/api";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
} from "@mui/material";
import { IoCloseOutline } from "react-icons/io5";
import Modal from "react-modal";
import { animated, useSpring } from "react-spring";
import { customStyles } from "../../components/Shared";
import { FiEdit, FiTrash } from "react-icons/fi";
import {
  Actions,
  ButtonWrapper,
  HeaderTitle,
  HeaderWrapper,
  ModalContent,
  StyledButtonPrimary,
  StyledButtonSecondary,
  StyledButtonWrapper,
  StyledForm,
  StyledInput,
  StyledModalTitle,
  StyledModalWrapper,
} from "./style";
import { formatDecimalValues } from "../../utils/formatDecimalValues";
import CustomButton from "../../components/CustomButton";
import { formatPercentage } from "../../utils/formatPercentage";
import formatCurrency from "../../utils/formatCurrencyBr";
import { sanitizePrice } from "../../utils/sanitizePrice";

interface ProfessionalServicesProps {
  closeModal: () => void;
  professional?: any;
}

export const ProfessionalServices = ({
  closeModal,
  professional,
}: ProfessionalServicesProps) => {
  const [services, setServices] = useState<any>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editingService, setEditingService] = useState<any>(null);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [serviceToDelete, setServiceToDelete] = useState<any>(null);

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
      },
      {
        Header: "Preço",
        accessor: "price",
        Cell: ({ value }: { value: number }) => (
          <span>{`R$ ${formatDecimalValues(value)}`}</span>
        ),
      },
      {
        Header: "Ativo",
        accessor: "is_available",
        Cell: ({ value }: any) => <span>{value ? "Sim" : "Não"}</span>,
      },
      {
        Header: "Comissão da Clínica",
        accessor: "clinica_commission",
        Cell: ({ value }: { value: number }) => <span>{`${value}%`}</span>,
      },
      {
        Header: "Ações",
        Cell: ({ row }: any) => (
          <Actions>
            <FiEdit
              size={18}
              cursor="pointer"
              onClick={() => handleEdit(row.original)}
            />
            <FiTrash
              size={18}
              cursor="pointer"
              color="#e74c3c"
              onClick={() => handleDeleteClick(row.original)}
            />
          </Actions>
        ),
      },
    ],
    []
  );

  const fetchServicesByProfessionalId = async () => {
    await api
      .get(`/services/?professional_id=${professional.id}`)
      .then((response) => {
        setServices(response.data);
      });
  };

  const handleAdd = async (service: any) => {
    try {
      const response = await api.post(`/services/`, {
        ...service,
        price: sanitizePrice(service.price),
        professional: professional.id,
      });
      setServices((prevServices: any) => [...prevServices, response.data]);
      setIsAdding(false);
    } catch (error) {
      console.error("Erro ao adicionar serviço:", error);
    }
  };

  const handleEdit = (service: any) => {
    setEditingService(service);
    setIsEditing(true);
  };

  const handleUpdate = async (updatedService: any) => {
    try {
      const response = await api.put(`/services/${updatedService.id}/`, {
        ...updatedService,
        price: sanitizePrice(updatedService.price),
        professional: professional.id,
      });
      setServices((prevServices: any) =>
        prevServices.map((service: any) =>
          service.id === updatedService.id ? response.data : service
        )
      );
      setIsEditing(false);
    } catch (error) {
      console.error("Erro ao atualizar serviço:", error);
    }
  };

  const handleDelete = async () => {
    if (serviceToDelete) {
      await api.delete(`/services/${serviceToDelete.id}/`).then(() => {
        fetchServicesByProfessionalId();
        setIsDeleteModalOpen(false);
      });
    }
  };

  const handleDeleteClick = (service: any) => {
    setServiceToDelete(service);
    setIsDeleteModalOpen(true);
  };

  useEffect(() => {
    fetchServicesByProfessionalId();
  }, []);

  return (
    <>
      <HeaderWrapper>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <HeaderTitle>Serviços</HeaderTitle>
        </div>
        <IoCloseOutline
          size={22}
          onClick={closeModal}
          color="#919EAB"
          cursor={"pointer"}
        />
      </HeaderWrapper>

      <ButtonWrapper>
        <CustomButton theme="add" onClick={() => setIsAdding(true)}>
          Cadastrar Novo Serviço
        </CustomButton>
      </ButtonWrapper>

      <TableContainer
        component={Paper}
        style={{ maxHeight: 400, overflowY: "auto" }}
      >
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "#1270FC" }}>
              {columns.map((column, index) => (
                <TableCell key={index} style={{ color: "#FFFFFF" }}>
                  {column.Header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((service: any) => (
              <TableRow key={service.id}>
                <TableCell>{service.name}</TableCell>
                <TableCell>{`R$ ${formatDecimalValues(
                  service.price
                )}`}</TableCell>
                <TableCell>{service.is_available ? "Sim" : "Não"}</TableCell>
                <TableCell>{`${service.clinica_commission}%`}</TableCell>
                <TableCell>
                  <Actions>
                    <FiEdit
                      size={18}
                      cursor="pointer"
                      onClick={() => handleEdit(service)}
                    />
                    <FiTrash
                      size={18}
                      cursor="pointer"
                      color="#e74c3c"
                      onClick={() => handleDeleteClick(service)}
                    />
                  </Actions>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isAdding && (
        <ServiceModal
          service={null}
          onClose={() => setIsAdding(false)}
          onSave={handleAdd}
        />
      )}

      {isEditing && (
        <ServiceModal
          service={editingService}
          onClose={() => setIsEditing(false)}
          onSave={handleUpdate}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={handleDelete}
        />
      )}
    </>
  );
};

const ServiceModal = ({ service, onClose, onSave }: any) => {
  const [modalServiceIsOpen, setModalServiceIsOpen] = useState(false);

  useEffect(() => {
    setModalServiceIsOpen(true);
  }, []);

  const [form, setForm] = useState({
    name: service?.name || "",
    price: service?.price * 100 || "",
    is_available: service?.is_available || true,
    clinica_commission: service?.clinica_commission || "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (name === "price") {
      const formatedValue = formatCurrency(value);
      setForm((prev) => ({ ...prev, [name]: formatedValue }));
      return;
    }

    if (name === "clinica_commission") {
      const formatedValue = formatPercentage(value);
      setForm((prev) => ({ ...prev, [name]: formatedValue }));
      return;
    }

    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, is_available: e.target.checked }));
  };

  const handleSubmit = () => {
    if (service) {
      onSave({ ...form, id: service.id });
    } else {
      onSave(form);
    }
    onClose();
  };

  const closeModalService = () => {
    setModalServiceIsOpen(false);
    onClose();
  };

  const fadeService = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalServiceIsOpen ? 1 : 0 },
  });

  return (
    <Modal
      isOpen={modalServiceIsOpen}
      onRequestClose={closeModalService}
      shouldCloseOnOverlayClick={false}
      style={customStyles}
    >
      <animated.div style={fadeService}>
        <StyledModalWrapper>
          <StyledModalTitle>
            {service ? "Editar Serviço" : "Cadastrar Serviço"}
          </StyledModalTitle>
          <StyledForm>
            <StyledInput
              type="text"
              name="name"
              placeholder="Nome"
              value={form.name}
              onChange={handleChange}
            />
            <StyledInput
              type="text"
              name="price"
              placeholder="Preço"
              value={formatCurrency(form.price.toString())}
              onChange={handleChange}
            />
            <StyledInput
              type="text"
              name="clinica_commission"
              placeholder="Comissão da Clínica"
              value={
                form.clinica_commission ? `${form.clinica_commission}%` : ""
              }
              onChange={handleChange}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5px",
              }}
            >
              <span>Ativo</span>
              <Switch
                checked={form.is_available}
                onChange={handleToggleChange}
                color="primary"
              />
            </div>
          </StyledForm>
          <StyledButtonWrapper>
            <StyledButtonPrimary onClick={handleSubmit}>
              Salvar
            </StyledButtonPrimary>
            <StyledButtonSecondary onClick={closeModalService}>
              Cancelar
            </StyledButtonSecondary>
          </StyledButtonWrapper>
        </StyledModalWrapper>
      </animated.div>
    </Modal>
  );
};

const DeleteConfirmationModal = ({ onClose, onConfirm }: any) => {
  const fadeService = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={false}
      style={customStyles}
    >
      <animated.div style={fadeService}>
        <ModalContent>
          <h3>Confirmar Exclusão</h3>
          <p>Você tem certeza de que deseja excluir este serviço?</p>
          <div>
            <button onClick={onConfirm}>Confirmar</button>
            <button onClick={onClose}>Cancelar</button>
          </div>
        </ModalContent>
      </animated.div>
    </Modal>
  );
};
