import ApexCharts from "apexcharts";
import { useEffect, useRef } from "react";
import { formatDecimalValues } from "../../../utils/formatDecimalValues";

interface SeriesProps {
  name: string;
  data: number[];
  type?: string; // Adicionado para suportar "bar" ou "line"
}

interface Profit {
  data: SeriesProps[];
  labelsExtern?: string[];
}

export const RevenueChart = ({ data, labelsExtern }: Profit) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const options = {
      series: data.map(item => ({
        name: item.name,
        type: item.type || "bar", // Define o tipo da série (bar ou line)
        data: item.data,
      })),
      chart: {
        height: 210,
        type: "line", // Base para gráfico misto, mas séries definem o tipo
        stacked: false, // Evita empilhamento em gráficos mistos
        zoom: {
          enabled: false,
        },
        defaultLocale: "br",
        locales: [
          {
            name: "br",
            options: {
              months: [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio", // Corrigido de "Junho" para "Maio"
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro",
              ],
              shortMonths: [
                "Jan",
                "Fev",
                "Mar",
                "Abr",
                "Mai",
                "Jun",
                "Jul",
                "Ago",
                "Set",
                "Out",
                "Nov",
                "Dez",
              ],
            },
          },
        ],
      },
      xaxis: {
        categories: labelsExtern,
        labels: {
          offsetX: -5,
          offsetY: 0,
        },
      },
      dataLabels: {
        enabled: false,
        formatter: (value: any) => "R$ " + formatDecimalValues(value.toFixed(2)),
      },
      colors: ["#1270FC", "#FF4560"], // Azul para barras, Vermelho para linha
      yaxis: {
        labels: {
          formatter: (value: any) => "R$ " + formatDecimalValues(value?.toFixed(2)),
        },
      },
      legend: {
        show: true,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
          borderRadius: 5,
          borderRadiusApplication: "end",
        },
      },
      stroke: {
        width: [0, 2], // 0 para barras, 2 para linha
        curve: "smooth", // Linha suave
      },
      tooltip: {
        y: {
          formatter: (value: any) => "R$ " + formatDecimalValues(value.toFixed(2)),
        },
      },
    };

    const chart = new ApexCharts(chartRef.current, options);
    chart.setLocale("br");
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data, labelsExtern]);

  return <div ref={chartRef} />;
};