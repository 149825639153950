import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 10px;
`;

export const Title = styled.h3`
  font-size: 1.2rem;
  color: #333;
`;

export const ButtonWrapperList = styled.div`
  display: flex;
  gap: 15px;
`;

export const ButtonWrapper = styled.div<{  disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: ${'#fff'};
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  border: 1px solid #1270FC;
`;

export const TitleButton = styled.span`
  font-size: 0.95rem;
  color: #333;
`;

export const ExpandButton = styled.span`
  font-size: 1.2rem;
  color: #333;
  cursor: pointer;
`;

export const SubItemList = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const SubItemWrapper = styled.div<{ isMarked: boolean; disabled?: boolean }>`
    padding: 5px;
    background-color: ${({ isMarked }) => (isMarked ? '#1270FC' : '#f0f0f0')};
    border: 1px solid;
    border-radius: 5px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
    transition: background-color 0.3s ease, opacity 0.3s ease;

    &:hover {
        background-color: ${({ isMarked, disabled }) => !disabled && (isMarked ? '#0a5bb5' : '#e0e0e0')};
    }
`;

export const SubItemTitle = styled.span<{ isMarked: boolean}>`
    font-size: 0.9rem;
    color: ${({ isMarked }) => (isMarked ? '#fff' : '#000')};
    transition: color 0.3s ease;
`;
