import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useEffect, useMemo, useState, useCallback } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { MdOutlineModeEdit } from "react-icons/md";
import { LifeLine } from "react-loading-indicators";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import CustomButton from "../../components/CustomButton";
import LabelTags from "../../components/LabelTags";
import PageStructure from "../../components/PageStructure";
import { IconWrapper, customStyles } from "../../components/Shared";
import { HeaderPage } from "../../components/Shared/HeaderPage";
import CreateUser from "../../modals/Patient/Create";
import UpdatePatient from "../../modals/Patient/Update";
import api from "../../service/api";
import { formatPhone } from "../../utils/formatPhone";
import {
  WrapperIcons,
  PaginationControls,
  WrapperTable,
} from "./style";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

interface PatientsResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: any[];
}

interface QueryParams {
  page: number;
  page_size: number;
  [key: string]: string | number | boolean | undefined;
}

const options = [
  "Nome",
  "CPF",
  "Desde de",
  "Telefone",
  "Email",
  "Tag",
  "Com Pendências",
];

export const PatientsPage = () => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [filterValue, setFilterValue] = useState("Nome");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [clientTags, setClientTags] = useState<any[]>([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [patient, setPatient] = useState({});
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: "asc" | "desc" }>({ key: "name", direction: "asc" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);

  const debouncedSearch = useCallback((value: string) => {
    const handler = setTimeout(() => {
      setSearchQuery(value);
      setCurrentPage(1);
    }, 1200);

    return () => clearTimeout(handler);
  }, []);

  const handleSearchChange = useCallback((value: string) => {
    debouncedSearch(value);
  }, [debouncedSearch]);

  const { data: patientsResponse, isLoading } = useQuery<PatientsResponse, Error, PatientsResponse, (string | number | Date | null | number)[]>({
    queryKey: ['patients', currentPage, searchQuery, filterValue, selectedDate, pageSize],
    queryFn: async (): Promise<PatientsResponse> => {
      const params: QueryParams = {
        page: currentPage,
        page_size: pageSize,
      };

      if (filterValue === "Desde de" && selectedDate) {
        params.created_at = selectedDate.toISOString().split('T')[0];
      } else if (filterValue === "Com Pendências") {
        params.has_pendency = searchQuery.toLowerCase() === 'true';
      } else if (searchQuery) {
        const filterMap = {
          "Nome": "name",
          "CPF": "document",
          "Telefone": "phone",
          "Email": "email",
          "Tag": "tag",
        };
        const backendFilter = filterMap[filterValue];
        if (backendFilter) {
          params[backendFilter] = searchQuery;
        }
      }

      console.log("Params enviados:", params);
      const response = await api.get("/clients", { params });
      return response.data as PatientsResponse;
    },
        // Forçando a aceitação de keepPreviousData (solução temporária)
    // @ts-ignore
    keepPreviousData: true,
  });

  const { data: tags } = useQuery({
    queryKey: ['tags'],
    queryFn: async () => {
      const response = await api.get("/tags/");
      return response.data;
    },
    staleTime: 60000,
  });

  useEffect(() => {
    if (patientsResponse?.results) {
      setFilteredData(patientsResponse.results);
    }
    if (tags) {
      setClientTags(tags);
    }
  }, [patientsResponse, tags]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery, filterValue, selectedDate, pageSize]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalIsOpen ? 1 : 0 },
  });

  const editFade = useSpring({
    from: { opacity: 0 },
    to: { opacity: showEditModal ? 1 : 0 },
  });

  const columns = useMemo(
    () => [
      { Header: "Nome", accessor: "name", sortable: true },
      { Header: "CPF", accessor: "document", sortable: true },
      {
        Header: "Paciente desde",
        accessor: "created_at",
        Cell: ({ value }) => (
          <span>{format(new Date(value), "dd/MM/yyyy HH:mm")}</span>
        ),
        sortable: true,
      },
      {
        Header: "Telefone",
        accessor: "phone",
        Cell: ({ value }) => (
          <span>{formatPhone(value.replace("+55", "")) || "Não informado"}</span>
        ),
        sortable: true,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => (
          <span>{value || "Não informado"}</span>
        ),
        sortable: true,
      },
      {
        Header: "Tag",
        accessor: "tag",
        Cell: (data) => (
          <LabelTags info={data.value.name} color={data.value.color} />
        ),
        sortable: false,
      },
      {
        Header: "Ações",
        accessor: "id",
        Cell: (data) => (
          <WrapperIcons>
            <IconWrapper
              onClick={() => {
                navigate("details", { state: { patient: { id: data.value } } });
              }}
            >
              <IoEyeOutline size={20} color="var(--primary-icon-color)" />
            </IconWrapper>
            <IconWrapper
              onClick={() => {
                setShowEditModal(true);
                setPatient({ id: data.value });
              }}
            >
              <MdOutlineModeEdit size={20} color="var(--primary-icon-color)" />
            </IconWrapper>
          </WrapperIcons>
        ),
      },
    ],
    []
  );

  const handleSort = (columnKey: string) => {
    const direction = sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: columnKey, direction });
    const sortedData = [...filteredData].sort((a, b) => {
      if (a[columnKey] < b[columnKey]) return direction === "asc" ? -1 : 1;
      if (a[columnKey] > b[columnKey]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setFilteredData(sortedData);
  };

  const totalPages = patientsResponse?.count ? Math.ceil((patientsResponse as PatientsResponse).count / pageSize) : 0;

  return (
    <>
      <PageStructure
        onSearchChange={handleSearchChange}
        onSelectChange={(e) => {
          setFilterValue(e);
          setCurrentPage(1);
        }}
        options={options}
        selectOption={filterValue}
        startDate={selectedDate}
        setStartDate={(date) => {
          setSelectedDate(date);
          setCurrentPage(1);
        }}
      >
        <div>
          <HeaderPage
            title=""
            buttonRight={
              <CustomButton onClick={() => setIsOpen(true)} theme="addPrimary" style={{ marginBottom: "20px" }}>
                Registrar pacientes
              </CustomButton>
            }
          />

          {isLoading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LifeLine color="#418DFD" size="medium" text="" textColor="" />
            </div>
          ) : (
            <WrapperTable>
              <TableContainer component={Paper} style={{ width: "100%", maxHeight: "100%", overflowY: "auto" }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#1270FC" }}>
                      {columns.map((column) => (
                        <TableCell
                          key={column.Header}
                          sx={{
                            color: "#FFFFFF",
                            fontWeight: "bold",
                            cursor: column.sortable ? "pointer" : "default",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            borderRight: "1px solid rgba(255, 255, 255, 0.2)",
                            "&:last-child": { borderRight: "none" },
                          }}
                          onClick={() => column.sortable && handleSort(column.accessor)}
                        >
                          <div style={{ display: "flex", alignItems: "center" }}>
                            {column.Header}
                            {column.sortable && (
                              sortConfig.key === column.accessor && sortConfig.direction === "asc" ? (
                                <FiChevronUp size={16} style={{ marginLeft: "8px" }} />
                              ) : sortConfig.key === column.accessor && sortConfig.direction === "desc" ? (
                                <FiChevronDown size={16} style={{ marginLeft: "8px" }} />
                              ) : null
                            )}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#E8ECEF",
                          "&:hover": {
                            backgroundColor: "#B3E5FC",
                            cursor: "pointer",
                          },
                        }}
                      >
                        {columns.map((column) => (
                          <TableCell
                            key={column.Header}
                            sx={{
                              borderRight: "1px solid #E0E0E0",
                              "&:last-child": { borderRight: "none" },
                            }}
                          >
                            {column.Cell ? column.Cell({ value: row[column.accessor] }) : row[column.accessor]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </WrapperTable>
          )}

          {!isLoading && patientsResponse && filteredData.length > 0 && (
            <PaginationControls>
              <CustomButton
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                theme="primary"
              >
                Anterior
              </CustomButton>
              <span>
                Página {currentPage} de {totalPages}
              </span>
              <CustomButton
                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
                theme="primary"
              >
                Próximo
              </CustomButton>
            </PaginationControls>
          )}
        </div>
      </PageStructure>

      <Modal
        isOpen={showEditModal}
        onRequestClose={closeEditModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={editFade}>
          <UpdatePatient
            closeModal={closeEditModal}
            updatePatients={() => {}}
            patient={patient}
          />
        </animated.div>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fade}>
          <CreateUser closeModal={closeModal} updateClients={() => {}} />
        </animated.div>
      </Modal>
    </>
  );
};

export default PatientsPage;