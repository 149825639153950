import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { MdCalendarToday, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

interface DatePickerProps {
  placeholder: string;
  selectedDate?: Date | null;
  onDateChange?: (date: Date | null) => void;
  minDate?: any
}

const DatePickerWrapper = styled.div`
  position: relative;
  width: 140px;
  font-family: Arial, sans-serif;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  font-size: 14px;
  border: 2px solid #636B74;
  border-radius: 10px;
  cursor: pointer;
  background-color: #ffffff;

  &:focus-within {
    border-color: #0056b3;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const DateInput = styled.input`
  border: none;
  outline: none;
  font-size: 14px;
  background: transparent;
  cursor: pointer;
  width: 75%;

  &::placeholder {
    color: #aaa;
  }
`;

const CalendarIcon = styled(MdCalendarToday)`
  font-size: 18px;
  width: 25%;
  color: #636B74;
`;

const Calendar = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 240px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: #007bff;
  color: white;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
`;

const ArrowButton = styled.button`
  background: transparent;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    color: #d9eaff;
  }
`;

const CalendarDaysHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  font-weight: bold;
  color: #555;
  padding: 6px 0;
  background-color: #f1f1f1;
  font-size: 12px;
`;

const CalendarDays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
  padding: 8px;
`;

const CalendarDay = styled.button<{disabled?: boolean}>`
  width: 100%;
  padding: 8px;
  font-size: 12px;
  background: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;

  &:hover {
    background: #007bff;
    color: white;
    transform: scale(1.1);
  }

  &:active {
    background: #0056b3;
  }
`;

const CustomDatePicker: React.FC<DatePickerProps> = ({
  placeholder,
  selectedDate,
  onDateChange,
  minDate
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const datePickerRef = useRef<HTMLDivElement>(null);

  const handleDateClick = (year: number, month: number, day: number) => {
    const newDate = new Date(year, month, day);
    if (onDateChange) {
      onDateChange(newDate);
    }
    setIsCalendarOpen(false);
  };

  const handlePreviousMonth = () => {
    setCurrentMonth((prev) => new Date(prev.getFullYear(), prev.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth((prev) => new Date(prev.getFullYear(), prev.getMonth() + 1, 1));
  };
  const renderCalendar = (
    minDate
  ) => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfWeek = new Date(year, month, 1).getDay();

    const days = [] as any[];
    for (let i = 0; i < firstDayOfWeek; i++) {
      days.push(<div key={`empty-${i}`} />);
    }
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const isDisabled = minDate && date <= minDate;
      days.push(
        <CalendarDay
          key={day}
          onClick={() => !isDisabled && handleDateClick(year, month, day)}
          disabled={!!isDisabled}
        >
          {day}
        </CalendarDay>
      );
    }

    const weekDays = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];

    return (
      <Calendar>
        <CalendarHeader>
          <ArrowButton onClick={handlePreviousMonth}>
            <MdKeyboardArrowLeft />
          </ArrowButton>
          {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
          <ArrowButton onClick={handleNextMonth}>
            <MdKeyboardArrowRight />
          </ArrowButton>
        </CalendarHeader>
        <CalendarDaysHeader>
          {weekDays.map((day, index) => (
            <div key={index}>{day}</div>
          ))}
        </CalendarDaysHeader>
        <CalendarDays>{days}</CalendarDays>
      </Calendar>
    );
  };

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DatePickerWrapper ref={datePickerRef}>
      <InputWrapper onClick={() => setIsCalendarOpen(!isCalendarOpen)}>
        <DateInput
          type="text"
          readOnly
          value={selectedDate ? formatDate(selectedDate) : ''}
          placeholder={placeholder}
        />
        <CalendarIcon />
      </InputWrapper>
      {isCalendarOpen && renderCalendar(minDate)}
    </DatePickerWrapper>
  );
};

export default CustomDatePicker;
