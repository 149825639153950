import * as S from "./styles";

interface ListOptions {
  title: string;
  value: string;
  icons?: any;
}

interface MultiOptionButtonListProps {
  listOptions: Array<ListOptions>;
  title?: string;
  setSelectedList: React.Dispatch<React.SetStateAction<string[]>>;
  selectedList: string[];
  disabled?: boolean;
  singleSelect?: boolean;
}

export const MultiOptionButtonList = ({
  listOptions,
  title,
  setSelectedList,
  selectedList = [],
  disabled,
  singleSelect = false,
}: MultiOptionButtonListProps) => {
  const handleMarkOption = (value: string) => {
    if (disabled) return;

    if (singleSelect) {
      setSelectedList([value]);
    } else {
      const index = selectedList.findIndex((element) => element === value);
      if (index === -1) {
        setSelectedList([...selectedList, value]);
      } else {
        setSelectedList(selectedList.filter((element) => element !== value));
      }
    }
  };

  return (
    <S.Wrapper>
      <S.TitleWrapper>
        <S.Title>{title}</S.Title>
      </S.TitleWrapper>
      <S.ButtonWrapperList>
        {listOptions.map((element) => (
          <S.ButtonWrapper
            key={element.value}
            isMarked={selectedList.includes(element.value)}
            disabled={disabled}
            onClick={() => handleMarkOption(element.value)}
          >
            <S.TitleButton>{element.title}</S.TitleButton>

            {element.icons}
          </S.ButtonWrapper>
        ))}
      </S.ButtonWrapperList>
    </S.Wrapper>
  );
};
