// UserSelect.js
import React from "react";
import { SelectWrapper, UserSelector, DropDownIcon } from "./style";
import { MdKeyboardArrowDown } from "react-icons/md";

interface CustomDropDownProps {
  options?: any[];
  customLabelWhenNoOptionSelected?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
}

const CustomDropDown: React.FC<CustomDropDownProps> = ({ options = [], onChange, customLabelWhenNoOptionSelected, value }) => {
  return (
    <SelectWrapper>
      <UserSelector onChange={onChange} value={value}>
        <option value="Todos">{customLabelWhenNoOptionSelected || 'Selecione'}</option>
        {options.map((option, index) => (
          <option key={index} value={option?.label ? index : option}>
            {option?.label || option}
          </option>
        ))}
      </UserSelector>
      <DropDownIcon>
        <MdKeyboardArrowDown size={20} />
      </DropDownIcon>
    </SelectWrapper>
  );
};

export default CustomDropDown;
