import styled from "styled-components";

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  padding: 15px 40px 120px 40px;
  background-color: #F6F6F6;
`;

export const CardContent = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
`;

export const ProfitContent = styled.div`
  margin-top: 20px;
  flex: 0 0 66.66%;
  min-width: 200px;
`;

export const QuantitiesContent = styled.div`
  margin-top: 20px;
  flex: 0 0 49%;
  min-width: 200px;
`;

export const ChartWrapper = styled.div`
  background-color: #ffffff;
  margin-top: 20px;
  padding: 10px;
  flex: 1;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 4px 6px -3px rgba(0, 0, 0, 0.18);

  @media (max-width: 1200px) {
    padding: 6px;
  }
`;

export const ChartRetentionWrapper = styled.div`
  background-color: #F2F6FF;
  margin-top: 10px;
  padding: 10px;
  flex: 1;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 4px 6px -3px rgba(0, 0, 0, 0.18);

  @media (max-width: 1200px) {
    padding: 6px;
  }
`;

export const SubTitle = styled.div`
  margin-top: 10px;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 700;
  color: "#444444";
`;

export const Label = styled.text`
  font-size: 14px;
  font-weight: 700;
  color: #B2B2B2;
`;

export const PatientContent = styled.div`
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  flex: 1;
  max-height: 400px;
  min-width: 150px;
  padding: 20px;
`;

export const BodyContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  flex: 1;
`;

export const SelectorWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
`;

export const Overlay = styled.div`
    position: fixed;
    top: 100px;
    left: 240px;
    width: calc(100% - 240px);
    height: calc(100% - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3); /* Changed to a dark color */
    z-index: 10;
`;

export const ListEmptyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`

export const ListEmptyLabel = styled.div`
    margin-top: 5px;
    color: #ccc;
`

export const LogoWrapper = styled.img`
    padding-top: 20px;
    width: 140px;
`