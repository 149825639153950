import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 100px);
  padding: 20px;
  background-color: #f6f6f6;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const FormWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

export const InlineFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
`;

export const VariablesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 10px;
`;

export const VariableButton = styled.button`
  background-color: #1270fc;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 40px;
  cursor: pointer;
  &:hover {
    background-color: #0a5bb5;
  }
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: #333;
`;

export const TableWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.thead`
  background-color: #1270fc;
  color: #fff;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;

export const TableBody = styled.tbody``;

export const customStyles = {
  overlay: {
    zIndex: 9000,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 16,
    transition: "all 0.3s ease",
    overflow: "visible",
  },
};

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderTitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #333;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const RowWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const MultiSelectWrapper = styled.div`
  .multi-select {
    width: 100%;
    border: 1px solid #919EAB52;
    border-radius: 4px;
    padding: 10px;
    background-color: #fff;
    color: #333;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;