import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { BiWorld } from "react-icons/bi";
import { BsPersonHeart } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import { FaHashtag } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { LuAtSign } from "react-icons/lu";
import { MdOutlineWorkOutline } from "react-icons/md";
import {
  PiIdentificationCardLight,
  PiPhone,
  PiUserCircleLight,
} from "react-icons/pi";
import { toast } from "react-toastify";
import AnonymousProfilePhoto from "../../../assets/userAnonymous.jpeg";
import CustomDropdown from "../../../components/CustomDropDown";
import CustomInput from "../../../components/CustomInput";
import ModalStepByStep from "../../../components/ModalStepByStep";
import api from "../../../service/api";
import formatCEP from "../../../utils/formatCEP";
import { formatDocument } from "../../../utils/formatDocument";
import { formatPhone } from "../../../utils/formatPhone";
import { validateCPF } from "../../../utils/validateCPF";
import { InfoWrapper } from "../../Event/Create/style";
import {
  ContentWrapper,
  FlexContainer,
  ProfileImageWrapper,
  WrapperInput,
} from "./style";

interface CreateProfessionalProps {
  closeModal: () => void;
  updateClients?: () => void;
  isBasicForm?: boolean;
}

export const CreateProfessional = ({
  closeModal,
  updateClients,
  isBasicForm = false,
}: CreateProfessionalProps) => {
  const [tags, setClientTags] = useState<any[]>([]);
  const [validationErros, setValidationErros] = useState<any[]>([]);

  const fetchClientTags = () => {
    api
      .get("/tags/")
      .then((resp) => {
        setClientTags(resp.data);
      })
      .catch(() => { });
  };

  useEffect(() => {
    fetchClientTags();
    fetchProfessionals();
  }, []);

  const [form, setForm] = useState<any>({
    name: "",
    document: "",
    email: "",
    phone: "",
    address: "",
    origin: "",
    ocupation: "",
    birthday: "",
    alias: "",
    cep: "",
    tag: null,
    professional: null,
    sex: "",
  });

  const genderOptions = [
    { id: "H", name: "Masculino" },
    { id: "M", name: "Feminino" },
    { id: "O", name: "Outros" },
  ];

  const steps = isBasicForm
    ? [{ stepTitle: "Informações Básicas" }]
    : [
      { stepTitle: "Informações Básicas" },
      { stepTitle: "Dados complementares" },
      { stepTitle: "Foto de Perfil" },
    ];

  const [professionalId, setProfessionalId] = useState<number>(2);
  const [stepActive, setStepActive] = useState(steps[0]);
  const [loading, setLoading] = useState(false);
  const [selectedTagName, setSelectedTagName] = useState("");
  const [professionals, setProfessionals] = useState<any[]>([]);
  const [selectedProfessional, setSelectedProfessional] = useState<any>({});
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [imagem, setImagem] = useState<any>(null);

  const originOptions = [
    { id: "instagram", name: "Instagram" },
    { id: "indication", name: "Indicação" },
    { id: "indication_renan", name: "Indicação Renan" },
    { id: "facebook", name: "Facebook" },
    { id: "whatsapp", name: "Whatsapp" },
  ];

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;

    if (name == "document") value = formatDocument(value);
    else if (name == "phone") value = formatPhone(value);
    else if (name == "cep") value = formatCEP(value);

    setForm((prevForm: any) => {
      const updatedForm = { ...prevForm, [name]: value };

      validateField(name, value);

      if (name === "cep" && value.length === 9) {
        fetchCEPData(value);
      }

      return updatedForm;
    });
  };

  const fetchCEPData = async (cep: string) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      if (response.ok) {
        const data = await response.json();
        setForm((prevForm: any) => ({
          ...prevForm,
          address: data.logradouro,
          bairro: data.bairro,
          localidade: data.localidade,
          uf: data.uf,
        }));
      } else {
        toast.error("Erro ao buscar informações do CEP");
      }
    } catch (error) {
      console.error("Erro ao buscar informações do CEP", error);
    }
  };

  const validateField = async (fieldName: any, fieldValue: any) => {
    try {
      if (fieldName === "document" && fieldValue.length === 14) {
        const response = await api.post(`/check-info/client/`, { document: fieldValue });
        if (response.data)
          if (response.data.document) {
            toast.error("CPF já cadastrado");
            setValidationErros((prevErrors) => [...prevErrors, "CPF já cadastrado"]);
          } else {
            setValidationErros((prevErrors) => prevErrors.filter((error) => error !== "CPF já cadastrado"));
          }
      }

      if (fieldName === "phone" && fieldValue.length === 16) {
        const response = await api.post(`/check-info/client/`, { phone: fieldValue });
        if (response.data)
          if (response.data.phone) {
            toast.error("Telefone já cadastrado");
            setValidationErros((prevErrors) => [...prevErrors, "Telefone já cadastrado"]);
          } else {
            setValidationErros((prevErrors) => prevErrors.filter((error) => error !== "Telefone já cadastrado"));
          }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const savePatient = () => {
    setLoading(true);

    if (isBasicForm) {
      const token = localStorage.getItem("bridges.token");

      if (token) {
        const tokenData = jwtDecode<any>(token);
        if (tokenData.professional_id != null) {
          setProfessionalId(tokenData.professional_id);
        }
      } else {
        console.error("Token não encontrado.");
      }
    }

    const formData = new FormData();
    formData.append("name", form["name"]);
    formData.append("document", form["document"]);
    formData.append("email", form["email"]);
    formData.append("phone", form["phone"]);
    formData.append("address", form["address"]);
    formData.append("origin", form["origin"]);
    formData.append("ocupation", form["ocupation"]);
    formData.append("birthday", form["birthday"]);
    formData.append("alias", form["alias"]);
    formData.append("cep", form["cep"]);
    formData.append("tag", isBasicForm ? 1 : form["tag"]);
    formData.append("professional", isBasicForm ? professionalId : form["professional"]);
    formData.append("sex", form["sex"]);

    if (imagem) formData.append("photo", selectedImage);

    api
      .post("/clients/", formData)
      .then(() => {
        setLoading(false);
        closeModal();
        toast.success("Paciente cadastrado com sucesso!");
        updateClients?.();
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao cadastrar o paciente!");
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const validateNextStep = () => {
    if (validationErros.length > 0) {
      for (const error of validationErros) {
        toast.error(error);
      }
      return false;
    }
    if (stepActive.stepTitle === "Informações Básicas") {
      if (form["name"].length < 3) {
        toast.error("Digite um nome válido");
        return false;
      } else if (form["phone"].replace(/\D/g, "").length !== 11) {
        toast.error("Digite um número de telefone válido");
        return false;
      } else if (!/\S+@\S+\.\S+/.test(form["email"])) {
        toast.error("Digite um e-mail válido");
        return false;
      } else if (!validateCPF(form["document"])) {
        toast.error("Digite um CPF válido");
        return false;
      }
    }

    if (stepActive.stepTitle === "Dados complementares") {
      return validateLastStep();
    }

    return true;
  };

  const validateLastStep = () => {
    if (form["birthday"] == "") {
      toast.error("Selecione uma data de nascimento.");
      return false;
    } else if (form["tag"] == null) {
      toast.error("Selecione uma tag para o paciente");
      return false;
    } else if (form["professional"] == null) {
      toast.error("Selecione um profissional para o paciente");
      return false;
    }

    return true;
  };

  const fetchProfessionals = () => {
    api.get(`/professionals/`).then((response) => {
      setProfessionals(response.data);
      setProfessionalId(response.data[0].id);
    });
  };

  const handleDropdownSelected = (key: any, value: any) => {
    setForm({ ...form, [key]: value });
  };

  const handleFileUpload = (event: any) => {
    const arquivo = event.target.files[0];

    const reader = new FileReader();
    setSelectedImage(arquivo);

    reader.onload = () => {
      const result = reader.result as any;
      setImagem(result);
    };

    reader.readAsDataURL(arquivo);
  };

  return (
    <ModalStepByStep
      steps={steps}
      isUpdateModal={false}
      onChangeStep={(step) => {
        setStepActive({ stepTitle: step.stepTitle });
      }}
      closeModal={closeModal}
      onConclude={() => {
        savePatient();
      }}
      validateNextStep={() => validateNextStep()}
      isLoadingRequest={loading}
    >
      <ContentWrapper>
        <InfoWrapper>
          {stepActive?.stepTitle == steps[0]?.stepTitle && (
            <>
              <WrapperInput>
                <CustomInput
                  disabled={loading}
                  value={form["name"]}
                  onChange={handleInputChange}
                  label="Nome"
                  name="name"
                  placeholder="Digite o nome do paciente"
                  leftIcon={
                    <PiUserCircleLight
                      size={20}
                      color="var(--primary-icon-color)"
                    />
                  }
                />
                <CustomInput
                  disabled={loading}
                  value={form["document"]}
                  onChange={handleInputChange}
                  label="CPF"
                  name="document"
                  placeholder="Digite o CPF do paciente"
                  leftIcon={
                    <PiIdentificationCardLight
                      size={20}
                      color="var(--primary-icon-color)"
                    />
                  }
                />
              </WrapperInput>
              <WrapperInput>
                <CustomInput
                  disabled={loading}
                  value={form["email"]}
                  onChange={handleInputChange}
                  label="E-mail"
                  name="email"
                  placeholder="Digite o email do paciente"
                  leftIcon={
                    <CiMail size={20} color="var(--primary-icon-color)" />
                  }
                />
                <CustomInput
                  disabled={loading}
                  value={form["phone"]}
                  onChange={handleInputChange}
                  label="Telefone"
                  name="phone"
                  placeholder="Digite o telefone do paciente"
                  leftIcon={
                    <PiPhone size={20} color="var(--primary-icon-color)" />
                  }
                />
              </WrapperInput>
              <WrapperInput>
                <CustomInput
                  disabled={loading}
                  value={form["alias"]}
                  onChange={handleInputChange}
                  label="Apelido"
                  name="alias"
                  placeholder="Como gostaria de ser chamado"
                  leftIcon={
                    <BsPersonHeart
                      size={20}
                      color="var(--primary-icon-color)"
                    />
                  }
                />
                <CustomDropdown
                  label="Sexo"
                  options={genderOptions}
                  selectedOption={
                    genderOptions.find((option) => option.id === form.sex)?.name || ""
                  }
                  setSelectedOption={(value: any) =>
                    handleDropdownSelected("sex", value.id)
                  }
                  leftIcon={
                    <HiOutlineUserCircle size={20} color="var(--primary-icon-color)" />
                  }
                />
                <CustomDropdown
                  label="Tag"
                  options={tags}
                  selectedOption={selectedTagName}
                  setSelectedOption={(value) => {
                    handleDropdownSelected("tag", value.id);
                    setSelectedTagName(value.name);
                  }}
                  leftIcon={
                    <FaHashtag size={16} color="var(--primary-icon-color)" />
                  }
                />
              </WrapperInput>
            </>
          )}
          {stepActive?.stepTitle == steps[1]?.stepTitle && (
            <>
              <WrapperInput>
                <CustomInput
                  disabled={loading}
                  onChange={handleInputChange}
                  value={form["address"]}
                  label="Endereço"
                  name="address"
                  placeholder="Digite o endereço do paciente"
                  leftIcon={
                    <GoLocation size={20} color="var(--primary-icon-color)" />
                  }
                />
                <CustomInput
                  disabled={loading}
                  onChange={handleInputChange}
                  value={form["cep"]}
                  label="CEP"
                  name="cep"
                  placeholder="Digite o CEP do paciente"
                  leftIcon={
                    <BiWorld size={20} color="var(--primary-icon-color)" />
                  }
                />
              </WrapperInput>
              <WrapperInput>
                <CustomInput
                  disabled={loading}
                  value={form["birthday"]}
                  type="date"
                  onChange={handleInputChange}
                  label="Data de nascimento"
                  name="birthday"
                  placeholder="Digite o telefone do paciente"
                  leftIcon={
                    <LiaBirthdayCakeSolid
                      size={20}
                      color="var(--primary-icon-color)"
                    />
                  }
                />
                <CustomDropdown
                  label="Origem"
                  options={originOptions}
                  selectedOption={
                    originOptions.find((option) => option.id === form.origin)?.name || ""
                  }
                  setSelectedOption={(value: any) =>
                    handleDropdownSelected("origin", value.id)
                  }
                  leftIcon={
                    <LuAtSign size={20} color="var(--primary-icon-color)" />
                  }
                />
              </WrapperInput>
              <WrapperInput>
                <CustomInput
                  disabled={loading}
                  value={form["ocupation"]}
                  onChange={handleInputChange}
                  label="Ocupação"
                  name="ocupation"
                  placeholder="Digite a ocupação do paciente"
                  leftIcon={
                    <MdOutlineWorkOutline
                      size={20}
                      color="var(--primary-icon-color)"
                    />
                  }
                />
              </WrapperInput>
              <WrapperInput>
                <CustomDropdown
                  label="Profissional"
                  setSelectedOption={(value: any) => {
                    handleDropdownSelected("professional", value.id);
                    setSelectedProfessional(value);
                  }}
                  selectedOption={
                    selectedProfessional?.name != null
                      ? selectedProfessional.name
                      : ""
                  }
                  options={professionals}
                  allowCreate={false}
                  onCreate={() => { }}
                  createLabel="Cadastrar profissional"
                  leftIcon={
                    <HiOutlineUserCircle
                      size={16}
                      color="var(--primary-icon-color)"
                    />
                  }
                />
              </WrapperInput>
            </>
          )}
          {stepActive?.stepTitle == steps[2]?.stepTitle && (
            <>
              <FlexContainer>
                <ProfileImageWrapper
                  src={imagem || AnonymousProfilePhoto}
                  alt="Foto de perfil"
                />
                <div style={{ marginLeft: "10px" }}>
                  <CustomInput
                    label="Escolha uma foto"
                    type="file"
                    disableBorder
                    onChange={handleFileUpload}
                  />
                </div>
              </FlexContainer>
            </>
          )}
        </InfoWrapper>
      </ContentWrapper>
    </ModalStepByStep>
  );
};

export default CreateProfessional;
