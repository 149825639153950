import styled from "styled-components";

const themes = {
    primary: {
        background: '#1270FC',
        width: 'auto',
        color: '#fff',        
        fontSize: '15px',
        border: null,
        borderRadius: '5px',
        padding: '10px 20px',
    },
    secondary: {
        background: '#fff',
        width: 'auto',
        color: '#000',
        fontSize: '15px',
        border: null,
        borderRadius: '5px',
        padding: '10px 20px'
    },
    success: {
        background: '#77ED8B',
        width: 'auto',
        color: '#02261E',
        fontSize: '15px',
        border: null,
        borderRadius: '5px',
        padding: '10px 20px'
    },
    cancel: {
        background: '#FFAC82',
        width: 'auto',
        color: '#B71D18',
        fontSize: '15px',
        border: null,
        borderRadius: '5px',
        padding: '10px 20px'
    },  
    add: {
        background: 'transparent',
        width: 'auto',
        color: '#1862B0',
        fontSize: '13px',
        border: '1px solid #1862B0',
        borderRadius: '4px',
        padding: '4px 20px'
    },
    addPrimary: {
        background: '#1270FC',
        width: 'auto',
        color: '#fff',        
        fontSize: '15px',
        fontWeight: '600',
        border: null,
        borderRadius: '5px',
        padding: '11px 16px'
    },
    register: {
        background: '#22C55E',
        color: '#02261E',        
        fontSize: '15px',
        fontWeight: '600',
        border: null,
        borderRadius: '5px',
        padding: '11px 16px',
        width: 'auto',
    },
    reschedule: {
        background: '#FFD666',
        width: 'auto',
        color: '#B76E00',        
        fontSize: '15px',
        border: null,
        borderRadius: '5px',
        padding: '10px 20px',
    },
    cancelDeleteAppointment: {
        background: '#FFAB00',
        width: 'auto',
        color: '#000',        
        fontSize: '15px',
        border: null,
        borderRadius: '5px',
        padding: '10px 20px',
    },
    deleteAppointment: {
        background: '#FF6B49',
        width: 'auto',
        color: '#580015',
        fontSize: '15px',
        border: null,
        borderRadius: '5px',
        padding: '10px 20px'
    },
    arrowLeft: {
        background: '#1270FC',
        width: 'auto',
        color: '#fff',        
        fontSize: '15px',
        fontWeight: '600',
        border: null,
        borderRadius: '5px',
        padding: '11px 16px'
    },
    arrowRight: {
        background: '#1270FC',
        width: 'auto',
        color: '#fff',        
        fontSize: '15px',
        fontWeight: '600',
        border: null,
        borderRadius: '5px',
        padding: '11px 16px'
    },
    update: {
        background: '#22C55E',
        color: '#fff',        
        fontSize: '15px',
        fontWeight: '600',
        border: null,
        borderRadius: '5px',
        padding: '11px 16px',
        width: 'auto',
    },
    pacientePronto: {
        background: '#22C55E',
        color: 'white',        
        fontSize: '15px',
        fontWeight: '600',
        border: null,
        borderRadius: '5px',
        padding: '11px 16px',
        width: 'auto',
    },
}
interface ButtonProps {
    theme: keyof typeof themes;
}



export const Button = styled.button<ButtonProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props: ButtonProps) => themes[props.theme].background};
    color: ${(props: ButtonProps) => themes[props.theme].color};
    font-size: ${(props: ButtonProps) => themes[props.theme].fontSize || '16px'};
    border: ${(props: ButtonProps) => themes[props.theme].border || 'none'};
    border-radius: ${(props: ButtonProps) => themes[props.theme].borderRadius || 'none'};
    padding: ${(props: ButtonProps) => themes[props.theme].padding || 'none'};
    outline: none;
    cursor: pointer;
    font-size: ${(props: ButtonProps) => themes[props.theme].fontSize || '16px'};
    font-weight: bold;
    /* margin: 10px; */
    transition: 0.3s;
    width: ${(props: ButtonProps) => themes[props.theme].width || 'auto'};
    gap: 4px;

    &:hover {
        opacity: 0.8;
    }
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;
