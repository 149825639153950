import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';
import styled from 'styled-components';

interface GenderChartProps {
    title: string;
    options: { name: string; value: number; color: string }[];
    disablePercentage?: boolean;
}

const GenderChart: React.FC<GenderChartProps> = ({ title, options, disablePercentage }) => {
    const chartRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const chartOptions = {
            series: options.map(option => option.value),
            chart: {
                type: 'donut',
                height: 300,
            },
            labels: options.map(option => option.name),
            colors: options.map(option => option.color),
            legend: {
                position: 'bottom',
                horizontalAlign: 'left',
            },
            dataLabels: {
                enabled: true,
                formatter: function (val: number, opts: any) {
                    return disablePercentage ? opts.w.config.series[opts.seriesIndex] : val.toFixed(1) + "%";
                },
            },
            tooltip: {
                y: {
                    formatter: function (val: number) {
                        return val;
                    },
                },
            },
        };

        const chart = new ApexCharts(chartRef.current, chartOptions);
        chart.render();

        return () => {
            chart.destroy();
        };
    }, [options]);

    return (
        <Card>
            <Title>{title}</Title>
            <ChartContainer ref={chartRef} />
        </Card>
    );
};

const Card = styled.div`
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
    width: 100%;
`;

const Title = styled.h3`
    margin: 0 0 16px 0;
    text-align: left;
`;

const ChartContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
`;

export default GenderChart;