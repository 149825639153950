import { ContentMoney } from "../../components/ContentMoney";
import PageStructure from "../../components/PageStructure";
import { SubTitle } from "../Control/style";
import {
  Button,
  ChartWrapper,
  DataHeaderWrapper,
  DataWrapper,
  DateTitle,
  DisplayWrapper,
  MovimentListWrapper,
  PageContent,
  SidebarWrapper,
  TextButton,
  ValuesContent,
  customStyles,
} from "./syle";

import { HistoryMoviment } from "../../components/HistoryMoviments";
import { useEffect, useRef, useState } from "react";
import { groupTransactionsByDate } from "../../utils/groupTransactionsByDate";
import { CreateTransactionHistory } from "../../modals/CreateTransactionHistory";
import Modal from "react-modal";
import { Profit } from "../../components/Charts/Profit";
import api from "../../service/api";
import { getFirstAndLastDayOfMonth } from "../../utils/getFirstAndLastDayOfMonth";
import { formatDecimalValues } from "../../utils/formatDecimalValues";
import CustomInput from "../../components/CustomInput";
import { HiMagnifyingGlass } from "react-icons/hi2";
import CustomDropdown from "../../components/CustomDropDown";
import { IoFilterOutline } from "react-icons/io5";
import { ListEmptyLabel, ListEmptyWrapper } from "../Today/style";
import { AiOutlineInbox } from "react-icons/ai";
import { FiArrowDown, FiArrowUp, FiClock } from "react-icons/fi";
import { PaymentMethod } from "../../modals/Event/PaymentEntry";

interface Transaction {
  id: number;
  title: string;
  type: string;
  mode: string;
  description: string;
  amountValue: string;
  date: string;
}

const options = [
  'Selecione...',
  'Similaridade',
  'Profissional',
  'Paciente',
  'Estabelecimento',
  'Serviço',
  'Observação',
  'Valor'
]

interface PaymentData {
  date: string;
  in: number;
  out: number;
  pend: number;
  payments: any[];
}

interface CondensedData {
  date: string[];
  in: number[];
  out: number[];
  pend: number[];
}

const monthNames = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];

function processPaymentData(data: PaymentData[]): CondensedData {
  const dates: string[] = [];
  const inValues: number[] = [];
  const outValues: number[] = [];
  const pendValues: number[] = [];

  if (data.length < 60) {
    data.forEach(d => {
      const [day, month, year] = d.date.split('/');
      const formattedDate = `${parseInt(day)} de ${monthNames[parseInt(month) - 1]}`;
      dates.push(formattedDate);
      inValues.push(d.in);
      outValues.push(d.out);
      pendValues.push(d.pend);
    });
  } else {
    const monthlyData: { [key: string]: { in: number; out: number; pend: number } } = {};

    data.forEach(d => {
      const [day, month, year] = d.date.split('/');
      const monthYear = `${month}/${year}`;

      if (!monthlyData[monthYear]) {
        monthlyData[monthYear] = { in: 0, out: 0, pend: 0 };
      }
      monthlyData[monthYear].in += d.in;
      monthlyData[monthYear].out += d.out;
      monthlyData[monthYear].pend += d.pend;
    });

    for (const monthYear in monthlyData) {
      const [month, year] = monthYear.split('/');
      const formattedMonthYear = `${monthNames[parseInt(month) - 1]}/${year}`;
      dates.push(formattedMonthYear);
      inValues.push(monthlyData[monthYear].in);
      outValues.push(monthlyData[monthYear].out);
      pendValues.push(monthlyData[monthYear].pend);
    }
  }

  return {
    date: dates,
    in: inValues,
    out: outValues,
    pend: pendValues
  };
};

export const Wallet = () => {
  const [transactionsByDate, setTransactionsByDate] = useState<{ [date: string]: Transaction[] }>({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sortChart, setSortChart] = useState("");
  const [receiveValue, setReceiveValue] = useState("");
  const [outValue, setOutValue] = useState("");
  const [pendencyValue, setPendencyValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [monthChartData, setMonthChartData] = useState<any[]>([]);
  const [labels, setLabels] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const paymentsBackup = useRef<any>(null);

  const options = ["Ontem", "Hoje", "Última Semana", "Último Mês"];
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);


  const lastRenderTimeRef = useRef<number>(Date.now());
  const [updatedAt, setUpdatedAt] = useState<string>("0s");

  const getElapsedTime = () => {
    const now = Date.now();

    const elapsedTime = (now - lastRenderTimeRef.current) / 1000;

    const minutes = Math.floor(elapsedTime / 60);
    const seconds = Math.floor(elapsedTime % 60);

    if (minutes === 0) {
      return `${seconds}s`;
    }

    return `${minutes}m`;
  };

  const fetchHistory = () => {
    let days = getFirstAndLastDayOfMonth();
    if (startDate) days.firstDay = startDate.toISOString().split('T')[0];
    if (endDate) days.lastDay = endDate.toISOString().split('T')[0];

    api.get(`/payments?start_date=${days.firstDay}&end_date=${days.lastDay}`).then((resp) => {
      const mappedReturn = groupTransactionsByDate(resp.data);
      paymentsBackup.current = mappedReturn || {};
      setTransactionsByDate(mappedReturn);
    });
  };

  useEffect(() => {
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);

    setEndDate(today);
    setStartDate(sevenDaysAgo);
  }, []);

  useEffect(() => {
    if (paymentsBackup.current) {
      handleFilter('');
    }

    lastRenderTimeRef.current = Date.now();

    fetchMonthData()
    fetchHistory()
  }, [startDate, endDate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setUpdatedAt(getElapsedTime());
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const fetchMonthData = () => {
    let days = getFirstAndLastDayOfMonth();
    if (startDate) days.firstDay = startDate.toISOString().split('T')[0];
    if (endDate) days.lastDay = endDate.toISOString().split('T')[0];

    api.get(`/dashboard/payments/${days.firstDay}/${days.lastDay}/`).then(async (resp) => {
      setReceiveValue(formatDecimalValues(resp.data.total_in));
      setOutValue(formatDecimalValues(resp.data.total_out));
      setPendencyValue(formatDecimalValues(resp.data.total_pend));

      const paymentData = processPaymentData(resp.data.results)

      const monthChartData = [
        { name: "Entradas", data: paymentData.in },
        { name: "Saidas", data: paymentData.out },
        { name: "Pendencias", data: paymentData.pend },
      ];

      setMonthChartData(monthChartData);
      setLabels(paymentData.date);
    });
  };

  const translateToKeyInObject = (option: string) => {
    switch (option) {
      case "Profissional":
        return "professional";
      case "Paciente":
        return "patient";
      case "Estabelecimento":
        return "establishment";
      case "Serviço":
        return "service";
      case "Observação":
        return "obs";
      case "Valor":
        return "value";
      default:
        return "";
    }
  };

  const containsSearchTerm = (obj: any, searchTerm: string, searchInto?: string): boolean => {
    for (let key in obj) {
      const keyToFilter = translateToKeyInObject(filterValue)!;

      if (filterValue !== "Similaridade" && key !== "appointment" && searchInto !== keyToFilter) {
        if (key !== keyToFilter) continue;
      }

      if (typeof obj[key] === "string" && obj[key].toLowerCase().includes(searchTerm.toLowerCase())) {
        return true;
      }

      if (typeof obj[key] === "object" && obj[key] !== null) {
        if (containsSearchTerm(obj[key], searchTerm, key)) {
          return true;
        }
      }
    }

    return false;
  };

  const handleFilter = (searchTerm: string) => {
    if (!paymentsBackup.current) return;
  
    if (!searchTerm && !startDate && !endDate && !filterValue) {
      setTransactionsByDate(paymentsBackup.current);
      fetchMonthData();
      return;
    }
  
    const filterModeId = filterValue 
      ? paymentMethods.find(method => method.name === filterValue)?.id || null
      : null;
  
    const filterBySearchTerm = (transaction: Transaction) => 
      searchTerm ? (transaction.title?.toLowerCase() || '').includes(searchTerm.toLowerCase()) : true;
  
    const filterByMode = (transaction: Transaction) => 
      filterModeId !== null ? String(transaction.mode) === String(filterModeId) : true;
  
    const filteredKeys = Object.keys(paymentsBackup.current).reduce((acc, key) => {
      const transactions = paymentsBackup.current[key].filter(transaction =>
        filterBySearchTerm(transaction) && filterByMode(transaction)
      );
  
      if (transactions.length > 0) acc[key] = transactions;
      return acc;
    }, {} as Record<string, Transaction[]>);
  
    setTransactionsByDate(filteredKeys);
    fetchMonthData();
  };
  
  useEffect(() => {
    handleFilter('');
  }, [filterValue]);
  
  const onSelectChangeFilter = (value: string) => {
    if (value === "Ontem") {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      setStartDate(yesterday);
      setEndDate(yesterday);
    } else if (value === "Hoje") {
      const today = new Date();
      setStartDate(today);
      setEndDate(today);
    } else if (value === "Última Semana") {
      const today = new Date();
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 7);
      setStartDate(sevenDaysAgo);
      setEndDate(today);
    } else if (value === "Último Mês") {
      const today = new Date();
      const lastMonth = new Date(today);
      lastMonth.setMonth(today.getMonth() - 1);
      setStartDate(lastMonth);
      setEndDate(today);
    } else {
      const today = new Date();
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 7);

      setEndDate(today);
      setStartDate(sevenDaysAgo);
    }
  };

  useEffect(() => {
    api
      .get("/paymentMethods")
      .then((response) => {
        const methods: PaymentMethod[] = response.data.map((method: any) => ({
          id: method.id,
          key: method.key,
          name: method.name,
          tax_percentage: method.tax_percentage,
          max_installments: method.max_installments,
        }));

        setPaymentMethods(methods);
      })
      .catch(() => {
        console.log("Erro ao carregar métodos de pagamento.");
      });
  }, []);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <CreateTransactionHistory
          closeModal={() => setModalIsOpen(false)}
          updateClients={() => { }}
          fetchFunction={fetchHistory}
          sortChart={sortChart}
        />
      </Modal>
      <PageStructure startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} options={options} onSelectChange={onSelectChangeFilter}>
        <PageContent>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '0 50px' }}>
            <Button
              style={{ alignSelf: 'flex-end', marginTop: '20px', marginBottom: '20px' }}
              onClick={() => setModalIsOpen(true)}
            >
              <TextButton>Solicitar Pagamento</TextButton>
            </Button>

            <DataHeaderWrapper>
              <DataWrapper>
                <ValuesContent>
                  <ContentMoney
                    title="Entradas"
                    icon={<FiArrowUp size={20} color="#fff" />}
                    amount={receiveValue}
                    updatedAt={updatedAt}
                    type="up"
                  />
                  <ContentMoney
                    title="Saídas"
                    icon={<FiArrowDown size={20} color="#fff" />}
                    amount={outValue}
                    updatedAt={updatedAt}
                    type="down"
                  />
                  <ContentMoney
                    title="Pendências"
                    icon={<FiClock size={20} color="#fff" />}
                    amount={pendencyValue}
                    updatedAt={updatedAt}
                    type="pending"
                  />
                </ValuesContent>

                <DisplayWrapper>
                  <ChartWrapper>
                    <SubTitle>Fluxo de Caixa</SubTitle>
                    <Profit data={monthChartData} labelsExtern={labels} />
                  </ChartWrapper>

                  <SidebarWrapper>
                    <SubTitle>Movimentações</SubTitle>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <CustomInput
                        onChange={(e) => handleFilter(e.currentTarget.value)}
                        name=""
                        disableBorder
                        placeholder="Pesquisar..."
                        leftIcon={
                          <HiMagnifyingGlass size={20} color="var(--primary-icon-color)" />
                        }
                        style={{ flex: 1 }}
                      />
                      <div style={{ minWidth: '200px', flex: 1 }}>
                        <CustomDropdown
                          disableBorder
                          setSelectedOption={(value) => setFilterValue(value)}
                          selectedOption={filterValue}
                          options={paymentMethods.map((method) => method.name)}
                          allowCreate={false}
                          leftIcon={
                            <IoFilterOutline size={16} color="var(--primary-icon-color)" />
                          }
                        />
                      </div>
                    </div>

                    <MovimentListWrapper>
                      {Object.keys(transactionsByDate).length ?
                        Object.keys(transactionsByDate).map((item) => (
                          <div key={item}>
                            <DateTitle>{item}</DateTitle>
                            <HistoryMoviment date={transactionsByDate[item]} />
                          </div>
                        )) :
                        <ListEmptyWrapper style={{ marginTop: '10px' }}>
                          <AiOutlineInbox style={{ fontSize: "24px", color: "#ccc" }} />
                          <ListEmptyLabel> Sem registros no período! </ListEmptyLabel>
                        </ListEmptyWrapper>
                      }
                    </MovimentListWrapper>
                  </SidebarWrapper>

                </DisplayWrapper>
              </DataWrapper>
            </DataHeaderWrapper>
          </div>
        </PageContent>
      </PageStructure>
    </>
  );
};
