import styled from "styled-components";

interface ModalWrapperProps {
    size?: 'default' | 'large' | 'small';
}

export const ModalWrapper = styled.div<ModalWrapperProps>`
    width: ${props => {
        switch (props.size) {
            case 'default':
                return '900px';
            case 'small':
                return '460px';
            default:
                return '1000px';
        }
    }};
    transition: all 0.3s ease-in-out;
`

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #E5E5E5;
`

export const HeaderTitle = styled.span`
    font-size: 20px;
    font-weight: 600;
`

export const HeaderSubtitle = styled.span`
    font-size: 14px;
    font-weight: 400;
    color: #252525;
`

export const ContentWrapper = styled.div`
    display: flex;
    gap: 16px;
    width: 100%;
    padding: 10px 0;
`

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 5;
`
export const AppointmentWrapper = styled.div`
    display: flex;
    gap: 16px;
    justify-content: space-between;
`

export const SeparatorBottom = styled.div`
    border-bottom: 1px solid #E5E5E5;
`

export const ObservationsWrapper = styled.div`

`

interface FooterWrapperProps {
    type?: 'space-between' | 'space-around' | 'space-evenly' | 'flex-end' | 'flex-start' | 'center';
}

export const FooterWrapper = styled.div<FooterWrapperProps>`
    padding-top: 10px;
    border-top: 1px solid #E5E5E5;
    display: flex;
    justify-content: ${props => props.type ? props.type : 'space-between'};
`

interface MenuItemProps {
    isActive: boolean;
}

export const ShiftButton = styled.div<MenuItemProps>`
    display: flex;
    gap: 5px;
    width: 80px;
    height: 15px;
    background-color: ${props => props.isActive ? '#1270FC' : 'transparent'};
    padding: 10px;
    border-radius: 6px;
    align-items: center;
    transition: .2s ease-in-out;
    cursor: pointer;
    
    font-size: 12px;

    text-align: center;
    justify-content: center;
    color: ${props => props.isActive ? 'white' : '#000'};

    border: 1px solid ${props => props.isActive ? '#d8dcfc' : '#E5E5E5'};
    
    &:hover {
        background-color: ${props => props.isActive ? '#1270FC' : '#d8dcfc'};
    }
`   
