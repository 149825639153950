import { useEffect, useMemo, useState } from "react";
import CustomTable from "../../components/CustomTable";
import PageStructure from "../../components/PageStructure";
import { HeaderPage } from "../../components/Shared/HeaderPage";
import * as S from "./styles";
import api from "../../service/api";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { format, differenceInDays } from "date-fns";
import { IoEyeOutline } from "react-icons/io5";
import { MdOutlineModeEdit } from "react-icons/md";
import { toast } from "react-toastify";
import Chart from "react-apexcharts";
import { Tooltip } from "react-tooltip";

interface AntropometriaRecord {
  date: string | number | Date;
  id: number;
  created_at: string;
  fat_free_mass: number | null;
  skeletal_muscle_mass: number | null;
  fat_mass: number | null;
  visceral_fat_level: number | null;
  pgc: number | null;
  obesity_grade: number | null;
  tmb: number | null;
}

const getLastVisitInfo = (historyData: any[]) => {
  if (historyData.length === 0) return "Primeira vez na clínica";

  const lastVisitDate = new Date(historyData[0].created_at);
  const daysSinceLastVisit = differenceInDays(new Date(), lastVisitDate);

  return `Última visita há ${daysSinceLastVisit} dias`;
};

export const MedicalRecordHistory = () => {
  const [antropometriaData, setAntropometriaData] = useState<AntropometriaRecord[]>([]);
  const [historyData, setHistoryData] = useState<any>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { patient } = location?.state as {
    patient?: any;
  };
  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "patient.name",
      },
      {
        Header: "Objetivo",
        accessor: "metrics.objective",
      },
      {
        Header: "Protocolo",
        accessor: "composition_body.protocol",
      },
      {
        Header: "Criado em",
        accessor: "created_at",
        Cell: ({ value }: { value: string }) => (
          <span>{format(new Date(value), "dd/MM/yyyy HH:mm")}</span>
        ),
      },
      {
        Header: "Atualizado em",
        accessor: "updated_at",
        Cell: ({ value }: { value: string }) => (
          <span>{format(new Date(value), "dd/MM/yyyy HH:mm")}</span>
        ),
      },
      {
        Header: "Profissional",
        accessor: "professional.name",
      },
      {
        Header: "Ações",
        accessor: "tag",
        Cell: (data: any) => (
          <S.WrapperIcons>
            <S.IconWrapper
              onClick={() => {
                navigate("/medicalRecord", {
                  state: {
                    patient: patient,
                    pageType: "view",
                    recordData: data.row.original,
                  },
                });
              }}
            >
              <IoEyeOutline size={20} color="var(--primary-icon-color)" />
            </S.IconWrapper>
            <S.IconWrapper
              onClick={() => {
                navigate("/medicalRecord", {
                  state: {
                    patient: patient,
                    pageType: "edit",
                    recordData: data.row.original,
                  },
                });
              }}
            >
              <MdOutlineModeEdit size={20} color="var(--primary-icon-color)" />
            </S.IconWrapper>
          </S.WrapperIcons>
        ),
      },
    ],
    []
  );

  const fetchPatientRecordHistory = async () => {
    const id = toast.loading("Carregando histórico..");
    await api
      .get(`/prontuarios/${patient.id}/historicos/`)
      .then((resp) => {
        setHistoryData(resp.data.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()));
        toast.update(id, {
          render: "Histórico carregado com sucesso!",
          type: "success",
          autoClose: 2000,
          isLoading: false,
        });
      })
      .catch(() => {
        toast.update(id, {
          render: "Houve uma falha ao carregar o histórico",
          type: "error",
          autoClose: 2000,
          isLoading: false,
        });
      });
  };

  const fetchAntropometriaData = async () => {
    try {
      const response = await api.get<AntropometriaRecord[]>(`/antropometria/${patient.id}/`);
      const sortedData = response.data
        .map((item) => ({
          ...item,
          date: new Date(item.date)
        }))
        .sort((a, b) => a.date.getTime() - b.date.getTime());

      setAntropometriaData(sortedData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPatientRecordHistory();
    fetchAntropometriaData();
  }, []);

  const getChartHeight = () => {
    if (window.innerHeight <= 600) {
      return "100px";
    } else if (window.innerHeight <= 800) {
      return "215px";
    } else {
      return "300px";
    }
  };

  const chartOptions = {
    chart: {
      type: 'bar' as const,
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: antropometriaData.map((data) => format(new Date(data.date), "dd/MM/yyyy")),
    },
    yaxis: {
      title: {
        text: '%',
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top' as const,
      horizontalAlign: 'left' as const,
    },
  };

  const chartSeries = [
    {
      name: '% de Gordura',
      data: antropometriaData.map((data) => data.fat_mass ?? 0),
    },
    {
      name: '% de Massa Magra',
      data: antropometriaData.map((data) => data.fat_free_mass ?? 0),
    },
    {
      name: '% de Massa Muscular Esquelética',
      data: antropometriaData.map((data) => data.skeletal_muscle_mass ?? 0),
    },
    {
      name: 'Nível de Gordura Visceral',
      data: antropometriaData.map((data) => data.visceral_fat_level ?? 0),
    },
  ];

  const lastVisitInfo = getLastVisitInfo(historyData);

  const consultationStatus = [
    { description: "O paciente não apresentou progresso.", color: "red" },
    { description: "O paciente ainda não atingiu a meta.", color: "orange" },
    { description: "O paciente alcançou a meta!", color: "green" },
  ];

  return (
    <PageStructure>
      <HeaderPage title="" />
      <S.Wrapper>
        <S.HeaderWrapper>
          <S.PatientHistoryWrapper>
            <S.ConsultationDotsWrapper>
              {consultationStatus.map((item, index) => (
                <div key={index}>
                  <S.ConsultationDot
                    key={index}
                    color={item.color}
                    data-tooltip-id={`tooltip-${index}`}
                    data-tooltip-content={item.description}
                  />
                  <Tooltip id={`tooltip-${index}`} place="top" />
                </div>
              ))}
            </S.ConsultationDotsWrapper>
            <S.LastVisitInfo>{lastVisitInfo}</S.LastVisitInfo>
          </S.PatientHistoryWrapper>
          <S.WrapperHeaderTable>
            <CustomButton
              onClick={() =>
                navigate("/medicalRecord", {
                  state: {
                    patient: patient,
                    pageType: "create",
                  },
                })
              }
              theme="addPrimary"
            >
              Adicionar
            </CustomButton>
          </S.WrapperHeaderTable>
        </S.HeaderWrapper>
        <S.ChartCard>
          <Chart options={chartOptions} series={chartSeries} type="bar" height={getChartHeight()} />
        </S.ChartCard>
        <S.TableWrapper>
          <CustomTable
            columns={columns}
            columnMinWidths={{}}
            data={historyData}
            emptyText="Nenhum dado registrado"
          />
        </S.TableWrapper>
      </S.Wrapper>
    </PageStructure>
  );
};
