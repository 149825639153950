import {
  ActionTitle,
  AmountWrapper,
  Content,
  DateWrapper,
  DebitAmountWrapper,
  LeftWrapper,
  MainWrapper,
  PaymentMethod,
  PaymentMethodWrapper,
  PaymentTitle,
  PaymentSubTitle,
  RightWrapper,
  ValueWrapper,
  WrapperImage,
  WrapperInfos,
  CardWrapper,
  AttachmentIcon,
  ModalRow,
  TruncatedTitle,
  TitleWrapper,
  PaymentLink,
} from "./style";
import ReciveImg from "../../assets/money-recive.svg";
import SendImg from "../../assets/money-send.svg";
import PendencyMoney from "../../assets/pendency-money.svg";
import { formatDate } from "../../utils/formatDate";
import { formatDecimalValues } from "../../utils/formatDecimalValues";
import { useEffect, useState } from "react";
import api from "../../service/api";
import { PaymentMethod as PaymentMethodType } from "../../modals/Event/PaymentEntry";
import Modal from "react-modal";
import { IoCloseOutline } from "react-icons/io5";
import { FiPaperclip, FiLink } from "react-icons/fi";
import { Tooltip } from "react-tooltip";

interface HistoryMoviment {
  date: any;
}

export const HistoryMoviment = ({ date }: HistoryMoviment) => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodType[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<any>(null);
  const [attachmentUrl, setAttachmentUrl] = useState<string | null>(null);

  useEffect(() => {
    api
      .get("/paymentMethods")
      .then((response) => {
        const methods: PaymentMethodType[] = response.data.map((method: any) => ({
          id: method.id,
          key: method.key,
          name: method.name,
          tax_percentage: method.tax_percentage,
          max_installments: method.max_installments,
        }));

        setPaymentMethods(methods);
      })
      .catch(() => {
        console.log("Erro ao carregar métodos de pagamento.");
      });
  }, []);

  const getPaymentMethodName = (key: number) => {
    const method = paymentMethods.find((method) => method.id === key);
    return method ? method.name : key;
  };

  const formatTransactionDate = (date: string) => {
    const splitedDateString = date.split("T");
    const formatBar = formatDate(splitedDateString[0]);

    return formatBar + " às " + splitedDateString[1];
  };

  const capitalizeWords = (str: string) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const openModal = async (transaction: any) => {
    setSelectedTransaction(transaction);
    setModalIsOpen(true);

    try {
      const response = await api.get(`/files/payment/?payment=${transaction.id}`,);
      if (response.data.length > 0) {
        setAttachmentUrl(response.data[0].file_url);
      } else {
        setAttachmentUrl(null);
      }
    } catch (error) {
      console.log("Erro ao buscar arquivos de pagamento.");
      setAttachmentUrl(null);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedTransaction(null);
    setAttachmentUrl(null);
  };

  const viewAttachment = () => {
    if (attachmentUrl) {
      const path = attachmentUrl.split("payment_files/")[1];

      const newUrl = `https://clinicahml-bkt.bcnjsa.com/payment_files/${path}`;

      window.open(newUrl, "_blank");
    }
  };

  return (
    <MainWrapper>
      {date.map((item: any) => (
        <CardWrapper key={item.id} onClick={() => openModal(item)}>
          <Content>
            <LeftWrapper>
              <WrapperImage>
                {item.type == "IN" && <img src={ReciveImg} />}
                {item.type == "OUT" && <img src={SendImg} />}
                {item.type == "PEND" && <img src={PendencyMoney} />}
              </WrapperImage>
              <WrapperInfos>
                <Tooltip id={`tooltip-${item.id}`} place="top" />
                <ActionTitle data-tooltip-id={`tooltip-${item.id}`} data-tooltip-content={item.title || (
                  "#" +
                  item.appointment.id +
                  " " +
                  item.appointment.service?.name +
                  " - " +
                  item.appointment.patient.name +
                  " - " +
                  item.appointment.professional.name)}>
                  <TruncatedTitle>
                    {item.title || (
                      "#" +
                      item.appointment.id +
                      " " +
                      item.appointment.service?.name +
                      " - " +
                      item.appointment.patient.name +
                      " - " +
                      item.appointment.professional.name)}
                  </TruncatedTitle>
                </ActionTitle>
                <PaymentTitle>{item.obs}</PaymentTitle>
                <PaymentSubTitle>Recebido por {capitalizeWords(item?.created_by || 'Sem nome')}</PaymentSubTitle>
                <DateWrapper>{formatTransactionDate(item.date)}</DateWrapper>
              </WrapperInfos>
            </LeftWrapper>
            <RightWrapper>
              {item.type == "IN" && (
                <ValueWrapper>
                  <PaymentMethodWrapper>
                    <PaymentMethod>{getPaymentMethodName(item.mode)}</PaymentMethod>
                  </PaymentMethodWrapper>
                  <AmountWrapper>
                    R$ {formatDecimalValues(item.value)}
                  </AmountWrapper>
                </ValueWrapper>
              )}

              {item.type == "PEND" && (
                <ValueWrapper>
                  <PaymentMethodWrapper>
                    <PaymentMethod>{getPaymentMethodName(item.mode.name)}</PaymentMethod>
                  </PaymentMethodWrapper>
                  <AmountWrapper>
                    R$ {formatDecimalValues(item.value)}
                  </AmountWrapper>
                </ValueWrapper>
              )}

              {item.type == "OUT" && (
                <ValueWrapper>
                  <PaymentMethodWrapper>
                    <PaymentMethod>{getPaymentMethodName(item.mode.name)}</PaymentMethod>
                  </PaymentMethodWrapper>
                  <DebitAmountWrapper>
                    -R$ {formatDecimalValues(item.value)}
                  </DebitAmountWrapper>
                </ValueWrapper>
              )}
            </RightWrapper>
          </Content>
        </CardWrapper>
      ))}

      {selectedTransaction && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              zIndex: 1000,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              borderRadius: 16,
              width: "40%",
              maxHeight: "90vh",
              overflowY: "auto",
            },
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              {selectedTransaction.type == "IN" && <img src={ReciveImg} />}
              {selectedTransaction.type == "OUT" && <img src={SendImg} />}
              {selectedTransaction.type == "PEND" && <img src={PendencyMoney} />}
              <h2>Detalhes da Movimentação</h2>
            </div>
            <IoCloseOutline size={22} onClick={closeModal} color="#919EAB" cursor={"pointer"} />
          </div>
          <div style={{ marginTop: '20px' }}>
            <ModalRow>
              <Tooltip id={`tooltip-modal-${selectedTransaction?.id}`} place="top" />
              <TitleWrapper data-tooltip-id={`tooltip-modal-${selectedTransaction?.id}`} data-tooltip-content={selectedTransaction?.title || (
                `#${selectedTransaction?.appointment?.id || ""} ` +
                `${selectedTransaction?.appointment?.service?.name || "Serviço Indefinido"} - ` +
                `${selectedTransaction?.appointment?.patient?.name || "Paciente Indefinido"} - ` +
                `${selectedTransaction?.appointment?.professional?.name || "Profissional Indefinido"}`)}>
                <strong>Título:</strong>
                <TruncatedTitle>
                  {selectedTransaction?.title || (
                    `#${selectedTransaction?.appointment?.id || ""} ` +
                    `${selectedTransaction?.appointment?.service?.name || "Serviço Indefinido"} - ` +
                    `${selectedTransaction?.appointment?.patient?.name || "Paciente Indefinido"} - ` +
                    `${selectedTransaction?.appointment?.professional?.name || "Profissional Indefinido"}`)}
                </TruncatedTitle>
              </TitleWrapper>
              <p><strong>Valor:</strong> R$ {formatDecimalValues(selectedTransaction.value)}</p>
            </ModalRow>
            <ModalRow>
              <Tooltip id={`tooltip-modal-obs-${selectedTransaction?.id}`} place="top" />
              <TitleWrapper data-tooltip-id={`tooltip-modal-obs-${selectedTransaction?.id}`} data-tooltip-content={selectedTransaction.obs}>
                <strong>Descrição:</strong>
                <TruncatedTitle>{selectedTransaction.obs}</TruncatedTitle>
              </TitleWrapper>
              <p><strong>Data:</strong> {formatTransactionDate(selectedTransaction.date)}</p>
            </ModalRow>
            <ModalRow>
              <p><strong>Método de Pagamento:</strong> {getPaymentMethodName(selectedTransaction.mode) === "Link de Pagamento" ? (
                <PaymentLink href={selectedTransaction.link_payment} target="_blank" rel="noopener noreferrer">
                  <FiLink size={16} color="#1270FC" />
                  Link de Pagamento
                </PaymentLink>
              ) : (
                getPaymentMethodName(selectedTransaction.mode)
              )}</p>
              <p><strong>Recebido por:</strong> {capitalizeWords(selectedTransaction?.created_by || 'Sem nome')}</p>
              {attachmentUrl && (
                <AttachmentIcon onClick={viewAttachment} style={{ textDecoration: 'none' }}>
                  <FiPaperclip size={20} color="#1270FC" />
                  <span>Visualizar Anexo</span>
                </AttachmentIcon>
              )}
            </ModalRow>
          </div>
        </Modal>
      )}
    </MainWrapper>
  );
};
