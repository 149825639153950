import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

interface FinancialItem {
  label: string;
  value: string;
}

interface FinancialSummaryProps {
  items: FinancialItem[];
}

const FinancialSummary = ({ items }: FinancialSummaryProps) => {
  const [loading, setLoading] = useState(true);
  const [isInfoVisible, setIsInfoVisible] = useState<boolean[]>([]);

  useEffect(() => {
    if (items.length > 0) {
      setLoading(false);
      setIsInfoVisible(new Array(items.length).fill(false));
    }
  }, [items]);

  const toggleVisibility = (index: number) => {
    setIsInfoVisible(prevState => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <SummaryContainer>
      {loading ? (
        <LoadingContainer>
          <LoadingMessage>Carregando...</LoadingMessage>
        </LoadingContainer>
      ) : (
        items.map((item, index) => (
          <ItemContainer key={index}>
            <div style={{display: 'flex', alignItems:'center', gap: '8px'}}>
              <Label>{item.label}</Label>
              <IoEyeOutline
                size={22}
                onClick={() => toggleVisibility(index)}
                color="#919EAB"
                cursor={"pointer"}
              />
            </div>
            <Value>{isInfoVisible[index] ? `R$ ${item.value}` : '----'}</Value>
          </ItemContainer>
        ))
      )}
    </SummaryContainer>
  );
};

const SummaryContainer = styled.div`
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 calc(33.33% - 0.5rem);
  min-width: 100px;
  padding: 0.5rem;
  border-radius: 8px;
`;

const Label = styled.span`
  color: #b2b2b2;
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
`;

const Value = styled.span`
  color: #444444;
  font-weight: 600;
  font-size: 0.9rem;
  text-align: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 50px; /* Ensure the component maintains the same height */
`;

const LoadingMessage = styled.div`
  font-size: 1rem;
  color: #b2b2b2;
`;

export default FinancialSummary;
