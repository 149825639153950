import styled from "styled-components";

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  overflow: auto;
  padding: 35px 40px 35px 40px;
  background-color: #F6F6F6;
`;

export const MainContent = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex: 1;
  max-height: 72%;

  @media (max-height: 800px) {
    max-height: 95%;
  }
`;

export const FinanceContent = styled.div`
  width: 66.66%;
  display: flex;
  flex-direction: column;
`;

export const CardContent = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
`;

export const ChartContent = styled.div`
  flex: 1;
  margin-top: 20px;
  background-color: white;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

export const ChartWrapper = styled.div`
  background-color: #F2F6FF;
  margin-top: 20px;
  padding: 20px;
  flex: 1;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 4px 6px -3px rgba(0, 0, 0, 0.18);

  @media (max-width: 1200px) {
    padding: 6px;
  }
`;

export const PatientContent = styled.div`
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  width: 33.33%;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export const SubTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const SubContent = styled.div`
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  padding: 40px;
  margin-top: 20px;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: #444444;	
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const SelectorWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const DateRange = styled.div`
  font-size: 12px;
  color: #888888;
  text-align: right;
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 5px;
  margin-right: 5px;
`;

export const customStyles = {
  overlay: {
    zIndex: 9000,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 16,
    transition: "all 0.3s ease",
    overflow: "visible",
  },
};

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    margin-top: 10px;
    border-bottom: 1px solid #E5E5E5;
`;

export const HeaderTitle = styled.span`
    font-size: 20px;
    font-weight: 600;
`;

export const Overlay = styled.div`
    position: fixed;
    top: 100px;
    left: 240px;
    width: calc(100% - 240px);
    height: calc(100% - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3); /* Changed to a dark color */
    z-index: 10;
`;