import Modal from "react-modal";
import api from "../../service/api";
import { useEffect, useMemo, useState } from "react";
import PageStructure from "../../components/PageStructure";
import { CardContent, ChartContent, ChartHeader, ChartWrapper, customStyles, DateRange, FinanceContent, HeaderContent, HeaderTitle, HeaderWrapper, MainContent, Overlay, PageContent, PatientContent, SelectorWrapper, SubContent, SubTitle, Title } from "./style";
import { formatDecimalValues } from "../../utils/formatDecimalValues";
import { FeaturedCard } from "./Components/FeaturedCard";
import { RevisionCard } from "../Overview/Components/RevisionCard";
import CustomButton from "../../components/CustomButton";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { IoCloseOutline, IoEyeOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { NewProfit } from "./Components/NewProfit";
import { RevenueChart } from "./Components/RevenueChart";
import CustomSelector from "./Components/CustomSelector";
import { animated, useSpring } from "react-spring";
import PatientProfile from "../Patients/Profile";
import { LifeLine } from "react-loading-indicators";
import GenderChart from "../Operational/Components/GenderChart";

export const Financial = () => {
    const navigate = useNavigate();
    const [labels, setLabels] = useState<any[]>([]);
    const [chartData, setChartData] = useState<any[]>([]);
    const [professionals, setProfessionals] = useState<any[]>([]);
    const [selectedProfessional, setSelectedProfessional] = useState<any>(null);
    const [elapsedTime, setElapsedTime] = useState<string>("0s");
    const [startTime, setStartTime] = useState<number>(Date.now());
    const [occupancyRate, setOccupancyRate] = useState<any>(null);
    const [realizedRevenue, setRealizedRevenue] = useState<any>(null);
    const [data, setData] = useState<any[]>([]);
    const [patientsLastMonth, setPatientsLastMonth] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [paymentMethods, setPaymentMethods] = useState<any>(null);

    const [labelsRevenue, setLabelsRevenue] = useState<any[]>([]);
    const [chartDataRevenue, setChartDataRevenue] = useState<any[]>([]);

    const [comissionLabels, setComisisonLabels] = useState<any[]>([]);
    const [chatDataComission, setChatDataComission] = useState<any[]>([]);

    const today = new Date();
    const last30Days = new Date();
    last30Days.setDate(today.getDate() - 30);
    const [startDate, setStartDate] = useState<Date | null>(last30Days);
    const [endDate, setEndDate] = useState<Date | null>(today);

    const [timeRange, setTimeRange] = useState<string>("week");
    const [comissionTimeRange, setComissionTimeRange] = useState<string>("weekly_commission");

    const rangeOptions = [
        { label: 'Diário', value: 'day' },
        { label: 'Semanal', value: 'week' },
        { label: 'Mensal', value: 'month' }
    ];

    const comissionRangeOptions = [
        { label: 'Semanal', value: 'weekly_commission' },
        { label: 'Mensal', value: 'monthly_commission' }
    ];

    const genderOptions = useMemo(() => [
        { name: 'Dinheiro', value: paymentMethods?.dinheiro ?? 0, color: '#FFD700' },
        { name: 'Débito', value: paymentMethods?.debit ?? 0, color: '#FF12A8' },
        { name: 'Crédito', value: paymentMethods?.crédito ?? 0, color: '#7C6C99' },
        { name: 'PIX', value: paymentMethods?.pix ?? 0, color: '#1270FC' },
        { name: 'Transferência', value: paymentMethods?.transferência ?? 0, color: '#32CD32' },
        { name: 'Link de Pagamento', value: paymentMethods?.link_de_pagamento ?? 0, color: '#FF4500' },
        { name: 'Consumo de Crédito', value: paymentMethods?.consumo_de_crédito ?? 0, color: '#8A2BE2' },
    ], [paymentMethods]);

    const [modalPersonDetail, setModalPersonDetail] = useState(false);

    const fadePersonDetail = useSpring({
        from: { opacity: 0 },
        to: { opacity: modalPersonDetail ? 1 : 0 },
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentTime = Date.now();
            const elapsedSeconds = Math.floor((currentTime - startTime) / 1000);

            if (elapsedSeconds < 60) {
                setElapsedTime(`${elapsedSeconds}s`);
            } else if (elapsedSeconds < 3600) {
                const minutes = Math.floor(elapsedSeconds / 60);
                setElapsedTime(`${minutes}m`);
            } else {
                const hours = Math.floor(elapsedSeconds / 3600);
                setElapsedTime(`${hours}h`);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [startTime]);

    useEffect(() => {
        setElapsedTime("0s");
        setStartTime(Date.now());
    }, [selectedProfessional, startDate, endDate]);

    const fetchAllData = async () => {
        try {
            const formattedStartDate = startDate?.toISOString().split('T')[0];
            const formattedEndDate = endDate?.toISOString().split('T')[0];

            const endDateForRetention = formattedEndDate ? new Date(formattedEndDate) : new Date();
            const startDateForRetention = new Date(endDateForRetention);
            startDateForRetention.setMonth(startDateForRetention.getMonth() - 5);
            const formattedStartDateRetention = startDateForRetention.toISOString().split('T')[0];

            const promises = [
                api.get('/professionals'),
                api.get("/patients/not-returned/"),
                api.get(`/reports/occupancy-rate/${formattedStartDate}/${formattedEndDate}/`),
                api.get(`/reports/new-patients-percentage/?start_date=${formattedStartDateRetention}&end_date=${formattedEndDate}`),
                api.get(`/reports/realized-revenue/${formattedStartDate}/${formattedEndDate}/`),
                api.get(`/reports/accumulated-revenue/?start_date=${formattedStartDate}&end_date=${formattedEndDate}&group_by=week`),
                api.get(`/reports/commission-payments/?start_date=${formattedStartDate}&end_date=${formattedEndDate}&${selectedProfessional?.id ? `professional_id=${selectedProfessional.id}` : ''}`),
                api.get(`/reports/payment-methods-distribution/?start_date=${formattedStartDate}&end_date=${formattedEndDate}&${selectedProfessional?.id ? `professional_id=${selectedProfessional.id}` : ''}`)
            ];                    

            const [
                professionalsRes,
                patientsNotReturnedRes,
                occupancyRateRes,
                newPatientsRes,
                realizedRevenueRes,
                accumulatedRevenueRes,
                comissionPaymentsRes,
                paymentMethodsRes,
            ] = await Promise.all(promises);

            setProfessionals(professionalsRes.data.map((p: any) => ({ id: p.id, name: p.name })));
            setData(patientsNotReturnedRes.data);
            setOccupancyRate(occupancyRateRes.data);
            setRealizedRevenue(realizedRevenueRes.data);

            const newPatientsData = newPatientsRes.data;
            const labels = newPatientsData.map((item: any) => {
                const [year, month] = item.month.split('-');
                const date = new Date(year, month - 1);
                let monthName = date.toLocaleString('default', { month: 'short' });
                monthName = monthName.replace('.', '');
                monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
                return `${monthName}/${year.slice(-2)}`;
            });

            setLabels(labels);
            setChartData([{ name: "Novos Pacientes (%)", data: newPatientsData.map((item: any) => item.new_patients_percentage) }]);
            setPatientsLastMonth(newPatientsData[5]);

            const accumulatedRevenueData = accumulatedRevenueRes.data.accumulated_revenue;
            const trendlineData = accumulatedRevenueRes.data.trendline; // Assumindo que o endpoint retorna trendline
            
            if (Array.isArray(accumulatedRevenueData)) {
                const labelsRevenue = accumulatedRevenueData.map((item: any) => {
                    if (timeRange === "week") {
                        const [weekStart, weekEnd] = [item.week_start, item.week_end];
                        const formattedWeekStart = new Date(weekStart).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' });
                        const formattedWeekEnd = new Date(weekEnd).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' });
                        return `${formattedWeekStart} - ${formattedWeekEnd}`;
                    } else if (timeRange === "month") {
                        const [year, month] = item.month_start.split('-');
                        const date = new Date(year, month - 1);
                        let monthName = date.toLocaleString('default', { month: 'short' });
                        monthName = monthName.replace('.', '');
                        monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
                        return `${monthName}/${year.slice(-2)}`;
                    } else {
                        const date = new Date(item.date);
                        return date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: '2-digit' });
                    }
                });

                setLabelsRevenue(labelsRevenue);
                setChartDataRevenue([
                    { name: "Receita Acumulada", data: accumulatedRevenueData.map((item: any) => item.revenue), type: "bar" },
                    { name: "Tendência", data: trendlineData.map((item: any) => item.value), type: "line" }
                ]);
            }

            const comissionChartData = comissionPaymentsRes.data;
            
            const labelsComission = comissionChartData[comissionTimeRange]?.map((item: any) => {
                if (comissionTimeRange === "weekly_commission") {
                    const [weekStart, weekEnd] = [item.week_start, item?.week_end];
                    const formattedWeekStart = new Date(weekStart).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' });
                    const formattedWeekEnd = new Date(weekEnd || '').toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' });
                    return `${formattedWeekStart} - ${formattedWeekEnd}`;
                } else {
                    const [year, month] = item.month_start.split('-');
                    const date = new Date(year, month - 1);
                    let monthName = date.toLocaleString('default', { month: 'short' });
                    monthName = monthName.replace('.', '');
                    monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
                    return `${monthName}/${year.slice(-2)}`;
                }
            });

            setComisisonLabels(labelsComission);
            setChatDataComission([{ name: "Comissão", data: comissionChartData[comissionTimeRange].map((item: any) => item.total) }]);

            const paymentMethodsData = paymentMethodsRes.data;
            const paymentMethodsMapped = paymentMethodsData.labels.reduce((acc: any, label: string, index: number) => {
                acc[label.toLowerCase().replace(/ /g, '_')] = paymentMethodsData.data[index];
                return acc;
            }, {});
            setPaymentMethods(paymentMethodsMapped);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAllData();
    }, []);

    const handleProfessionalSelect = (name: string) => {
        const professional = professionals.find((p: any) => p.name === name);
        setSelectedProfessional(professional);
    };

    const fetchOccupancyRate = async (startDate: string, endDate: string, professionalId?: number) => {
        try {
            const url = professionalId
                ? `/reports/occupancy-rate/${startDate}/${endDate}/${professionalId}/`
                : `/reports/occupancy-rate/${startDate}/${endDate}/`;
            const response = await api.get(url);
            setOccupancyRate(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchNewPatients = async (endDate: string, professionalId?: number) => {
        try {
            const end = new Date(endDate);
            const start = new Date(end);
            start.setMonth(start.getMonth() - 5);

            const formattedStartDate = start.toISOString().split('T')[0];
            const formattedEndDate = end.toISOString().split('T')[0];
            const url = professionalId
                ? `/reports/new-patients-percentage/?start_date=${formattedStartDate}&end_date=${formattedEndDate}&professional_id=${professionalId}`
                : `/reports/new-patients-percentage/?start_date=${formattedStartDate}&end_date=${formattedEndDate}`;

            const response = await api.get(url);
            const newPatientsData = response.data;

            const labels = newPatientsData.map((item: any) => {
                const [year, month] = item.month.split('-');
                const date = new Date(year, month - 1);
                let monthName = date.toLocaleString('default', { month: 'short' });
                monthName = monthName.replace('.', '');
                monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
                return `${monthName}/${year.slice(-2)}`;
            });

            setLabels(labels);
            setChartData([{ name: "Novos Pacientes (%)", data: newPatientsData.map((item: any) => item.new_patients_percentage) }]);
            setPatientsLastMonth(newPatientsData[5]);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchRealizedRevenue = async (startDate: string, endDate: string, professionalId?: number) => {
        try {
            const formattedStartDate = startDate.split('T')[0];
            const formattedEndDate = endDate.split('T')[0];
            const url = professionalId
                ? `/reports/realized-revenue/${formattedStartDate}/${formattedEndDate}/?professional_id=${professionalId}`
                : `/reports/realized-revenue/${formattedStartDate}/${formattedEndDate}/`;

            const response = await api.get(url);
            setRealizedRevenue(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchAccumulatedRevenue = async (startDate: string, endDate: string, professionalId?: number) => {
        try {
            const formattedStartDate = startDate.split('T')[0];
            const formattedEndDate = endDate.split('T')[0];

            const url = timeRange === 'week'
                ? `/reports/accumulated-revenue/?start_date=${formattedStartDate}&end_date=${formattedEndDate}${professionalId !== undefined ? `&professional_id=${professionalId}` : ''}&group_by=week`
                : timeRange === 'month'
                    ? `/reports/accumulated-revenue/?start_date=${formattedStartDate}&end_date=${formattedEndDate}${professionalId !== undefined ? `&professional_id=${professionalId}` : ''}&group_by=month`
                    : `/reports/accumulated-revenue/?start_date=${formattedStartDate}&end_date=${formattedEndDate}${professionalId !== undefined ? `&professional_id=${professionalId}` : ''}&group_by=day`;

            const response = await api.get(url);
            const accumulatedRevenueData = response.data.accumulated_revenue;
            const trendlineData = response.data.trendline; // Assumindo que o endpoint retorna trendline

            if (Array.isArray(accumulatedRevenueData)) {
                const labelsRevenue = accumulatedRevenueData.map((item: any) => {
                    const date = new Date(item.date);
                    if (timeRange === 'month') {
                        const [year, month] = item.month_start.split('-');
                        const date = new Date(year, month - 1);
                        let monthName = date.toLocaleString('default', { month: 'short' });
                        monthName = monthName.replace('.', '');
                        monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
                        return `${monthName}/${year.slice(-2)}`;
                    } else if (timeRange === 'week') {
                        const [weekStart, weekEnd] = [item.week_start, item.week_end];
                        const formattedWeekStart = new Date(weekStart).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' });
                        const formattedWeekEnd = new Date(weekEnd).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' });
                        return `${formattedWeekStart} - ${formattedWeekEnd}`;
                    } else {
                        return date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: '2-digit' });
                    }
                });

                setLabelsRevenue(labelsRevenue);
                setChartDataRevenue([
                    { name: "Receita Acumulada", data: accumulatedRevenueData.map((item: any) => item.revenue), type: "bar" },
                    { name: "Tendência", data: trendlineData.map((item: any) => item.value), type: "line" }
                ]);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (startDate && endDate) {
            fetchOccupancyRate(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0], selectedProfessional?.id);
            fetchRealizedRevenue(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0], selectedProfessional?.id);
            fetchAccumulatedRevenue(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0], selectedProfessional?.id);

            api.get(`/reports/payment-methods-distribution/?start_date=${startDate.toISOString().split('T')[0]}&end_date=${endDate.toISOString().split('T')[0]}&${selectedProfessional?.id ? `professional_id=${selectedProfessional.id}` : ''}`).then((paymentMethodsRes) => {
                const paymentMethodsData = paymentMethodsRes.data;
                const paymentMethodsMapped = paymentMethodsData.labels.reduce((acc: any, label: string, index: number) => {
                    acc[label.toLowerCase().replace(/ /g, '_')] = paymentMethodsData.data[index];
                    return acc;
                }, {});
                setPaymentMethods(paymentMethodsMapped);
            });

            api.get(`/reports/commission-payments/?start_date=${startDate.toISOString().split('T')[0]}&end_date=${endDate.toISOString().split('T')[0]}&${selectedProfessional?.id ? `professional_id=${selectedProfessional.id}` : ''}`).then((comissionPaymentsRes) => {
                const comissionChartData = comissionPaymentsRes.data;
            
                const labelsComission = comissionChartData[comissionTimeRange]?.map((item: any) => {
                    if (comissionTimeRange === "weekly_commission") {
                        console.log(item, 'teste');
                        const [weekStart, weekEnd] = [item.week_start, item?.week_end];
                        const formattedWeekStart = new Date(weekStart).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' });
                        const formattedWeekEnd = new Date(weekEnd).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' });
                        return `${formattedWeekStart} - ${formattedWeekEnd}`;
                    } else {
                        const [year, month] = item.month_start.split('-');
                        const date = new Date(year, month - 1);
                        let monthName = date.toLocaleString('default', { month: 'short' });
                        monthName = monthName.replace('.', '');
                        monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
                        return `${monthName}/${year.slice(-2)}`;
                    }
                });
    
                setComisisonLabels(labelsComission);
                setChatDataComission([{ name: "Comissão", data: comissionChartData[comissionTimeRange].map((item: any) => item.total) }]);
            });
        }
    }, [startDate, endDate, selectedProfessional, timeRange, comissionTimeRange]);

    useEffect(() => {
        const endDate = new Date();
        fetchNewPatients(endDate.toISOString().split('T')[0], selectedProfessional?.id);
    }, [selectedProfessional]);

    const closeModal = () => {
        setModalPersonDetail(false);
    };

    return (
        <>
            <PageStructure
                options={professionals.map((professional: any) => professional.name)}
                onSelectChange={handleProfessionalSelect}
                startDate={startDate} setStartDate={setStartDate}
                endDate={endDate} setEndDate={setEndDate}
            >
                {isLoading && (
                    <Overlay>
                        <LifeLine color="#418DFD" size="large" text="" textColor="" />
                    </Overlay>
                )}

                <PageContent>
                    <MainContent>
                        <FinanceContent>
                            <CardContent>
                                <FeaturedCard
                                    title={"% de ocupação"}
                                    value={occupancyRate ? occupancyRate.general_occupancy_rate : 0}
                                    format={"percent"}
                                    images={[""]}
                                    type={occupancyRate ? occupancyRate.percentage_difference > 0 ? "up" : "down" : "up"}
                                    percentage={occupancyRate ? `${formatDecimalValues(occupancyRate.percentage_difference)}%` : "0"}
                                    updatedAt={elapsedTime} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}
                                />
                                <FeaturedCard
                                    title={"Faturamento realizado"}
                                    value={realizedRevenue ? realizedRevenue.realized_revenue : 0}
                                    format={"currency"}
                                    images={[""]}
                                    type={realizedRevenue ? realizedRevenue?.percentage_difference > 0 ? "up" : "down" : "up"}
                                    percentage={realizedRevenue ? `${formatDecimalValues(realizedRevenue?.percentage_difference) === "NaN" ? "0" : formatDecimalValues(realizedRevenue?.percentage_difference)}%` : "0"}
                                    updatedAt={elapsedTime} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}
                                />
                            </CardContent>

                            <ChartContent>
                                <ChartHeader>
                                    <SubTitle>Faturamento Acumulado</SubTitle>
                                    <SelectorWrapper>
                                        <CustomSelector options={rangeOptions} selectedOption={timeRange} setSelectedOption={setTimeRange} />
                                    </SelectorWrapper>
                                </ChartHeader>
                                <RevenueChart data={chartDataRevenue} labelsExtern={labelsRevenue} />
                            </ChartContent>

                            <ChartContent>
                                <ChartHeader>
                                    <SubTitle>Pagamentos referente à comissão</SubTitle>
                                    <SelectorWrapper>
                                        <CustomSelector options={comissionRangeOptions} selectedOption={comissionTimeRange} setSelectedOption={setComissionTimeRange} />
                                    </SelectorWrapper>
                                </ChartHeader>
                                <RevenueChart data={chatDataComission} labelsExtern={comissionLabels} />
                            </ChartContent>
                        </FinanceContent>

                        <PatientContent>
                            <RevisionCard label="Pacientes novos" value={patientsLastMonth?.new_patients_percentage ?? 0} />

                            <ChartWrapper>
                                <SubTitle>% pacientes novos</SubTitle>
                                <NewProfit data={chartData} labelsExtern={labels} />
                            </ChartWrapper>

                            <div style={{ marginTop: '10px' }}>
                                <GenderChart title="Tipos de pagamento" disablePercentage options={genderOptions} />
                            </div>
                        </PatientContent>
                    </MainContent>
                </PageContent>
            </PageStructure>
            <Modal
                isOpen={modalPersonDetail}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={false}
                style={{
                    ...customStyles,
                    content: {
                        ...customStyles.content,
                        width: '80%'
                    }
                }}
            >
                <animated.div style={fadePersonDetail}>
                    <HeaderWrapper>
                        <HeaderTitle>Paciente</HeaderTitle>
                        <IoCloseOutline
                            size={22}
                            onClick={closeModal}
                            color="#919EAB"
                            cursor={"pointer"}
                        />
                    </HeaderWrapper>
                    <PatientProfile />
                </animated.div>
            </Modal>
        </>
    );
};