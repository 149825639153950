import { IoCloseOutline } from "react-icons/io5";
import CustomButton from "../../../components/CustomButton";
import api from "../../../service/api";
import { ContentWrapper, FooterWrapper, HeaderSubtitle, HeaderTitle, HeaderWrapper, InfoWrapper, ModalWrapper, ObservationsWrapper, ShiftButton } from "./style";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CustomInput from "../../../components/CustomInput";
import { FaRegCalendarCheck } from "react-icons/fa";
import { IoMdTime } from "react-icons/io";
import CustomTextArea from "../../../components/CustomTextArea";
import { jwtDecode } from "jwt-decode";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import { getProfessionalById } from "../../../service/integrations/ProfessionalService";

interface OcurrenceProps {
    closeModal: () => void;
    ocurrenceToShow ?: any;
}

export const Occurrence  = (props: OcurrenceProps) => {
    const [disableConfirmButton, setDisableConfirmButton] = useState(true)

    const [shiftSelected, setShiftSelected] = useState<string | null>(null);
    const [timeRange, setTimeRange] = useState({ start: "06:00", end: "07:00" });
    const [isFinalDateEnabled, setIsFinalDateEnabled] = useState(false);

    const [isViewMode, setIsViewMode] = useState(false);

    const [professional, setProfessional] = useState({} as any);

    const actualDate = new Date();
    const actualTime = new Date().toISOString().slice(11, 16);
    actualDate.setHours(parseInt(actualTime.split(":")[0]));
    actualDate.setMinutes(parseInt(actualTime.split(":")[1]));
    
    const [formData, setFormData] = useState<any>({
        time: new Date().toISOString().slice(11, 16),
        start_date: new Date().toISOString().slice(0, 10),
        start_time: actualDate.toISOString(),
        time_initial: new Date().toISOString().slice(11, 16),
        end_time: new Date(Date.now() + 3600000).toISOString().slice(11, 16),
        observation: '',
    });

    useEffect(() => {
        if(props?.ocurrenceToShow?.id){
            setIsViewMode(true);
            const { start, end, extendedProps } = props.ocurrenceToShow;
            setFormData({
                time: start.slice(11, 16),
                start_date: start.slice(0, 10),
                end_date: start !== end ? end.slice(0, 10) : start.slice(0, 10),
                start_time: start,
                time_initial: start.slice(11, 16),
                end_time: end.slice(11, 16),
                observation: extendedProps.justification,
            });

            getProfessionalById(props?.ocurrenceToShow?.extendedProps?.professional).then((response) => {
                setProfessional(response)
            })
        }
    }, [props?.ocurrenceToShow]);

    useEffect(() => {
        validateForm();
    }, [formData]);

    const validateForm = () => {
        const { start_date, time, end_time, observation } = formData;
        if (start_date && time && end_time && observation) {
            setDisableConfirmButton(false);
        } else {
            setDisableConfirmButton(true);
        }
    };

    const handleInputChange = (
    event:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        if(isViewMode) return;
        
        const { name, value } = event.target;
        if (name === "start_date") {
            let date = new Date();
            if(value != ''){
                date = new Date(value + "T00:00:00");
                date.setHours(actualDate.getHours());
                date.setMinutes(actualDate.getMinutes());
            }

            setFormData((prevFormData: any) => ({
                ...prevFormData,
                [name]: value,
                start_time: date?.toISOString() || '',
            }));

        } else if (name === "time") {
            const date = new Date(formData.start_date + "T00:00:00");
            const hour = value.split(":")[0];
            const minutes = value.split(":")[1];
            date.setHours(parseInt(hour));
            date.setMinutes(parseInt(minutes));
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                [name]: value,
                start_time: date?.toISOString() || '',
            }));
            setShiftSelected(null);
            setTimeRange({ ...timeRange, start: value });
        } else if (name === "end_time") {
            const date = new Date(formData.start_date + "T00:00:00");
            const hour = value.split(":")[0];
            const minutes = value.split(":")[1];
            date.setHours(parseInt(hour));
            date.setMinutes(parseInt(minutes));
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                [name]: value,
            }));
            setShiftSelected(null);
            setTimeRange({ ...timeRange, start: value });
        } else {
            setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value,
            }));
        }
    };

    const handleShiftClick = (shift: string) => {
        if (shiftSelected === shift) {
            setShiftSelected(null);
            setTimeRange({ start: "06:00", end: "23:59" });
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                time: "06:00",
                end_time: "23:59",
            }));
        } else {
            setShiftSelected(shift);
            if (shift === 'Manhã') {
                setTimeRange({ start: "06:00", end: "12:00" });
                setFormData((prevFormData: any) => ({
                    ...prevFormData,
                    time: "06:00",
                    end_time: "12:00",
                }));
            } else if (shift === 'Tarde') {
                setTimeRange({ start: "13:00", end: "18:00" });
                setFormData((prevFormData: any) => ({
                    ...prevFormData,
                    time: "13:00",
                    end_time: "18:00",
                }));
            } else if (shift === 'Noite') {
                setTimeRange({ start: "18:01", end: "23:59" });
                setFormData((prevFormData: any) => ({
                    ...prevFormData,
                    time: "18:01",
                    end_time: "23:59",
                }));
            }
        }
    };

    // function adicionarUmaHora(strHora: any) {
    //     const [hora, minuto] = strHora.split(':');
    //     const data = new Date();
    //     data.setHours(parseInt(hora, 10));
    //     data.setMinutes(parseInt(minuto, 10)); 
    //     data.setHours(data.getHours() + 1);
    //     const novaHora = `${String(data.getHours()).padStart(2, '0')}:${String(data.getMinutes()).padStart(2, '0')}`;
    //     return novaHora;
    // }

    const concludeRecorrence = async () => {
        const token = localStorage.getItem("bridges.token");

        const tokenData = jwtDecode<any>(token || '');

        const { start_date, end_date, time, end_time, observation } = formData;
        const period = shiftSelected ? shiftSelected : null;
        const specific_time = !shiftSelected ? { start: time, end: end_time } : null;

        if (!start_date || !observation || (!period && !specific_time)) {
            toast.error("Por favor, preencha todos os campos obrigatórios.");
            return;
        }

        if (new Date(end_date) < new Date(start_date)) {
            toast.error("A data final não pode ser anterior à data inicial.");
            return;
        }

        if (isFinalDateEnabled && !end_date) {
            toast.error("É necessário uma data final para o intervalo de datas.");
            return;
        }

        if (!isFinalDateEnabled || end_date === start_date) {
            const startDateTime = new Date(`${start_date}T${time}:00-03:00`);
            const endDateTime = new Date(`${start_date}T${end_time}:00-03:00`);
        
            if (isFinalDateEnabled && end_date) {
                const finalEndDateTime = new Date(`${end_date}T${end_time}:00-03:00`);
                if (finalEndDateTime <= startDateTime) {
                    toast.error("A data e hora final devem ser no futuro da inicial.");
                    return;
                }
            } else {
                if (endDateTime <= startDateTime) {
                    toast.error("A hora final deve ser no futuro da hora inicial.");
                    return;
                }
            }
        }

        try {
            const response = await api.post('/absence/', {
                professional: tokenData?.professional_id, 
                start_date: `${start_date}T${specific_time?.start+':00-03:00' || '00:00:00-03:00'}`,
                end_date: `${end_date ? end_date : start_date}T${specific_time?.end+':00-03:00' || '23:59:00-03:00'}`,
                justification: observation
            });

            toast.success("Ocorrência registrada com sucesso!");
            props.closeModal();
        } catch (error: any) {
            toast.error(error?.response?.data?.error || "Erro ao registrar a ocorrência. Tente novamente mais tarde.");
        }
    };

    return (
        <ModalWrapper size="large">
            <HeaderWrapper>
                <HeaderTitle>{isViewMode ? 'Visualizar' : 'Registrar' } Ocorrência</HeaderTitle>
                <IoCloseOutline size={22} onClick={props.closeModal} color="#919EAB" cursor={"pointer"} />
            </HeaderWrapper>
            <ContentWrapper>
                <div style={{width: '100%'}}>
                    <CustomInput
                        label="Data de ausência inicial"
                        name="start_date"
                        type="date"
                        disabled={isViewMode}
                        value={formData.start_date}
                        min={new Date().toISOString().split('T')[0]}
                        onChange={handleInputChange}
                        leftIcon={
                            <FaRegCalendarCheck
                            size={16}
                            color="var(--primary-icon-color)"
                            />
                        }
                    />

                { !isViewMode ? <label style={{ display: 'flex', alignItems: 'center', marginTop: '10px', cursor: 'pointer' }}>
                        <input
                            type="checkbox"
                            checked={isFinalDateEnabled}
                            onChange={() => setIsFinalDateEnabled(!isFinalDateEnabled)}
                            style={{cursor: 'pointer'}}
                        />
                        <span style={{ marginLeft: '8px' }}>Habilitar intervalo de datas</span>
                    </label> : null}
                </div>

                <CustomInput
                    label="Data de ausência final"
                    name="end_date"
                    type="date"
                    value={formData.end_date}
                    min={new Date().toISOString().split('T')[0]}
                    onChange={handleInputChange}
                    leftIcon={
                        <FaRegCalendarCheck
                        size={16}
                        color="var(--primary-icon-color)"
                        />
                    }
                    disabled={!isFinalDateEnabled || isViewMode}
                />
            </ContentWrapper>

            <ContentWrapper>
                <CustomInput
                    label="Hora Inicio"
                    name="time"
                    value={formData.time}
                    onChange={handleInputChange}
                    disabled={isViewMode}
                    placeholder="06:00"
                    type="time"
                    leftIcon={
                        <IoMdTime size={16} color="var(--primary-icon-color)" />
                    }
                />
                <CustomInput
                    label="Hora Fim"
                    name="end_time"
                    value={formData.end_time}
                    onChange={handleInputChange}
                    placeholder="06:00"
                    disabled={isViewMode}
                    type="time"
                    required
                    leftIcon={
                        <IoMdTime size={16} color="var(--primary-icon-color)" />
                    }
                min={new Date().toISOString().split('T')[0]}
                />
            </ContentWrapper>

            {      
                isViewMode ?     
                <CustomInput
                    label="Profissional"
                    value={professional?.name}
                    onChange={handleInputChange}
                    leftIcon={
                        <PeopleOutlinedIcon color="primary"/>
                    }
                    disabled={!isFinalDateEnabled || isViewMode}
                /> : null
            }

            { !isViewMode  ?      
                <div style={{width: '100%', display: 'flex', gap: '10px'}}>
                <HeaderSubtitle> Ou escolher os horários por turno </HeaderSubtitle>

                <div  style={{display: 'flex', gap: '10px', marginBottom: '10px'}}>
                    <ShiftButton
                        isActive={shiftSelected === 'Manhã'}
                        onClick={() => handleShiftClick('Manhã')}
                    >
                        Manhã
                    </ShiftButton>

                    <ShiftButton
                        isActive={shiftSelected === 'Tarde'}
                        onClick={() => handleShiftClick('Tarde')}
                    >
                        Tarde
                    </ShiftButton>
                    
                    <ShiftButton
                        isActive={shiftSelected === 'Noite'}
                        onClick={() => handleShiftClick('Noite')}
                    >
                        Noite
                    </ShiftButton>
                </div>
                </div> : null
            }

            <ContentWrapper>
                <CustomTextArea
                    label="Justificativa"
                    name="observation"
                    value={formData.observation}
                    disabled={isViewMode}
                    onChange={handleInputChange}
                    rows={5}
                    placeholder="Motivo da ausência"
                />
            </ContentWrapper>
            
          { !isViewMode ?<FooterWrapper>
                <CustomButton theme="cancelDeleteAppointment" onClick={props.closeModal}>Cancelar</CustomButton>
                <CustomButton theme="register" disabled={disableConfirmButton} onClick={() => {concludeRecorrence()}}> Justificar falta</CustomButton>
            </FooterWrapper> : null}
        </ModalWrapper>
    );
}

export default Occurrence ;