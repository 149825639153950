import styled from "styled-components";

export const SelectWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 0.65rem;
  display: flex;
  align-items: center;
  border: 1px solid #636B74;
  position: relative; 
`;

export const UserSelector = styled.select`
  border: none;
  background: transparent;
  outline: none;
  font-size: 13px;
  font-weight: 700;
  color: #636B74;
  padding-right: 20px; 
  padding-left: 8px; 
  appearance: none;
  width: 100%;
`;

export const DropDownIcon = styled.div`
  color: gray;
  position: absolute;
  right: 10px;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;
