import { format } from 'date-fns';
import { formatDate } from './formatDate';

interface Transaction {
    id: number;
    title: string;
    type: string;
    description: string;
    amountValue: string;
    date: string;
    mode: string;
}

export const groupTransactionsByDate = (transactions: Transaction[]): { [date: string]: Transaction[] } => {
    const groupedTransactions: { [date: string]: Transaction[] } = {};



    transactions?.forEach(transaction => {
        const date = transaction.date.substring(0, 10);

        if (format(new Date(transaction.date), 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd')) {
            groupedTransactions['Hoje'] = groupedTransactions['Hoje'] || [];
            groupedTransactions['Hoje'].unshift(transaction); // Adiciona ao início do array "Hoje"
        }
        else if (format(new Date(transaction.date), 'yyyy-MM-dd') === format(new Date(new Date().getTime() - 24 * 60 * 60 * 1000), 'yyyy-MM-dd')) {
            groupedTransactions['Ontem'] = groupedTransactions['Ontem'] || [];
            groupedTransactions['Ontem'].push(transaction);
        }
        else {
            const formattedDate = formatDate(date);
            groupedTransactions[formattedDate] = groupedTransactions[formattedDate] || [];
            groupedTransactions[formattedDate].push(transaction);
        }
    });

    const sortedGroupedTransactions: { [date: string]: Transaction[] } = {};
    if (groupedTransactions['Hoje']) {
        sortedGroupedTransactions['Hoje'] = groupedTransactions['Hoje'];
    }
    if (groupedTransactions['Ontem']) {
        sortedGroupedTransactions['Ontem'] = groupedTransactions['Ontem'];
    }
    for (const key in groupedTransactions) {
        if (key !== 'Hoje' && key !== 'Ontem') {
            sortedGroupedTransactions[key] = groupedTransactions[key];
        }
    }

    return sortedGroupedTransactions;
};