import { FiArrowUp, FiArrowDown, FiAlertCircle } from "react-icons/fi";
import {
  AmountContent,
  BottomContent,
  Content,
  HeaderContent,
  Title,
  ProgressBar,
  FooterContent,
  Percentage,
  TimeIndicator,
  ArrowIcon,
} from "./style";

interface ContentMoneyProps {
  title: string;
  icon: React.ReactNode;
  amount: string;
  percentage?: string;
  updatedAt?: string;
  type?: "up" | "down" | "pending"; // Adicionei "pending"
}

export const ContentMoney = ({
  title,
  icon,
  amount,
  percentage,
  updatedAt,
  type = "up",
}: ContentMoneyProps) => {
  
  const IndicatorIcon =
    type === "up"
      ? FiArrowUp
      : type === "down"
        ? FiArrowDown
        : FiArrowUp;

  return (
    <Content>
      <HeaderContent>
        <Title>{title}</Title>
        <ArrowIcon color={type === "up" ? "#00FF5599" : type === "down" ? "#FF000099" : "#FFD400"}>{icon}</ArrowIcon>
      </HeaderContent>
      <BottomContent>
        <AmountContent>R$ {amount}</AmountContent>
        <ProgressBar>
          <div className="filled"></div>
          <div className="empty"></div>
        </ProgressBar>
      </BottomContent>
      <FooterContent>
        {updatedAt && (
          <TimeIndicator>{`Atualizado há ${updatedAt}`}</TimeIndicator>
        )}
      </FooterContent>
    </Content>
  );
};
