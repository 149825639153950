import React from 'react';
import styled from 'styled-components';

interface PatientGoalsProps {
    title: string;
    goals: { label: string; value: number }[];
}

const PatientGoals: React.FC<PatientGoalsProps> = ({ title, goals }) => {
    return (
        <Card>
            <Title>{title}</Title>
            {goals.map((goal, index) => (
                <GoalContainer key={index}>
                    <GoalHeader>
                        <GoalLabel>{goal.label.charAt(0).toUpperCase() + goal.label.slice(1)}</GoalLabel>
                        <GoalValue>{goal.value}%</GoalValue>
                    </GoalHeader>
                    <ProgressBar>
                        <div className="filled" style={{ width: `${goal.value}%` }}></div>
                        <div className="empty"></div>
                    </ProgressBar>
                </GoalContainer>
            ))}
        </Card>
    );
};

const Card = styled.div`
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
    width: 100%;
`;

const Title = styled.h3`
    margin: 0 0 20px 0;
    text-align: left;
`;

const GoalContainer = styled.div`
    margin-bottom: 10px;
`;

const GoalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
`;

const GoalLabel = styled.div`
    font-weight: 700;
    color: #444444;
`;

const GoalValue = styled.div`
    font-weight: 700;
    color: #444444;
`;

const ProgressBar = styled.div`
    display: flex;
    height: 3px;
    margin-top: 5px;

    .filled {
        background-color: #007bff;
        border-radius: 2px;
    }

    .empty {
        background-color: #e0e0e0;
        flex: 1;
        border-radius: 2px;
    }
`;

export default PatientGoals;