import React from 'react';
import { InfoLabelWrapper, ProfessionalAlias, ProfessionalName, ProfileImage, Tooltip } from './style';

interface InfoLabelProps {
    professionalAlias?: string
    professionalName?: string
    profileImage: string;
}

export const InfoProfissional: React.FC<InfoLabelProps> = ({ profileImage, professionalName, professionalAlias }) => {
    const defaultImage = require("../../../../assets/user-profile.png");

    const truncateName = (name: string) => {
        return name.split(' ')[0]
    };
    
    return (
        <InfoLabelWrapper>
            <ProfileImage
                src={profileImage}
                alt="Perfil"
                onError={(e) => {
                    (e.currentTarget as HTMLImageElement).src = defaultImage;
                }}
            />
            {professionalAlias ? 
                <ProfessionalAlias>{professionalAlias}</ProfessionalAlias> : 
                <ProfessionalName data-tip={professionalName}>
                    {(truncateName(professionalName || ''))}
                    <Tooltip className='tooltip'>
                        {(professionalName || '')}
                    </Tooltip>
                </ProfessionalName>
            }
        </InfoLabelWrapper>
    );
};
