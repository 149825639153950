import { format } from "date-fns";
import { useEffect, useState } from "react";
import { IoEyeOutline } from "react-icons/io5";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { toast } from "react-toastify";
import CustomButton from "../../components/CustomButton";
import PageStructure from "../../components/PageStructure";
import { customStyles } from "../../components/Shared";
import { HeaderPage } from "../../components/Shared/HeaderPage";
import CreateProfessional from "../../modals/CreateProfessional";
import api from "../../service/api";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
} from "@mui/material";
import { GiBackwardTime } from "react-icons/gi";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export const ProfessionalsPage = () => {
  const navigate = useNavigate();

  const [data, setData] = useState<any>([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [editingInactiveDaysModalIsOpen, setEditingInactiveDaysModalIsOpen] = useState(false);
  const [professionalValue, setProfessionalValue] = useState("");
  const [editingInactiveDays, setEditingInactiveDays] = useState<any>({
    professionalId: null,
    inactiveDays: 0,
  });

  const [sortConfig, setSortConfig] = useState<{ key: string, direction: 'asc' | 'desc' }>({
    key: '',
    direction: 'asc',
  });

  const fetchProfessionals = () => {
    let toastLoading = toast.loading("Carregando profissionais...", {
      className: "toast-loading",
    });

    api
      .get("/professionals")
      .then((response) => {
        setData(response.data);
      })
      .catch(() => {
        toast.error("Erro ao carregar profissionais!");
      })
      .finally(() => {
        toast.dismiss(toastLoading);
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      let toastLoading = toast.loading("Carregando profissional...", {
        className: "toast-loading",
      });

      api
        .get(`/professionals/?name=${professionalValue}`)
        .then((response) => {
          setData(response.data);
        })
        .catch(() => {
          toast.error("Erro ao carregar profissional!");
        })
        .finally(() => {
          toast.dismiss(toastLoading);
        });
    }, 500);

    return () => clearTimeout(timeout);
  }, [professionalValue]);

  useEffect(() => {
    fetchProfessionals();
  }, []);

  const handleSort = (key: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setData(sortedData);
  };

  const renderSortableHeaderCell = (label: string, key: string) => (
    <TableCell
      sx={{
        color: "#FFFFFF",
        fontWeight: "bold",
        borderRight: "1px solid rgba(255, 255, 255, 0.2)",
        "&:last-child": { borderRight: "none" },
      }}
      onClick={() => handleSort(key)}
    >
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        {label}
        {sortConfig.key === key && (
          <span style={{ marginLeft: "5px", display: "inline-flex", alignItems: "center" }}>
            {sortConfig.direction === "asc" ? <FiChevronUp size={16} /> : <FiChevronDown size={16} />}
          </span>
        )}
      </div>
    </TableCell>
  );

  const closeModal = () => {
    setIsOpen(false);
  };

  const closeEditingInactiveDaysModal = () => {
    setEditingInactiveDaysModalIsOpen(false);
  };

  const openEditInactiveDays = (professional: any) => {
    setEditingInactiveDays({
      professionalId: professional.id,
      inactiveDays: professional.inactive_days || 0,
    });
    setEditingInactiveDaysModalIsOpen(true);
  };

  const handleSaveInactiveDays = () => {
    const { professionalId, inactiveDays } = editingInactiveDays;

    if (inactiveDays <= 0) {
      toast.error("O valor de dias inativos deve ser maior que zero.");
      return;
    }

    const professionalToUpdate = data.find((p: any) => p.id === professionalId);

    if (!professionalToUpdate) {
      toast.error("Profissional não encontrado!");
      return;
    }

    const updatedProfessional = { ...professionalToUpdate };
    delete updatedProfessional.photo;

    updatedProfessional.inactive_days = inactiveDays;

    api
      .put(`/professionals/${professionalId}/`, updatedProfessional)
      .then(() => {
        toast.success("Valor de dias inativos atualizado!");
        fetchProfessionals();
        closeEditingInactiveDaysModal();
      })
      .catch(() => {
        toast.error("Erro ao atualizar dias inativos.");
      });
  };

  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalIsOpen ? 1 : 0 },
  });

  return (
    <PageStructure onSearchChange={(e) => setProfessionalValue(e)}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fade}>
          <CreateProfessional
            closeModal={closeModal}
            updateClients={fetchProfessionals}
          />
        </animated.div>
      </Modal>

      <Modal
        isOpen={editingInactiveDaysModalIsOpen}
        onRequestClose={closeEditingInactiveDaysModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <div
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <h3>Período de Inatividade de Pacientes</h3>
          <TextField
            label="Dias Inativos"
            type="number"
            value={editingInactiveDays.inactiveDays}
            onChange={(e) =>
              setEditingInactiveDays({ ...editingInactiveDays, inactiveDays: Number(e.target.value) })
            }
            fullWidth
            style={{ marginBottom: "20px" }}
          />

          <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
            <CustomButton onClick={closeEditingInactiveDaysModal} theme="secondary">
              Cancelar
            </CustomButton>
            <CustomButton onClick={handleSaveInactiveDays} theme="update">
              Salvar
            </CustomButton>
          </div>
        </div>
      </Modal>

      <HeaderPage
        title=""
        buttonRight={
          <CustomButton
            onClick={() => setIsOpen(true)}
            theme="addPrimary"
            style={{ marginBottom: "20px" }}
          >
            Registrar Profissional
          </CustomButton>
        }
      />
      <TableContainer component={Paper} style={{ marginTop: "10px" }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#1270FC" }}>
              {renderSortableHeaderCell("Nome", "name")}
              {renderSortableHeaderCell("CPF", "document")}
              {renderSortableHeaderCell("Profissional desde", "created_at")}
              {renderSortableHeaderCell("Telefone", "phone")}
              {renderSortableHeaderCell("Email", "email")}
              <TableCell
                sx={{
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  borderRight: "1px solid rgba(255, 255, 255, 0.2)",
                  "&:last-child": { borderRight: "none" },
                }}
                align="center"
              >
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: any, index: number) => (
              <TableRow
                key={row.id}
                sx={{
                  backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#E8ECEF",
                  "&:hover": {
                    backgroundColor: "#B3E5FC",
                    cursor: "pointer",
                  },
                }}
              >
                <TableCell
                  sx={{
                    borderRight: "1px solid #E0E0E0",
                    "&:last-child": { borderRight: "none" },
                  }}
                >
                  {row.alias || row.name}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid #E0E0E0",
                    "&:last-child": { borderRight: "none" },
                  }}
                >
                  {row.document}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid #E0E0E0",
                    "&:last-child": { borderRight: "none" },
                  }}
                >
                  {format(new Date(row.created_at), "dd/MM/yyyy HH:mm")}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid #E0E0E0",
                    "&:last-child": { borderRight: "none" },
                  }}
                >
                  {row.phone}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid #E0E0E0",
                    "&:last-child": { borderRight: "none" },
                  }}
                >
                  {row.email}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    borderRight: "1px solid #E0E0E0",
                    "&:last-child": { borderRight: "none" },
                  }}
                >
                  <IconButton
                    onClick={() =>
                      navigate("details", {
                        state: { professional: row },
                      })
                    }
                  >
                    <IoEyeOutline size={20} color="var(--primary-icon-color)" />
                  </IconButton>
                  <IconButton onClick={() => openEditInactiveDays(row)}>
                    <GiBackwardTime size={20} color="var(--primary-icon-color)" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageStructure>
  );
};

export default ProfessionalsPage;