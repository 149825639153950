import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const WrapperHeaderTable = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const WrapperIcons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
`;

export const TableWrapper = styled.div`
  max-height: 50vh;
  overflow-y: auto;

  @media (max-height: 800px) {
    max-height: 40vh;
  }

  @media (max-height: 600px) {
    max-height: 30vh;
  }
`;

export const ChartCard = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  @media (max-height: 800px) {
    height: 30vh;
  }

  @media (max-height: 600px) {
    height: 20vh;
  }
`;

export const PatientHistoryWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

export const LastVisitInfo = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #333;
`;

export const ConsultationDotsWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const ConsultationDot = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
