import React, { useEffect, useState } from 'react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import styled from 'styled-components';
import CustomSelector from './CustomSelector';

interface FeaturedCardProps {
  title: string;
  value: string;
  images: string[];
  percentage?: string;
  updatedAt?: string;
  type?: "up" | "down" | "pending";
  format?: "percent" | "normal";
  startDate: Date | null;
  setStartDate: (date: Date | null) => void;
  endDate: Date | null;
  setEndDate: (date: Date | null) => void;
}

export const FeaturedCard: React.FC<FeaturedCardProps> = ({
  title,
  value,
  type,
  images,
  format,
  percentage,
  startDate,
  setStartDate,
  endDate,
  setEndDate
}) => {
  const [elapsedTime, setElapsedTime] = useState<string>("0s");
  const [startTime, setStartTime] = useState<number>(Date.now());

  const IndicatorIcon =
    type === "up"
      ? FiArrowUp
      : type === "down"
        ? FiArrowDown
        : FiArrowUp;

  const backgroundColor =
    type === "up"
      ? "#00FF5599"
      : type === "down"
        ? "#FF000099"
        : "#FFD400";

  const formattedValue = format === 'percent' ? (
    <>
      <Value>{value}</Value><Percent>%</Percent>
    </>
  ) : (
    <Value>{value}</Value>
  );

  const progressBarWidth = percentage ? `${100 + parseFloat(percentage)}%` : '0%';

  const rangeOptions = [
    { label: 'Semana', value: 'weekly' },
    { label: 'Mês', value: 'monthly' },
    { label: 'Ano', value: 'yearly' }
  ];

  const [timeRange, setTimeRange] = React.useState<string>("monthly");

  useEffect(() => {
    const today = new Date();
    setEndDate(today);

    if (timeRange === 'weekly') {
      const last7Days = new Date();
      last7Days.setDate(today.getDate() - 7);
      setStartDate(last7Days);
    } else if (timeRange === 'monthly') {
      const last30Days = new Date();
      last30Days.setDate(today.getDate() - 30);
      setStartDate(last30Days);
    } else if (timeRange === 'yearly') {
      const lastYear = new Date();
      lastYear.setFullYear(today.getFullYear() - 1);
      setStartDate(lastYear);
    }
  }, [timeRange, setStartDate, setEndDate]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      const elapsedSeconds = Math.floor((currentTime - startTime) / 1000);

      if (elapsedSeconds < 60) {
        setElapsedTime(`${elapsedSeconds}s`);
      } else if (elapsedSeconds < 3600) {
        const minutes = Math.floor(elapsedSeconds / 60);
        setElapsedTime(`${minutes}m`);
      } else {
        const hours = Math.floor(elapsedSeconds / 3600);
        setElapsedTime(`${hours}h`);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [startTime]);

  useEffect(() => {
    setElapsedTime("0s");
    setStartTime(Date.now());
  }, [startDate, endDate]);

  return (
    <Card>
      <Header>
        <Title>{title}</Title>
      </Header>
      {formattedValue}
      <ProgressBar>
        <div className="filled" style={{ width: progressBarWidth }}></div>
        <div className="empty"></div>
      </ProgressBar>

      <FooterContent>
        <Percentage style={{ backgroundColor }}>
          {percentage} <IndicatorIcon size={12} color="#fff" />
        </Percentage>
        {elapsedTime && (
          <TimeIndicator>{`Atualizado há ${elapsedTime}`}</TimeIndicator>
        )}
      </FooterContent>
    </Card>
  );
};

const Card = styled.div`
  background-color: white;
  max-height: 200px;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const Title = styled.text`
  font-weight: 700;
  color: #B2B2B2;
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
`;

const Value = styled.span`
  font-size: 60px;
  font-weight: 700;
  color: #444444;
`;

const Percent = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: #444444;
`;

const ProgressBar = styled.div`
  display: flex;
  height: 3px;
  margin-top: 5px;

  .filled {
    background-color: #007bff;
    border-radius: 2px;
  }

  .empty {
    background-color: #e0e0e0;
    flex: 1;
    border-radius: 2px;
  }
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

const Percentage = styled.div`
  background-color: #d4f8e8;
  color: #ffffff;
  padding: 4px 6px;
  border-radius: 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const TimeIndicator = styled.div`
  font-size: 10px;
  color: #ffffff;
  background-color: #1270FC99;
  padding: 4px 6px;
  border-radius: 12px;
`;

const CustomSelectorWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const DateRange = styled.div`
  font-size: 12px;
  color: #888888;
  text-align: right;
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 5px;
  margin-right: 5px;
`;
