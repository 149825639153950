import ApexCharts from "apexcharts";
import { useEffect, useRef } from "react";
import { formatDecimalValues } from "../../../utils/formatDecimalValues";

interface SeriesProps {
  name: string;
  data: number[];
}

interface Profit {
  data: SeriesProps[];
  labelsExtern?: string[];
}

export const NewProfit = ({ data, labelsExtern }: Profit) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const options = {
      series: data,
      chart: {
        type: "area",
        height: 300,
        zoom: {
          enabled: false,
        },
        defaultLocale: "br",
        locales: [
          {
            name: "br",
            options: {
              months: [
                "Janeiro", "Fevereiro", "Março", "Abril", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro",
              ],
              shortMonths: [
                "Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez",
              ],
            },
          },
        ],
      },
      xaxis: {
        labels: {
          offsetX: 0,
          offsetY: 0,
        },
      },
      dataLabels: {
        enabled: false,
        formatter: (value: any) => "R$ " + formatDecimalValues(value.toFixed(2)),
      },
      markers: {
        size: 5,
        colors: ["#1270FC", "#0D5AC4", "#1F8BFF"],
        strokeColor: "#000",
        strokeWidth: 1,
      },
      colors: ["#1270FC", "#0D5AC4", "#1F8BFF"],
      fill: {
        type: "solid",
        colors: ["transparent"],
      },
      stroke: {
        curve: "straight",
      },
      labels: labelsExtern,
      yaxis: {
        labels: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
    };

    const chart = new ApexCharts(chartRef.current, options);
    chart.setLocale("br");
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data, labelsExtern]);

  return <div ref={chartRef} />;
};
