import { useEffect, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import PageStructure from "../../../components/PageStructure";
import Modal from "react-modal";
import { animated, useSpring } from "react-spring";
import api from "../../../service/api";
import { toast } from "react-toastify";
import {
    Wrapper,
    FormWrapper,
    FieldWrapper,
    Label,
    ButtonWrapper,
    customStyles,
    HeaderWrapper,
    HeaderTitle,
    CloseButton,
    RowWrapper,
    BodyWrapper,
} from "./style";

import CustomDropdown from "../../../components/CustomDropDown";
import CustomDropDownMultiselect from "../../../components/CustomDropDownMultiselect";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Tooltip,
} from "@mui/material";
import { MdEdit, MdDelete } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import CustomInput from "../../../components/CustomInput";
import formatCurrency from "../../../utils/formatCurrencyBr";
import { formatDate } from "../../../utils/formatDate";

const ServicePackage = () => {
    interface Package {
        id: number;
        name: string;
        establishment: number;
        services: number[];
        quantity: number;
        validity_days: number;
        promotional_price: string;
        start_date: string;
        end_date: string;
    }

    const [packages, setPackages] = useState<Package[]>([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [name, setName] = useState("");
    const [establishment, setEstablishment] = useState<number | null>(null);
    const [services, setServices] = useState<any[]>([]);
    const [quantity, setQuantity] = useState<number>(0);
    const [validityDays, setValidityDays] = useState<number>(0);
    const [promotionalPrice, setPromotionalPrice] = useState<string>("");
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [editingPackage, setEditingPackage] = useState<Package | null>(null);
    const [deletingPackage, setDeletingPackage] = useState<Package | null>(null);
    const [establishments, setEstablishments] = useState<any[]>([]);
    const [serviceOptions, setServiceOptions] = useState<any[]>([]);

    const fetchPackages = async () => {
        try {
            const response = await api.get("/packages/");
            const packagesData = response.data.map((pkg: Package) => ({
                ...pkg,
                promotional_price: formatCurrency(pkg.promotional_price),
            }));
            setPackages(packagesData);
        } catch (error) {
            toast.error("Erro ao carregar pacotes");
        }
    };

    const fetchEstablishments = async () => {
        try {
            const response = await api.get("/establishments/");
            setEstablishments(response.data);
        } catch (error) {
            toast.error("Erro ao carregar estabelecimentos");
        }
    };

    const fetchServices = async () => {
        try {
            const response = await api.get("/services/");
            setServiceOptions(response.data.map((service: any) => ({ id: service.id, name: service.name })));
        } catch (error) {
            toast.error("Erro ao carregar serviços");
        }
    };

    useEffect(() => {
        fetchPackages();
        fetchEstablishments();
        fetchServices();
    }, []);

    const handleSave = async () => {
        if (!name || !establishment || services.length === 0 || !quantity || !validityDays || !promotionalPrice || !startDate || !endDate) {
            toast.error("Por favor, preencha todos os campos obrigatórios.");
            return;
        }

        try {
            const packageData = {
                name,
                establishment,
                services: services.map(service => service.id),
                quantity,
                validity_days: validityDays,
                promotional_price: (promotionalPrice.match(/\d+/g) || [])
                    .join(".")
                    .replace(/\.(?=\d{3,})/g, ""),
                start_date: startDate,
                end_date: endDate,
            };

            if (editingPackage) {
                await api.put(`/packages/${editingPackage.id}/`, packageData);
                toast.success("Pacote atualizado com sucesso!");
            } else {
                await api.post("/packages/", packageData);
                toast.success("Pacote cadastrado com sucesso!");
            }
            fetchPackages();
            closeModal();
        } catch (error) {
            toast.error("Erro ao salvar pacote");
        }
    };

    const handleDelete = async () => {
        try {
            if (deletingPackage) {
                await api.delete(`/packages/${deletingPackage.id}/`);
                toast.success("Pacote excluído com sucesso!");
                fetchPackages();
                closeDeleteModal();
            }
        } catch (error) {
            toast.error("Erro ao excluir pacote");
        }
    };

    const openModal = (packageData?: Package) => {
        if (packageData) {
            setEditingPackage(packageData);
            setName(packageData.name);
            setEstablishment(packageData.establishment);
            setServices(packageData.services.map(serviceId => serviceOptions.find(service => service.id === serviceId)));
            setQuantity(packageData.quantity);
            setValidityDays(packageData.validity_days);
            setPromotionalPrice(formatCurrency(packageData.promotional_price));
            setStartDate(packageData.start_date);
            setEndDate(packageData.end_date);
        } else {
            setEditingPackage(null);
            setName("");
            setEstablishment(null);
            setServices([]);
            setQuantity(0);
            setValidityDays(0);
            setPromotionalPrice("");
            setStartDate("");
            setEndDate("");
        }
        setIsOpen(true);
    };

    const openDeleteModal = (packageData: Package) => {
        setDeletingPackage(packageData);
        setDeleteModalIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const closeDeleteModal = () => {
        setDeleteModalIsOpen(false);
    };

    const fade = useSpring({
        from: { opacity: 0 },
        to: { opacity: modalIsOpen ? 1 : 0 },
    });

    const calculateModalHeight = () => {
        const baseHeight = 65; // Base height in percentage
        const additionalHeight = services.length * 0.5; // Additional height per service
        return `${baseHeight + additionalHeight}%`;
    };

    const handlePromotionalPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/\D/g, "");
        const formattedValue = formatCurrency(value);
        setPromotionalPrice(formattedValue);
    };

    return (
        <PageStructure>
            <Wrapper>
                <ButtonWrapper>
                    <CustomButton onClick={() => openModal()} theme={"addPrimary"}>
                        Cadastrar Novo Pacote
                    </CustomButton>
                </ButtonWrapper>
                <TableContainer component={Paper} style={{ marginTop: 20 }}>
                    <Table>
                        <TableHead>
                            <TableRow style={{ backgroundColor: "#1270FC" }}>
                                <TableCell style={{ color: "#FFFFFF", fontWeight: "bold", position: 'sticky', top: 0, zIndex: 1 }}>Nome</TableCell>
                                <TableCell style={{ color: "#FFFFFF", fontWeight: "bold", position: 'sticky', top: 0, zIndex: 1 }}>Estabelecimento</TableCell>
                                <TableCell style={{ color: "#FFFFFF", fontWeight: "bold", position: 'sticky', top: 0, zIndex: 1 }}>Serviços</TableCell>
                                <TableCell style={{ color: "#FFFFFF", fontWeight: "bold", position: 'sticky', top: 0, zIndex: 1 }}>Quantidade</TableCell>
                                <TableCell style={{ color: "#FFFFFF", fontWeight: "bold", position: 'sticky', top: 0, zIndex: 1 }}>Validade (dias)</TableCell>
                                <TableCell style={{ color: "#FFFFFF", fontWeight: "bold", position: 'sticky', top: 0, zIndex: 1 }}>Preço Promocional</TableCell>
                                <TableCell style={{ color: "#FFFFFF", fontWeight: "bold", position: 'sticky', top: 0, zIndex: 1 }}>Data de Início</TableCell>
                                <TableCell style={{ color: "#FFFFFF", fontWeight: "bold", position: 'sticky', top: 0, zIndex: 1 }}>Data de Fim</TableCell>
                                <TableCell style={{ color: "#FFFFFF", fontWeight: "bold", position: 'sticky', top: 0, zIndex: 1 }}>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {packages.map((packageData, index) => (
                                <TableRow key={index}>
                                    <TableCell>{packageData.name}</TableCell>
                                    <TableCell>{establishments.find(est => est.id === packageData.establishment)?.name}</TableCell>
                                    <TableCell>
                                        {packageData.services.length > 0 && (
                                            <Tooltip title={packageData.services.slice(1).map(serviceId => serviceOptions.find(service => service.id === serviceId)?.name).join(", ")}>
                                                <span>
                                                    {serviceOptions.find(service => service.id === packageData.services[0])?.name}
                                                    {packageData.services.length > 1 && " ..."}
                                                </span>
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                    <TableCell>{packageData.quantity}</TableCell>
                                    <TableCell>{packageData.validity_days}</TableCell>
                                    <TableCell>{formatCurrency(packageData.promotional_price)}</TableCell>
                                    <TableCell>{formatDate(packageData.start_date)}</TableCell>
                                    <TableCell>{formatDate(packageData.end_date)}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => openModal(packageData)}>
                                            <MdEdit />
                                        </IconButton>
                                        <IconButton onClick={() => openDeleteModal(packageData)}>
                                            <MdDelete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Wrapper>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={false}
                style={{
                    ...customStyles,
                    content: {
                        ...customStyles.content,
                        width: '80%',
                        height: calculateModalHeight(),
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: '90vh',
                        overflowY: 'auto',
                    },
                }}
            >
                <animated.div style={{ ...fade, display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <HeaderWrapper>
                        <HeaderTitle>{editingPackage ? "Editar Pacote" : "Criar Pacote"}</HeaderTitle>
                        <CloseButton onClick={closeModal}>
                            <IoCloseOutline size={22} color="#919EAB" />
                        </CloseButton>
                    </HeaderWrapper>
                    <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                        <div style={{ flex: 1, paddingRight: '20px' }}>
                            <FormWrapper>
                                <RowWrapper>
                                    <FieldWrapper>
                                        <Label>Nome</Label>
                                        <CustomInput
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="Digite o nome do pacote"
                                        />
                                    </FieldWrapper>
                                    <FieldWrapper>
                                        <Label>Estabelecimento</Label>
                                        <CustomDropdown
                                            options={establishments.map(est => ({ id: est.id, name: est.name }))}
                                            selectedOption={establishments.find(est => est.id === establishment)?.name || ""}
                                            setSelectedOption={(value) => setEstablishment(value.id)}
                                        />
                                    </FieldWrapper>
                                </RowWrapper>
                                <RowWrapper>
                                    <FieldWrapper>
                                        <Label>Serviços</Label>
                                        <CustomDropDownMultiselect
                                            options={serviceOptions}
                                            setSelectedOption={setServices}
                                            selectedOption={null}
                                            placeHolder={`${services.length > 0 ? "" : "Selecione um serviço"}`}
                                        />
                                    </FieldWrapper>
                                    <FieldWrapper>
                                        <Label>Quantidade</Label>
                                        <CustomInput
                                            type="number"
                                            value={quantity}
                                            onChange={(e) => setQuantity(Math.max(0, parseInt(e.target.value)))}
                                            placeholder="Digite a quantidade"
                                        />
                                    </FieldWrapper>
                                </RowWrapper>
                                <RowWrapper>
                                    <FieldWrapper>
                                        <Label>Validade (dias)</Label>
                                        <CustomInput
                                            type="number"
                                            value={validityDays}
                                            onChange={(e) => setValidityDays(Math.max(0, parseInt(e.target.value)))}
                                            placeholder="Digite a validade em dias"
                                        />
                                    </FieldWrapper>
                                    <FieldWrapper>
                                        <Label>Preço Promocional</Label>
                                        <CustomInput
                                            type="text"
                                            value={promotionalPrice}
                                            onChange={handlePromotionalPriceChange}
                                            placeholder="Digite o preço promocional"
                                        />
                                    </FieldWrapper>
                                </RowWrapper>
                                <RowWrapper>
                                    <FieldWrapper>
                                        <Label>Data de Início</Label>
                                        <CustomInput
                                            type="date"
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                    </FieldWrapper>
                                    <FieldWrapper>
                                        <Label>Data de Fim</Label>
                                        <CustomInput
                                            type="date"
                                            value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                        />
                                    </FieldWrapper>
                                </RowWrapper>
                                <CustomButton onClick={handleSave} theme={"primary"}>
                                    {editingPackage ? "Atualizar" : "Salvar"}
                                </CustomButton>
                            </FormWrapper>
                        </div>
                    </div>
                </animated.div>
            </Modal>

            <Modal
                isOpen={deleteModalIsOpen}
                onRequestClose={closeDeleteModal}
                shouldCloseOnOverlayClick={false}
                style={{
                    ...customStyles,
                    content: {
                        ...customStyles.content,
                        width: 'auto',
                        height: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '90vw',
                        maxHeight: '90vh',
                        overflowY: 'auto',
                    },
                }}
            >
                <HeaderWrapper>
                    <HeaderTitle>Confirmar Exclusão</HeaderTitle>
                    <CloseButton onClick={closeDeleteModal}>
                        <IoCloseOutline size={22} color="#919EAB" />
                    </CloseButton>
                </HeaderWrapper>
                <BodyWrapper>
                    <p>Tem certeza que deseja excluir o pacote: <strong>{deletingPackage?.name}</strong>?</p>
                    <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
                        <CustomButton onClick={handleDelete} theme={"success"}>
                            Excluir
                        </CustomButton>
                        <CustomButton onClick={closeDeleteModal} theme={"cancel"}>
                            Cancelar
                        </CustomButton>
                    </div>
                </BodyWrapper>
            </Modal>
        </PageStructure>
    );
};

export default ServicePackage;
