import React from 'react';
import * as S from './styles';

interface SubItem {
    title: string;
    value: string;
}

interface ListOptions {
    title: string;
    value: string;
    subItems?: SubItem[];
}

interface ExpandableOptionListProps {
    listOptions: Array<ListOptions>;
    title?: string;
    setSelectedList: React.Dispatch<React.SetStateAction<string[]>>;
    selectedList: string[];
    disabled?: boolean;
}

export const ExpandableOptionList = ({
    listOptions,
    title,
    setSelectedList,
    selectedList = [],
    disabled,
}: ExpandableOptionListProps) => {

    const handleMarkOption = (value: string) => {
        if (disabled) return;

        const index = selectedList.findIndex((element) => element === value);
        if (index === -1) {
            setSelectedList([...selectedList, value]);
        } else {
            setSelectedList(selectedList.filter((element) => element !== value));
        }
    };

    return (
        <S.Wrapper>
            <S.TitleWrapper>
                <S.Title>{title}</S.Title>
            </S.TitleWrapper>
            <S.ButtonWrapperList>
                {listOptions.map((element) => (
                    <div key={element.value}>
                        <S.ButtonWrapper disabled={disabled}>
                            <S.TitleButton>{element.title}</S.TitleButton>
                        </S.ButtonWrapper>
                        {element.subItems && (
                            <S.SubItemList>
                                {element.subItems.map((subItem) => (
                                    <S.SubItemWrapper
                                        key={subItem.value}
                                        isMarked={selectedList.includes(subItem.value)}
                                        disabled={disabled}
                                        onClick={() => handleMarkOption(subItem.value)}
                                    >
                                        <S.SubItemTitle isMarked={selectedList.includes(subItem.value)}>{subItem.title}</S.SubItemTitle>
                                    </S.SubItemWrapper>
                                ))}
                            </S.SubItemList>
                        )}
                    </div>
                ))}
            </S.ButtonWrapperList>
        </S.Wrapper>
    );
};
