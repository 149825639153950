import { useEffect, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { PiMoneyLight, PiCreditCard } from "react-icons/pi";
import { GiPayMoney } from "react-icons/gi";
import { RiBillLine } from "react-icons/ri";
import CustomButton from "../../../components/CustomButton";
import CustomDropdown from "../../../components/CustomDropDown";
import {
  AppointmentWrapper,
  ContentWrapper,
  FooterWrapper,
  HeaderTitle,
  HeaderWrapper,
  InfoWrapper,
  ModalWrapper,
} from "./style";
import CustomInput from "../../../components/CustomInput";
import api from "../../../service/api";
import { toast } from "react-toastify";
import { MdOutlineDoneAll, MdPayments } from "react-icons/md";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export interface PaymentMethod {
  id: number;
  key: string;  // Antigo 'name', agora chamado 'key'
  name: string;  // Antigo 'display_name', agora chamado 'name'
  tax_percentage: number;
  max_installments: number;  // Novo campo adicionado
}

interface RescheduleProps {
  closeModal: () => void;
  initialValue: number;
  event: any;
  doRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PaymentEntry = (props: RescheduleProps) => {
  const [value, setValue] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [options, setOptions] = useState<PaymentMethod[]>([]); // Métodos de pagamento
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>({
    id: 0,
    key: "NONE",
    name: "Nenhum",
    tax_percentage: 0,
    max_installments: 0,
  });
  const [installments, setInstallments] = useState("1x");
  const [alreadyPaid, setAlreadyPaid] = useState(false);
  const [originalInitialValue, setOriginalInitialValue] = useState("");
  const [idStone, setIdStone] = useState("");
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [receiptBase64, setReceiptBase64] = useState<any>(null);

  const [paymentOption, setPaymentOption] = useState({
    name: "Nenhum",
    id: "NONE",
  } as { name: string; id: string });

  useEffect(() => {
    if (
      props.initialValue <= 0 ||
      props.event.extendedProps.status.name == "Cancelado"
    )
      setAlreadyPaid(true);

    const initialValueIntern = props.initialValue > 50 ? 50 
    : props.initialValue >= 0 && props.initialValue <= 50? props.initialValue : 0;

    handleChange(
      (props.initialValue > 0 ? initialValueIntern * 100 : "000").toString()
    );

    setOriginalInitialValue(
      "R$ " +
      formatCurrency(
        (props.initialValue > 0 ? initialValueIntern * 100 : "000")
          .toString()
          .replace(/[^0-9]/g, "")
      )
    );
    const paymentEntry = document.getElementById("input-payment-entry") as any;

    if (!paymentEntry) return;

    paymentEntry.focus();
  }, []);

  const currencyToNumber = (currency: string) => {
    const currencyNumber = currency.split(" ")[1];
    const formatted = currencyNumber.replace(/\./g, "").replace(",", ".");

    return Number(formatted);
  };

  const savePaymentEntry = () => {
    const toastLoading = toast.loading("Lançando Pagamento...", {
      className: "toast-loading",
    });

    const pixProofInput = document.getElementById("pixProof") as any;
    const prePaymentInput = paymentOption.id === "PREPAYMENT";

    let obs = '';
    let prePaymentValue = false;

    if (prePaymentInput)
      prePaymentValue = prePaymentInput;

    if (paymentMethod.key == "PIX" && pixProofInput?.value)
      obs = `Comprovante (PIX): ${pixProofInput?.value} `;
    else if (paymentMethod.key == "CREDIT") {
      if (installments)
        obs = `Parcelado em: ${installments} `;

      if (!idStone) {
        toast.update(toastLoading, {
          render: "ID da Transação não informado!.",
          type: "warning",
          isLoading: false,
          autoClose: 3000,
        });
        return;
      } else
        obs += `Transação (Stone)'}: ${idStone}`
    } else if (paymentMethod.key == "DEBIT") {
      if (!idStone) {
        toast.update(toastLoading, {
          render: "ID da Transação não informado!.",
          type: "warning",
          isLoading: false,
          autoClose: 3000,
        });
        return;
      } else
        obs += `Transação (Stone)'}: ${idStone}`
    }

    if (idStone)
      obs += `Transação (Stone): ${idStone} `

    const formData = new FormData();

    formData.append('value', currencyToNumber(value).toString());
    formData.append('date', new Date().toISOString());
    formData.append('mode', paymentMethod.id.toString());
    formData.append('appointment', Number(props.event.id).toString());
    formData.append('type', 'IN');
    formData.append('obs', obs || '');
    formData.append('is_pre_payment', prePaymentValue.toString());

    if (receiptBase64) {
      formData.append('attachment', selectedImage);
    }

    api
      .post("/payments/", formData)
      .then(async (res) => {
        toast.update(toastLoading, {
          render: "Pagamento lançado com sucesso!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });

        const token = localStorage.getItem("bridges.token");

        if (paymentOption.id === "ADVANCED" && token) {
          const tokenData = jwtDecode<any>(token);

          await api.post("/credits/", {
            amount: currencyToNumber(value),
            payment: res.data.id,
            user: props.event.extendedProps.patient.id,
            created_by: tokenData.user_id,
          }).then(() => {
            toast.update(toastLoading, {
              render: "Crédito antecipado registrado com sucesso!",
              type: "success",
              isLoading: false,
              autoClose: 3000,
            });
          }).catch(() => {
            toast.update(toastLoading, {
              render: "Falha ao tentar registrar crédito antecipado!",
              type: "error",
              isLoading: false,
              autoClose: 3000,
            });
          })
        }

        props.doRefresh(true);
        searchParams.set("refresh", "true");

        navigate({
          pathname: location.pathname,
          search: searchParams.toString()
        }, { replace: true });
      })
      .catch(() => {
        toast.update(toastLoading, {
          render: "Erro no lançamento.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      });
  };

  const handleChange = (value: string) => {
    setValue("R$ " + formatCurrency(value.replace(/[^0-9]/g, "")));
  };

  const formatCurrency = (value: string) => {
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    return value;
  };

  const handleFileUpload = (event: any) => {
    const arquivo = event.target.files[0];

    const fileSizeInMB = arquivo.size / 1024 / 1024;
    if (fileSizeInMB > 5) {
      toast.warn("O arquivo não deve ultrapassar o limite de 5MB!", {
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }

    const reader = new FileReader();
    setSelectedImage(arquivo)

    reader.onload = () => {
      const result = reader.result as any

      setReceiptBase64(result);
    };

    reader.readAsDataURL(arquivo);
  };
  
  useEffect(() => {
    api
      .get("/paymentMethods")
      .then((response) => {
        const methods: PaymentMethod[] = response.data.map((method: any) => ({
          id: method.id,
          key: method.key,
          name: method.name,
          tax_percentage: method.tax_percentage,
          max_installments: method.max_installments,
        }));
        
        setOptions(methods);
      })
      .catch(() => {
        toast.error("Erro ao carregar métodos de pagamento.");
      });
  }, []);

  return (
    <>
      <ModalWrapper size="small">
        <HeaderWrapper>
          <HeaderTitle>Lançar pagamento</HeaderTitle>
          <IoCloseOutline
            size={22}
            onClick={props.closeModal}
            color="#919EAB"
            cursor={"pointer"}
          />
        </HeaderWrapper>
        <ContentWrapper>
          <InfoWrapper>
            <AppointmentWrapper>
              <CustomInput
                label="Valor"
                id="input-payment-entry"
                disabled={alreadyPaid}
                value={value}
                onChange={(e) => {
                  if (
                    currencyToNumber(
                      "R$ " +
                      formatCurrency(e.target.value.replace(/[^0-9]/g, ""))
                    ) >= currencyToNumber(originalInitialValue)
                  ) {
                    handleChange(originalInitialValue);
                    return;
                  }
                  handleChange(e.currentTarget.value);
                }}
                leftIcon={
                  <PiMoneyLight size={16} color="var(--primary-icon-color)" />
                }
              />
              <CustomDropdown
                label="Opção de pagamento"
                options={[
                  { name: "Nenhum", id: "NONE" },
                  // { name: "Crédito Antecipado", id: "ADVANCED" },
                  { name: "Pagamento Sinal", id: "PREPAYMENT" },
                ]}
                selectedOption={paymentOption.name}
                setSelectedOption={(value) => {
                  setPaymentOption(value);
                }}
                leftIcon={
                  <MdPayments size={16} color="var(--primary-icon-color)" />
                }
              />
              <CustomDropdown
                label="Método de pagamento"
                options={options}
                selectedOption={paymentMethod.name}
                setSelectedOption={(value) => {
                  setPaymentMethod(value);
                }}
                leftIcon={
                  <GiPayMoney size={16} color="var(--primary-icon-color)" />
                }
              />
              {(paymentMethod?.key == "PIX" || paymentMethod?.key == "TRANSFER") && (
                <CustomInput
                  label="Comprovante de Pagamento"
                  id="pixProof"
                  placeholder="Digite o comprovante"
                  leftIcon={
                    <RiBillLine size={16} color="var(--primary-icon-color)" />
                  }
                />
              )}

              {(paymentMethod?.key == "PIX" || paymentMethod?.key == "TRANSFER") && (
                <>
                  <CustomInput
                    label="Selecione o comprovante"
                    type="file"
                    disableBorder
                    inputId="receiptInputUpload"
                    onChange={handleFileUpload}
                    accept=".pdf, image/*"
                    fileSelected={selectedImage?.name}
                  />
                </>
              )}

              {paymentMethod?.key == "CREDIT" && (
                <>
                  <CustomDropdown
                    label="Parcelas"
                    options={[
                      "1x",
                      "2x",
                    ]}
                    selectedOption={installments}
                    setSelectedOption={(value) => {
                      setInstallments(value);
                    }}
                    leftIcon={
                      <PiCreditCard size={16} color="var(--primary-icon-color)" />
                    }
                  />
                </>
              )}

              {paymentMethod?.key != 'CASH' && paymentMethod?.key != 'PIX' && paymentMethod?.key != 'TRANSFER' && paymentMethod?.key !== 'LINK' &&
                <CustomInput
                  label="ID da Transação"
                  value={idStone}
                  onChange={e => setIdStone(e.currentTarget.value)}
                  placeholder="Digite o identificador"
                  leftIcon={
                    <MdOutlineDoneAll size={16} color="var(--primary-icon-color)" />
                  }
                />
              }
            </AppointmentWrapper>
          </InfoWrapper>
        </ContentWrapper>
        <FooterWrapper>
          <CustomButton theme="cancel" onClick={props.closeModal}>
            Cancelar
          </CustomButton>
          <CustomButton
            theme="success"
            onClick={savePaymentEntry}
            disabled={alreadyPaid}
          >
            Salvar
          </CustomButton>
        </FooterWrapper>
      </ModalWrapper>
    </>
  );
};

export default PaymentEntry;
