import React, { useEffect, useState } from "react";
import {
  HeaderWrapper,
  InputsContainer,
  ProfileContainer,
  UserImage,
  UserName,
  UserProfile,
  WelcomeText,
} from "./style";
import CustomSearch from "../CustomSearch";
import CustomDropDown from "../CustomDropDown";
import { useLocation } from "react-router-dom";
import ToggleButton from "./Components/ToggleButton/ToggleButton";
import CustomDatePicker from "../CustomDatePicker";
import HeaderButton from "./Components/HeaderButton/HeaderButton";
import Modal from "react-modal";
import { customStyles } from "../../Shared";
import { CreateTransactionHistory } from "../../../modals/CreateTransactionHistory";
import api from "../../../service/api";
import { getFirstAndLastDayOfMonth } from "../../../utils/getFirstAndLastDayOfMonth";
import { groupTransactionsByDate } from "../../../utils/groupTransactionsByDate";
import { authenticateProfessionalImageUrl } from "../../../utils/authenticateImageUrl";
import { jwtDecode } from "jwt-decode";

interface HeaderProps {
  onSearchChange: (value: string) => void;
  onSelectChange: (value: string) => void;
  options: string[];
  selectOption?: string;
  onYesterdayClick: () => void;
  onTodayClick: () => void;
  startDate?: Date | null;
  setStartDate?: (date: Date | null) => void;
  endDate?: Date | null;
  setEndDate?: (date: Date | null) => void;
}

const Header: React.FC<HeaderProps> = ({
  onSearchChange,
  onSelectChange,
  options,
  selectOption,
  onYesterdayClick,
  onTodayClick,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const defaultImage = require("../../../assets/user-profile.png");

  const location = useLocation();
  const [isYesterday, setIsYesterday] = useState(false);
  const [userName, setUserName] = useState<string>("Usuário");
  const [userPhoto, setUserPhoto] = useState<string>(require("../../../assets/user-profile.png"));
  const [ showPaymentModal, setShowPaymentModal ] = useState(false);
  const [transactionsByDate, setTransactionsByDate] = useState<{ [date: string]: IDBTransaction[] }>({});

  useEffect(() => {
    const token = localStorage.getItem("bridges.token");
    
    if (!token) return;

    const tokenData = jwtDecode<any>(token);

    const getProfilePhoto = async () => {
      const url = await authenticateProfessionalImageUrl(tokenData.user_id, '');
    
      setUserPhoto(url);
    }

    getProfilePhoto();
  }, []);

  const handleToggleClick = () => {
    if (isYesterday) {
      onTodayClick();
    } else {
      onYesterdayClick();
    }
    setIsYesterday(!isYesterday);
  };

  const renderInputsForToday = () => {
    if (location.pathname === "/") return renderInputs();
    if (location.pathname !== "/today") return renderInputsForAgenda();

    return (
      <>
        <CustomSearch onChange={(e) => onSearchChange(e.target.value)} />
        <CustomDropDown
          onChange={(e) => onSelectChange(e.target.value)}
          options={options}
        />
        <ToggleButton isYesterday={isYesterday} onClick={handleToggleClick} />
        <HeaderButton buttonText={'Solicitar pagamento'} onClick={() => {
          setShowPaymentModal(true)
        }} />
      </>
    );
  };
  
  const renderInputs = () => {
    return (
      <>
        <CustomSearch onChange={(e) => onSearchChange(e.target.value)} />
        <HeaderButton buttonText={'Solicitar pagamento'} onClick={() => {
          setShowPaymentModal(true)
        }} />      
      </>
    );
  };

  const renderInputsForAgenda = () => {
    if (location.pathname !== "/") return renderInputsForWallet();

    return (
      <>
        <CustomSearch onChange={(e) => onSearchChange(e.target.value)} />
      </>
    );
  };

  const renderInputsForWallet = () => {
    if (location.pathname !== "/wallet") return renderInputsForProfessionalsDetails();

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Carteira</h1>
        <CustomDatePicker
          placeholder="Data inicial"
          selectedDate={startDate}
          onDateChange={setStartDate}
        />
        <span>–</span>
        <CustomDatePicker
          placeholder="Data final"
          selectedDate={endDate}
          onDateChange={setEndDate}
          minDate={startDate}
        />
        <CustomDropDown
            onChange={(e) => onSelectChange(e.target.value)}
            options={options}
          />
      </div>
    );
  };

  const renderInputsForProfessionalsDetails = () => {
    if (location.pathname !== "/professionals/details") return renderInputsForProfessionals();

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Profissional</h1>
      </div>
    );
  };

  const renderInputsForProfessionals = () => {
    if (location.pathname !== "/professionals") return renderInputsForPatients();

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Profissional</h1>
        <CustomSearch onChange={(e) => onSearchChange(e.target.value)} />
      </div>
    );
  };

  const renderInputsForPatients = () => {
    if (location.pathname !== "/patients") return renderInputsForPatientsDetails();

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Pacientes</h1>

        {
          selectOption == 'Desde de' ?
            <CustomDatePicker
              placeholder="Data final"
              selectedDate={startDate}
              onDateChange={setStartDate}
            />
            :
            <CustomSearch
              onChange={(e) => onSearchChange(e.target.value)}
            />
        }

        <div>
          <CustomDropDown
            onChange={(e) => onSelectChange(e.target.value)}
            options={options}
          />
        </div>
      </div>
    );
  };
  
  const renderInputsForPatientsDetails = () => {
    if (location.pathname !== "/patients/details") return renderInputsForMedicalRecord();

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Pacientes</h1>
      </div>
    );
  };

  const renderInputsForMedicalRecord = () => {
    if (location.pathname !== "/medicalRecord") return renderInputsForOverview();

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Prontuário</h1>
      </div>
    );
  };

  const renderInputsForOverview = () => {
    if (location.pathname !== "/overview") return renderInputsForFinancial();

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Visão Geral</h1>
        </div>
        <CustomDropDown
          onChange={(e) => onSelectChange(e.target.value)}
          options={options}
        />
        <CustomDatePicker
          placeholder="Data inicial"
          selectedDate={startDate}
          onDateChange={setStartDate}
        />
        <span>–</span>
        <CustomDatePicker
          placeholder="Data final"
          selectedDate={endDate}
          onDateChange={setEndDate}
          minDate={startDate}
        />
      </>
    );
  };

  const renderInputsForFinancial = () => {
    if (location.pathname !== "/financial") return renderInputsForOperational();

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Financeiro</h1>
        </div>
        <CustomDropDown
          onChange={(e) => onSelectChange(e.target.value)}
          options={options}
        />
        <CustomDatePicker
          placeholder="Data inicial"
          selectedDate={startDate}
          onDateChange={setStartDate}
        />
        <span>–</span>
        <CustomDatePicker
          placeholder="Data final"
          selectedDate={endDate}
          onDateChange={setEndDate}
          minDate={startDate}
        />
      </>
    );
  };

  const renderInputsForOperational = () => {
    if (location.pathname !== "/operational") return renderInputsForPostConsultation();

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Operacional</h1>
        </div>
        <CustomDropDown
          onChange={(e) => onSelectChange(e.target.value)}
          options={options}
        />
        <CustomDatePicker
          placeholder="Data inicial"
          selectedDate={startDate}
          onDateChange={setStartDate}
        />
        <span>–</span>
        <CustomDatePicker
          placeholder="Data final"
          selectedDate={endDate}
          onDateChange={setEndDate}
          minDate={startDate}
        />
      </>
    );
  };

  const renderInputsForPostConsultation = () => {
    if (location.pathname !== "/postConsultation") return renderInputsForPreConsultation();

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Pós Consulta</h1>
        </div>
        <CustomDatePicker
          placeholder="Data inicial"
          selectedDate={startDate}
          onDateChange={setStartDate}
        />
        <span>–</span>
        <CustomDatePicker
          placeholder="Data final"
          selectedDate={endDate}
          onDateChange={setEndDate}
          minDate={startDate}
        />
      </>
    );
  };

  const renderInputsForPreConsultation = () => {
    if (location.pathname !== "/preConsultation") return renderInputsForMedicalRecordHistory();

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Pré Consulta</h1>
        </div>
        <CustomDatePicker
          placeholder="Data inicial"
          selectedDate={startDate}
          onDateChange={setStartDate}
        />
        <span>–</span>
        <CustomDatePicker
          placeholder="Data final"
          selectedDate={endDate}
          onDateChange={setEndDate}
          minDate={startDate}
        />
      </>
    );
  };

  const renderInputsForMedicalRecordHistory = () => {
    if (location.pathname !== "/medicalRecordHistory") return renderInputsForTemplateMessage();

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Histórico de prontuário</h1>
        </div>
      </>
    );
  };

  const renderInputsForTemplateMessage = () => {
    if (location.pathname !== "/template-message") return renderInputsForServicePackage();

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Mensagens de Template</h1>
        </div>
        <CustomDropDown
          onChange={(e) => onSelectChange(e.target.value)}
          options={options}
        />
      </>
    );
  };

  const renderInputsForServicePackage = () => {
    if (location.pathname !== "/service-package") return null;

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Pacote de Serviços</h1>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal
        isOpen={showPaymentModal}
        onRequestClose={() => setShowPaymentModal(false)}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <CreateTransactionHistory
          closeModal={() => setShowPaymentModal(false)}
          updateClients={() => { }}
          fetchFunction={() => { }}
          sortChart={''}
        />
      </Modal>
      <HeaderWrapper>
        <InputsContainer>{renderInputsForToday()}</InputsContainer>
        <ProfileContainer>
          <UserProfile>
            <UserName>
              {userName}
              <WelcomeText>Seja bem-vindo!</WelcomeText>
            </UserName>
          </UserProfile>
          <UserImage src={userPhoto} alt="Perfil" onError={(e) => {
                          (e.currentTarget as HTMLImageElement).src =
                            defaultImage;
                        }}/>
        </ProfileContainer>
      </HeaderWrapper>
    </>
  );
};

export default Header;
