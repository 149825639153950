import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';
import styled from 'styled-components';
import CustomDropDown from '../../../components/v2/CustomDropDown';

interface GenderChartProps {
    title: string;
    options: { name: string; value: string;}[];
}

const NpsChart: React.FC<GenderChartProps> = ({ title, options }) => {
    const chartRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const chartOptions = {
            series: [76],
            chart: {
                type: 'radialBar',
                offsetY: -20,
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        background: "#e7e7e7",
                        strokeWidth: '97%',
                        margin: 5, // margin is in pixels
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            offsetY: -2,
                            fontSize: '22px'
                        }
                    }
                }
            },
            grid: {
                padding: {
                    top: -10
                }
            },
            fill: {
                colors: ['#1270FC'] // Set the fill color to the standard blue color
            },
            labels: ['Average Results'],
        };

        const chart = new ApexCharts(chartRef.current, chartOptions);
        chart.render();

        return () => {
            chart.destroy();
        };
    }, [options]);

    return (
        <Card>
            <Title>{title}</Title>
            <ChartContainer ref={chartRef} />
            <DropDownWrapper>
                <CustomDropDown options={options.map(option => option.name)} onChange={() => {}} />
            </DropDownWrapper>
        </Card>
    );
};

const Card = styled.div`
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
    width: 100%;
`;

const Title = styled.h3`
    margin: 0 0 16px 0;
    text-align: left;
`;

const ChartContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px; /* Reduced height */
`;

const DropDownWrapper = styled.div`
    margin-top: 10px; /* Reduce the distance */
`;

export default NpsChart;